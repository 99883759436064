import axios from "axios";
import { configJson } from "../library/utils/firebaseUtils";

export const api = axios.create({
  baseURL: configJson.API_HOST_V2,
});

export const getSellerBalance = async (uid, token) => {
  try {
    const response = await api.get(`/brasilCap/getSellerBalance/?uid=${uid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateSellerBalance = async (uid, credits, token) => {
  try {
    const response = await api.post(
      `brasilCap/insertSellerCredits/`,
      {
        uid,
        credits,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};