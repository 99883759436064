import styled from "styled-components";

export const InputHolder = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 450px) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin-bottom: 5%;
    //padding: 1%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  border-radius: 15px;
  align-items: center;
  min-width: 50vw;
  margin-left: 100px;
  max-height: 1em;
  @media screen and (max-width: 450px) {
    display: flex;
    width: 80vw;
    margin: 0;
    margin-top: 15px;
    padding: 5%;
    //justify-content: flex-start;
    //align-items: center;
    min-width: 95vw;
    //margin: 0;
  }
`;

export const Select = styled.select`
  font-size: 1.2em;
  background-color: white;
  margin-left: 10px;
  margin-right: 5px;
  padding-left: 10px;
  border: none;
  outline: none;
  border-left: 1px solid black;
  border-radius: 0 15px 15px 0;
  @media screen and (max-width: 450px) {
    font-size: 1em;
    margin-right: 2px;
    //margin-right:4em;
    //margin-bottom: 0;
  }
`;

export const ProfileBox = styled.div`
  background-color: #2d70b9;
  padding: 1em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15em;
  margin-left: 2em;
  @media screen and (max-width: 450px) {
    //padding: 0.5em;
    display: flex;
    margin-top: 5px;
    margin-left: 0;
    align-items: center;
    width: 75vw;
    height: 5vh;
    margin-bottom: 1.2em;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  max-height: 70vh;
  width: 80%;
  margin-top: 2em;
  tr {
    text-align: center;
  }
  li {
    display: none;
  }
  .next {
    display: flex !important;
    color: #2d70b9;
    font-size: 3em;
  }
  .previous {
    display: flex !important;
    color: #2d70b9;
    font-size: 3em;
    margin-right: 1em;
  }
  ul {
    display: flex;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    font-size: 0.7em;
    margin: 0;
  }
`;

export const ChangePage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

export const SearchIcon = styled.div`
  //height: 1em;
  font-size: 2em;
  padding-left: 5px;
  padding-right: 5px;
  @media screen and (max-width: 450px) {
    font-size: 1em;
  }
`;
