import { useEffect, useState } from 'react';
import { mask } from 'remask';
import { toast } from 'react-toastify';

// STYLES
import {
  ContainerAddress,
  Input,
  Label,
  TextArea,
} from '../../pages/DistribuitionCartelas/styles/DistribuitionCartelasStyle';
import { Button, Form, WhiteBox } from '../../styled/RegisterStyle';
import { DivModal, HeaderText, ModalBody, ModalHeader } from './style/ModalStyle';
import { CircularProgress } from '@mui/material';

// APIS
import { verifyCep } from '../../services/APIs/viaCep';
import { editAccredited } from '../../services/APIs/editAccredited';

export const ModalEditAccredited = ({ close, open, pdv, uId, setUpdatePage, updatePage }) => {
  const [accreditedId, setAccreditedId] = useState('');
  const [accreditedInfo, setAccreditedInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAccreditedId(pdv.id);
    setAccreditedInfo({ ...pdv });
  }, [pdv]);

  // PEGA AS INFORMAÇÕES DE ENDEREÇO NO VIACEP
  useEffect(() => {
    if (accreditedInfo.addressList && accreditedInfo.addressList[0].zipCode?.length === 9) {
      const treatmentCep = accreditedInfo?.addressList[0].zipCode.replace(/,/g, '');
      verifyCep(treatmentCep).then((res) =>
        setAccreditedInfo((prevAccreditedInfo) => ({
          ...prevAccreditedInfo,
          addressList: [
            {
              ...(prevAccreditedInfo.addressList && prevAccreditedInfo.addressList[0]),
              streetAve: res.logradouro,
              city: res.localidade,
              state: res.uf,
              extra: res.complemento,
              neighborhood: res.bairro,
            },
          ],
        }))
      );
    }
    // eslint-disable-next-line
  }, [accreditedInfo.addressList && accreditedInfo.addressList[0].zipCode?.length]);

  const handleInputChange = (e, type) => {
    let value = e.target.value;
    let name = e.target.name;

    switch (type) {
      case 'commission':
        const regexNumber = /^[0-9]+(\.[0-9]{0,2})?$/; // Verifica se o input é um número com no máximo duas casas decimais;

        if (regexNumber.test(value) || value === '') {
          setAccreditedInfo((prevAccredited) => ({ ...prevAccredited, commission: value }));
        }
        break;

      case 'addressList':
        setAccreditedInfo((prevAccreditedInfo) => ({
          ...prevAccreditedInfo,
          addressList: [
            {
              ...(prevAccreditedInfo.addressList && prevAccreditedInfo.addressList[0]),
              [name]: value,
            },
          ],
        }));
        break;

      case 'zipCode':
        setAccreditedInfo((prevAccreditedInfo) => ({
          ...prevAccreditedInfo,
          addressList: [
            {
              ...(prevAccreditedInfo.addressList && prevAccreditedInfo.addressList[0]),
              [name]: mask(value, ['99999-999']),
            },
          ],
        }));
        break;

      default:
        setAccreditedInfo((prevAccredited) => ({ ...prevAccredited, [name]: value }));
    }
  };

  const handleClose = () => {
    setAccreditedInfo({});
    setUpdatePage(!updatePage);
    close();
  };

  const putAccredited = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      delete accreditedInfo.accreditedId;
      delete accreditedInfo.id;
      delete accreditedInfo.updateDate;
      delete accreditedInfo.status;
      delete accreditedInfo.address;

      const payload = {
        accreditedId,
        changedFields: {
          ...accreditedInfo,
        },
      };

      delete accreditedInfo.id;

      await editAccredited(uId, payload);
      setUpdatePage(!updatePage);
      toast.success('Atualização efetuada com sucesso.');
      handleClose();
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`modal ${open ? 'modal is-active' : 'modal'}`}>
      <div onClick={handleClose} className="modal-background"></div>
      <DivModal style={{ minHeight: '40rem', width: '40rem' }} className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">Editar credenciamento</HeaderText>
        </ModalHeader>
        <ModalBody className="modal-card-body">
          <Form onSubmit={putAccredited} style={{ marginTop: '10rem' }} autocomplete="off">
            <WhiteBox
              className="box"
              style={{
                justifyContent: 'center',
                width: '95%',
                height: '40rem',
              }}
            >
              <Label>Nome do credenciado*:</Label>
              <Input
                type="text"
                name="name"
                placeholder="Triibo Tecnologia"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                onChange={(e) => handleInputChange(e)}
                value={accreditedInfo.name}
                required
              />
              <Label>Descrição*:</Label>
              <TextArea
                placeholder="Plataforma de engajamento baseado em comunidade."
                name="description"
                aria-multiline
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                onChange={(e) => handleInputChange(e)}
                value={accreditedInfo.description}
                required
              />
              <Label>Comissão*:</Label>
              <Input
                type="text"
                name="comission"
                placeholder="12%"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                onChange={(e) => handleInputChange(e, 'commission')}
                value={accreditedInfo.commission}
                maxLength={2}
                required
              />
              <ContainerAddress>
                <Label>Cep*:</Label>
                <Input
                  autoComplete="both"
                  type="text"
                  name="zipCode"
                  placeholder="00000000"
                  className={
                    window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                  }
                  onChange={(e) => handleInputChange(e, 'zipCode')}
                  maxLength={9}
                  value={accreditedInfo.addressList && accreditedInfo.addressList[0].zipCode}
                  required
                />
                <div>
                  <span>
                    <Label>Rua*:</Label>
                    <Input
                      autoComplete="both"
                      type="text"
                      name="streetAve"
                      placeholder="Rua guapiaçu"
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      onChange={(e) => handleInputChange(e, 'addressList')}
                      value={accreditedInfo.addressList && accreditedInfo.addressList[0].streetAve}
                      required
                    />
                  </span>
                  <span style={{ width: '30%' }}>
                    <Label>Número*:</Label>
                    <Input
                      autoComplete="both"
                      type="tel"
                      name="number"
                      placeholder="0000"
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      onChange={(e) => handleInputChange(e, 'addressList')}
                      value={accreditedInfo.addressList && accreditedInfo.addressList[0].number}
                      required
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <Label>Cidade*:</Label>
                    <Input
                      autoComplete="both"
                      type="text"
                      name="city"
                      placeholder="São Paulo"
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      onChange={(e) => handleInputChange(e, 'addressList')}
                      value={accreditedInfo.addressList && accreditedInfo.addressList[0].city}
                      required
                    />
                  </span>
                  <span style={{ width: '30%' }}>
                    <Label>Estado*:</Label>
                    <Input
                      autoComplete="both"
                      type="text"
                      name="state"
                      placeholder="SP"
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      onChange={(e) => handleInputChange(e, 'addressList')}
                      maxLength={2}
                      value={
                        accreditedInfo.addressList &&
                        accreditedInfo.addressList[0].state?.toUpperCase()
                      }
                      required
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <Label>Bairro*:</Label>
                    <Input
                      autoComplete="both"
                      type="text"
                      name="neighborhood"
                      placeholder="Centro"
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      onChange={(e) => handleInputChange(e, 'addressList')}
                      value={
                        accreditedInfo.addressList && accreditedInfo.addressList[0].neighborhood
                      }
                      required
                    />
                  </span>
                  <span>
                    <Label>Complemento:</Label>
                    <Input
                      autoComplete="both"
                      type="text"
                      name="extra"
                      placeholder="Torre, apto, casa, etc..."
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      onChange={(e) => handleInputChange(e, 'addressList')}
                      value={accreditedInfo.addressList && accreditedInfo.addressList[0].extra}
                    />
                  </span>
                </div>
              </ContainerAddress>
              <span>
                <Label>Ponto de referência:</Label>
                <Input
                  autoComplete="both"
                  type="text"
                  name="landmark"
                  placeholder="Próximo à triibo"
                  className={
                    window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                  }
                  onChange={(e) => handleInputChange(e, 'addressList')}
                  value={accreditedInfo.addressList && accreditedInfo.addressList[0].landmark}
                />
              </span>
            </WhiteBox>
            <Button
              type="submit"
              className={window.screen.width > 450 ? 'button' : 'button is-small'}
            >
              {loading ? (
                <CircularProgress size={26} thickness={4} style={{ color: '#fff' }} />
              ) : (
                'Salvar'
              )}
            </Button>
          </Form>
        </ModalBody>
      </DivModal>
    </div>
  );
};
