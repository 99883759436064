import 'react-toastify/dist/ReactToastify.css';
import ReactInputMask from 'react-input-mask';
import { Button, LinkCode } from '../../pages/Login/style/Login';
import { authCode } from '../../services/login';
import React, { useEffect, useState } from 'react';

export default function InputCode({
  handleRedirect,
  code,
  setCode,
  phoneNumber,
}) {
  const [second, setSecond] = useState(30);
  const sendType = { email: false, sms: true, whatsapp: false };

  const seconds = second % 60;

  useEffect(() => {
    if (second === 0) {
      setSecond(0);
    } else {
      setTimeout(() => {
        setSecond(second - 1);
      }, 1000);
    }
  }, [second]);

  const handleResend = async () => {
    await authCode(phoneNumber, sendType);
    setSecond(30);
  };

  return (
    <>
      <form onSubmit={handleRedirect}>
        <ReactInputMask
          style={{
            textAlign: 'center',
          }}
          className="input is-medium is-fullwidth"
          maskChar=""
          alwaysShowMask={false}
          type="text"
          mask="999999"
          placeholder="000000"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />

        <LinkCode
          className="button"
          type="button"
          onClick={() => handleResend()}
          disabled={second !== 0}
        >
          <span style={{ display: second === 0 ? 'none' : '' }}>
            {seconds.toString().padStart(2, '0')}
          </span>
          &nbsp;Enviar o código novamente
        </LinkCode>
        <Button className="button is-fullwidth" type="submit">
          Continuar
        </Button>
      </form>
    </>
  );
}
