import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { toast } from 'react-toastify';

// STYLES
import { StyledDiv } from './style/MUIDatatable';
import { CircularProgress, createTheme, ThemeProvider, Tooltip } from '@mui/material';
import { LoadingTable } from '../../pages/ListOfRaffles/style/ListOfRaffles';
import { BottomMargin } from '../../pages/SellerPage/style/SellerPageStyle';
import { GoUnlink } from 'react-icons/go';

// COMPONENTS
import { ContainerSwitch } from '../../pages/DistribuitionCartelas/styles/DistribuitionCartelasStyle';
import { SwitchButton } from '../UpdateSellerSupervisor/UpdateSellerSupervisor';

//APIS
import { toggleEstablishmentStatus } from '../../services/APIs/toggleEstablishmentStatus';

export default function EstablishmentsLinkWithAccreditedTable({
  selected,
  establishmentList,
  idPDV,
  openModalUnlinkPdv,
  setEstablishmentId,
  setUpdatePage,
  updatePage,
}) {
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [displayData, setDisplayData] = useState([]);
  const [processingItem, setProcessingItem] = useState(null);

  // VERIFICA SE O CREDENCIADO POSSUI ESTABELECIMENTOS VINCULADOS
  useEffect(() => {
    if (establishmentList !== undefined) {
      if (establishmentList !== 'not found') {
        switch (selected) {
          case 'todos os estabelecimentos':
            setDisplayData(establishmentList);
            break;
          case 'ativos':
            setDisplayData(establishmentList.filter((el) => el.status === true));
            break;
          case 'inativos':
            setDisplayData(establishmentList.filter((el) => el.status === false));
            break;
          default:
            toast.error('Algo ocorreu de errado.');
        }
      } else {
        setDisplayData([]);
      }
    }
  }, [establishmentList, selected]);

  // RETORNA O LOADING
  if (establishmentList?.length === 0)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingTable>
          <CircularProgress size="100px" />
        </LoadingTable>
      </div>
    );

  // RETORNA MSG DE NÃO POSSUI ESTABELECIMENTOS
  if (establishmentList === 'not found') {
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <LoadingTable>Credenciado ainda não possui estabelecimentos vinculados.</LoadingTable>
      </div>
    );
  }

  const handleStatusEstablishments = async (e, info) => {
    e.preventDefault();
    setProcessingItem(info[0]);

    try {
      await toggleEstablishmentStatus(uId, idPDV, !info[2], info[0]);
    } catch (error) {
      toast.error('Algo não funcionou corretamente, tente novamente mais tarde.');
    } finally {
      setProcessingItem(null);
      setUpdatePage(!updatePage);
    }
  };

  const handleModalUnlinkPdv = (e, value) => {
    e.preventDefault();

    setEstablishmentId(value);
    openModalUnlinkPdv();
  };

  const columns = [
    {
      name: 'establishmentId',
      label: 'Id do estabelecimento',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'establishmentName',
      label: 'Nome do estabelecimento',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <ContainerSwitch>
              Inativo
              {processingItem === tableMeta.rowData[0] ? (
                <CircularProgress size={20} thickness={5} style={{ color: '#EDB622' }} />
              ) : (
                <SwitchButton
                  // Verifique se este item está em processo e defina o estado do botão e a função de clique adequadamente
                  checked={value}
                  onClick={(e) => handleStatusEstablishments(e, tableMeta.rowData)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
              Ativo
            </ContainerSwitch>
          );
        },
      },
    },

    {
      name: 'establishmentId',
      label: 'Ação',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          return (
            <>
              <Tooltip title="Desvincular estabelecimento" placement="top">
                <button
                  style={{ background: 'transparent', border: 0 }}
                  onClick={(e) => handleModalUnlinkPdv(e, value)}
                >
                  <GoUnlink size={25} color="red" />
                </button>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const tableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: 'simple',
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: '',
        toolTip: `${'table.toolTip'}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${'table.viewColumnsTitle'}`,
      },
    },
  };
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
            },
          },
        },
      },
    });

  return (
    <BottomMargin>
      <StyledDiv style={{ cursor: 'pointer' }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            className={'table is-mobile'}
            data={displayData}
            columns={columns}
            options={tableOptions}
          />
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}
