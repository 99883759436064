import { useContext, useEffect, useState } from 'react';
import { DistribuitionCartelaContext } from '../../../context/distribuitionCartela';
import { toast } from 'react-toastify';

// STYLES
import { Content, InsideContent } from '../../../styled/generic';

// COMPONENTS
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import { LowsTable } from '../../../components/Tables/LowsTable';

// APIS
import { getDeactivatedCardBySerie } from '../../../services/APIs/getDeactivatedCardsBySerie';

export function LowsList() {
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [blockedApi, setBlockedApi] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [formattedLows, setFormattedLows] = useState([]);
  const [highPage, setHighPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    if ((currentPage > highPage || highPage === 0) && !blockedApi) {
      setLoading(true);
      setHighPage(currentPage);
      getDeactivatedCardBySerie(uId, serie ? serie : serieUrl, currentPage, rowsPerPage)
        .then((result) => {
          result.length < rowsPerPage && setBlockedApi(true);
          const dataStructuring = result.map((lows) => {
            const operationInfos = Object.values(lows)[0][0];
            return {
              lows: Object.values(lows)[0],
              accreditedName: operationInfos.accreditedName,
              creationDate: operationInfos.creationDate,
              isUnitary: operationInfos.isUnitary,
              operationId: operationInfos.operationId,
              operatorName: operationInfos.operatorName,
              status: operationInfos.status,
            };
          });
          setFormattedLows((prevFormattedLows) => [...prevFormattedLows, ...dataStructuring]);
        })
        .catch(() => {
          toast.error('Algo ocorreu de errado. Volte novamente mais tarde.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [uId, serie, serieUrl, currentPage, rowsPerPage]);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <LowsTable
            listLows={formattedLows}
            loadingTable={loading}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            currentPage={currentPage}
          />
        </InsideContent>
      </Content>
    </>
  );
}

