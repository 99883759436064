import axios from 'axios';
import { configJson } from '../../library/utils/firebaseUtils';
import { authToken } from '../APIs/token';

export function transferCartela(uId, payload) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .post(
            configJson.API_HOST_V2 + `/sorteSaude/cards/transfer`,
            {
              ...payload, //série, oldPdvId, newPdvId, establishmentId (ID DA ENTREGA),
            },
            { headers: { authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            // console.log('RESULT USER BRASIL CAP ==>', result.data.response);
            resolve(result.data);
          })
          .catch((error) => {
            // console.log('ERROR USER BRASIL CAP ==>', error);
            reject(error.response); // Reject the promise with the error
          });
      })
      .catch((authError) => {
        reject(authError); // Reject the promise with the authentication error
      });
  });
}
