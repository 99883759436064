// STYLES
import { ButtonDistribuitionTable } from '../Tables/style/EditButtomTableStyles';
import { IoTrashBinOutline } from 'react-icons/io5';
import Tooltip from '@mui/material/Tooltip';

// OTHERS
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { convertVisualDateForTimestamp } from '../../services/helpers';

export const CardLosesButton = ({ serie, serieEndDate, seriesFinished, status }) => {
  const { setSerie } = useContext(DistribuitionCartelaContext);
  const statusReleasesAction = ['lossInitiated', 'lossCompleted'];

  return (
    statusReleasesAction.includes(status) &&
    seriesFinished(convertVisualDateForTimestamp(serieEndDate)) && (
      <Tooltip title="Cartelas extraviadas" placement="top">
        <Link to={`/operacao-cartela/gerenciar-series/extravios/${serie}`}>
          <ButtonDistribuitionTable onClick={() => setSerie(serie)}>
            <IoTrashBinOutline size={20} style={{ color: '#8A8F91' }} />
          </ButtonDistribuitionTable>
        </Link>
      </Tooltip>
    )
  );
};

