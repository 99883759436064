import React, { useState } from 'react';

// STYLES
import MUIDataTable from 'mui-datatables';
import { StyledDiv } from '../Tables/style/MUIDatatable';
import { BottomMargin } from '../../pages/SellerPage/style/SellerPageStyle';
import { CircularProgress, createTheme, ThemeProvider, Tooltip } from '@mui/material';
import { HiOutlineDocumentChartBar } from 'react-icons/hi2';
import { ButtonDistribuitionTable, ContainerNotHaveItem } from './style/EditButtomTableStyles';
import { BsCircleFill } from 'react-icons/bs';

// OTHERS
import { convertToFullDate } from '../../services/helpers';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import { ModalLowsLog } from '../Modals/ModalLowsLog';

export function LowsTable({
  listLows,
  loadingTable,
  handleChangePage,
  rowsPerPage,
  setRowsPerPage,
  currentPage,
}) {
  const navigate = useNavigate();
  const [logs, setLogs] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const displayData = listLows;

  const handleOpenModal = (log) => {
    setIsOpenModal(true);
    setLogs(log);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const columns = [
    {
      name: 'operationId',
      label: 'ID da baixa',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'accreditedName',
      label: 'Nome do credenciado',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          // AJUSTES PARA EXIBIR A DATA EM ORDEM CORRETA
          return value ? value : 'Cartela não distribuída';
        },
      },
    },
    {
      name: 'operatorName',
      label: 'Nome do Operador',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'creationDate',
      label: 'Data da baixa',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value, rowData) => {
          // AJUSTES PARA EXIBIR A DATA EM ORDEM CORRETA
          return convertToFullDate(value);
        },
      },
    },
    {
      name: 'isUnitary',
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          return value ? 'Cartela' : 'Lote';
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'disablePending':
              return (
                <span>
                  <BsCircleFill style={{ color: '#ffa500' }} />
                  &nbsp; Pendente
                </span>
              );
            case 'disabledError':
              return (
                <span>
                  <BsCircleFill style={{ color: '#EB1E0A' }} />
                  &nbsp; Erro
                </span>
              );
            case 'disableConfirmed':
              return (
                <span>
                  <BsCircleFill style={{ color: '#2DB94D' }} />
                  &nbsp; Confirmado
                </span>
              );
            default:
              break;
          }
        },
      },
    },
    {
      name: 'lows',
      label: 'Ação',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          return (
            <Tooltip title="Log" placement="top">
              <ButtonDistribuitionTable onClick={() => handleOpenModal(value)}>
                <HiOutlineDocumentChartBar size={25} style={{ color: '#2D70B9' }} />
              </ButtonDistribuitionTable>
            </Tooltip>
          );
        },
      },
    },
  ];

  const tableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: 'simple',
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: '',
        toolTip: `${'table.toolTip'}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${'table.viewColumnsTitle'}`,
      },
    },
    defaultSortField: 'creationDate',
    sortOrder: {
      direction: 'desc',
    },
    pagination: true,
    count: displayData.length + 1,
    rowsPerPageOptions: [5, 10, 20],
    onChangeRowsPerPage: (page) => setRowsPerPage(page),
    rowsPerPage: rowsPerPage,
    onChangePage: handleChangePage,
    page: currentPage,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
              cursor: 'default',
              textAlign: 'center',
            },
          },
        },
      },
    });

  return (
    <BottomMargin>
      <ModalLowsLog close={handleCloseModal} open={isOpenModal} log={logs} />
      <StyledDiv>
        <ThemeProvider theme={getMuiTheme()}>
          {loadingTable ? (
            <CircularProgress size={100} thickness={4} style={{ marginTop: '8rem' }} />
          ) : displayData.length === 0 ? (
            <ContainerNotHaveItem>
              <h3 style={{ marginTop: '8rem' }}>Até o momento, não há ocorrência de baixas.</h3>
              <button onClick={() => navigate(-1)}>&lt; VOLTAR</button>
            </ContainerNotHaveItem>
          ) : (
            <MUIDataTable
              className={'table is-mobile'}
              data={displayData}
              columns={columns}
              options={tableOptions}
            />
          )}
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}

