import { useRef, useState } from 'react';
import { convertBase64 } from '../../services/helpers';

// STYLES
import { GrDocumentUpdate } from 'react-icons/gr';
import { UploadImage } from '../../styled/generic';
import { ModalBody, SendCartelaButton } from '../Modals/style/ModalStyle';
import { CircularProgress, Tooltip } from '@mui/material';
import { ContentButtonX } from '../../pages/DistribuitionCartelas/SaleOff/style';
import { ImCancelCircle } from 'react-icons/im';

export const SendImage = ({
  action,
  labelButton,
  labelImage,
  labelTooltip,
  loading,
  pathImage,
  setImage,
  setPathImage,
}) => {
  const fileInputRef = useRef(null);
  const [previewImage, setPreviewImage] = useState('');

  // ABRE O INPUT FILE AO CLICAR NA IMAGEM
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  //PEGA INFOS DA IMAGEM
  const handleSubmitImage = async (event) => {
    const file = event.target.files[0];
    setImage(file);
    setPreviewImage(URL.createObjectURL(file));

    const base64 = await convertBase64(file);
    setPathImage(base64);
  };

  // REMOVE A IMAGEM
  const removeImage = () => {
    setImage('');
    setPathImage('');
    setPreviewImage('');
  };

  return (
    <ModalBody style={{ width: '100%', height: '100%' }}>
      <UploadImage>
        {pathImage === '' ? (
          <span onClick={handleImageClick}>
            <GrDocumentUpdate size={90} />
            {labelImage}
          </span>
        ) : (
          <span onClick={handleImageClick}>
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxWidth: '260px', maxHeight: '290px' }}
            />
          </span>
        )}

        <input type="file" accept="image/*" ref={fileInputRef} onChange={handleSubmitImage} />
      </UploadImage>

      {pathImage && (
        <Tooltip title={labelTooltip} placement="right">
          <ContentButtonX onClick={removeImage}>
            <ImCancelCircle size={30} />
          </ContentButtonX>
        </Tooltip>
      )}

      <SendCartelaButton
        disabled={pathImage === '' || loading}
        onClick={() => action()}
        variant="contained"
      >
        {loading ? (
          <CircularProgress size={20} thickness={4} style={{ color: '#fff' }} />
        ) : (
          labelButton
        )}
      </SendCartelaButton>
    </ModalBody>
  );
};

