import styled from 'styled-components';

export const BlueBox = styled.div`
  display: flex;
  height: 8em;
  background-color: #2d70b9;
  align-items: center;
  padding: 2em 4em;
  justify-content: space-between;
  width: 40%;
  margin-bottom: 1.5em;
  border-radius: 10px;
  @media screen and (max-width: 450px) {
    width: 95vw;
    padding: 2%;
    margin-left: 10%;
    height: 6em;
  }
`;

export const LogoBlueBox = styled.img`
  width: 6em;
  margin-right: 1.5em;
  @media screen and (max-width: 450px) {
    width: 4em;
  }
`;

export const DivInputBlueBox = styled.div`
  width: 70%;
`;

export const LabelBlueBox = styled.label`
  color: white;
  text-align: center;
  //width: 25em;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 4em;
  justify-content: space-between;
  width: 40%;
  margin-bottom: 1.5em;
  border-radius: 10px;
  height: 15em;
  @media screen and (max-width: 450px) {
    width: 90%;
    padding: 2%;
    margin-left: 8%;
  }
`;

export const SmallWhiteBox = styled.div`
  display: flex;
  //flex-direction: column;
  padding: 2em 4em;
  justify-content: center;
  align-items: center;
  //padding-top: 2em;
  width: 37.4vw;
  height: 4em;
  @media screen and (max-width: 450px) {
    width: 90%;
    padding: 2%;
    margin-left: 8%;
  }
`;

export const Input = styled.input`
  margin-bottom: 1em;
`;

export const DivInput = styled.div`
  display: flex;
  align-items: center;
`;

export const SmallLabel = styled.label`
  color: #707070;
  font-weight: 400;
  font-size: 0.8em;
`;

export const ButtonInside = styled.button`
  color: white;
  background-color: #2d70b9;
  width: 10em;
  margin-left: 2em;
  margin-top: -1em;
`;

export const Button = styled.button`
  color: white;
  background-color: #2d70b9;
  width: 10em;

  transition: 0.2s;

  &:hover {
    color: #fff;
    font-weight: 600;
    opacity: 0.9;
  }
  @media screen and (max-width: 450px) {
    margin-left: 8%;
    margin-bottom: 2em;
  }
`;

export const Form = styled.form`
  width: 100%;
  //height: 30em;
  display: flex;
  margin-top: 2em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 450px) {
    height: 80%;
    margin-top: 0;
  }
`;
