import styled from 'styled-components';

export const SideBarDesktopStyle = styled.div`
  flex: 1;
  //z-index: 1;
  position: fixed;
  min-width: 6.5em;
  max-width: 6.5em;
  background-color: #2d70b9;
  height: 100vh;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  top: 0;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const Button = styled.button`
  background-color: #2d70b9;
  border: none;
  color: white;
  width: 100%;
  margin-left: 0.2em;
  font-size: 1.5em;
  @media screen and (max-width: 450px) {
    display: flex;
    background-color: #2d70b9;
    border: none;
    color: white;
    width: 1.5em;
    font-size: 1.7em;
    align-items: center;
    padding: 0.1em;
    justify-content: center;
    margin: 0.8em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-right: 1em;
  height: 90%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 450px) {
    margin-right: 0;
    padding-top: 0;
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
    width: 100%;
  }
`;

export const SideBarLogo = styled.img`
  display: flex;
  padding-top: 15px;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;
