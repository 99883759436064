import React from 'react';

// STYLES
import MUIDataTable from 'mui-datatables';
import { StyledDiv } from '../Tables/style/MUIDatatable';
import { BottomMargin } from '../../pages/SellerPage/style/SellerPageStyle';
import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { BsCircleFill } from 'react-icons/bs';
import { ContainerNotHaveItem } from './style/EditButtomTableStyles';

// OTHERS
import { convertToFullDate } from '../../services/helpers';
import { useNavigate } from 'react-router-dom';

export function LossTable({ listLoss, loadingTable }) {
  const displayData = listLoss;
  const navigate = useNavigate();

  const columns = [
    {
      name: 'id',
      label: 'ID da baixa',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'accreditedName',
      label: 'Nome do credenciado',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'operatorName',
      label: 'Nome do Operador',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'creationDate',
      label: 'Data do extravio',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          // AJUSTES PARA EXIBIR A DATA EM ORDEM CORRETA
          return convertToFullDate(value);
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'lossPending':
              return (
                <span>
                  <BsCircleFill style={{ color: '#ffa500' }} />
                  &nbsp; Pendente
                </span>
              );
            case 'lossError':
              return (
                <span>
                  <BsCircleFill style={{ color: '#EB1E0A' }} />
                  &nbsp; Erro
                </span>
              );
            case 'lossConfirmed':
              return (
                <span>
                  <BsCircleFill style={{ color: '#2DB94D' }} />
                  &nbsp; Confirmado
                </span>
              );
            case 'lossFinished':
              return (
                <span>
                  <BsCircleFill style={{ color: '#2d70b9' }} />
                  &nbsp; Finalizado
                </span>
              );
            default:
              break;
          }
        },
      },
    },
    {
      name: 'cardId',
      label: 'Cartela',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          return <strong>{value}</strong>;
        },
      },
    },
  ];

  const tableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: 'simple',
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: '',
        toolTip: `${'table.toolTip'}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${'table.viewColumnsTitle'}`,
      },
    },
    defaultSortField: 'creationDate',
    sortOrder: {
      name: 'creationDate',
      direction: 'desc',
    },
    rowsPerPageOptions: [5, 10, 20],
    rowsPerPage: 20,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
              cursor: 'default',
              textAlign: 'center',
            },
          },
        },
      },
    });

  return (
    <BottomMargin>
      <StyledDiv
        style={{
          cursor: 'pointer',
        }}
      >
        <ThemeProvider theme={getMuiTheme()}>
          {loadingTable ? (
            <CircularProgress size={100} thickness={4} style={{ marginTop: '8rem' }} />
          ) : displayData.length === 0 ? (
            <ContainerNotHaveItem>
              <h3 style={{ marginTop: '8rem' }}>Até o momento, não há registros de extravios.</h3>
              <button onClick={() => navigate(-1)}>&lt; VOLTAR</button>
            </ContainerNotHaveItem>
          ) : (
            <MUIDataTable
              className={'table is-mobile'}
              data={displayData}
              columns={columns}
              options={{
                ...tableOptions,
              }}
            />
          )}
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}

