import axios from "axios";
import { configJson } from "../library/utils/firebaseUtils";

export const api = axios.create({
  baseURL: configJson.API_HOST_V2,
});

export const updateSupervisor = async (uidSupervisor, uidSeller, token) => {
  try {
    const response = await api.put(
      `/brasilCap/updateSupervisorSeller/?uidSupervisor=${uidSupervisor}&uidSeller=${uidSeller}`,
      {},
      {
        data: {},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
