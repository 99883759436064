import React, { useState, useEffect } from "react";
import { BsCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { StyledDiv } from "../../components/Tables/style/MUIDatatable";
import { authToken } from "../../services/APIs/token";
import { getAllSupervisors } from "../../services/getAll";
import { BottomMargin } from "../../pages/SellerPage/style/SellerPageStyle";
import { CircularProgress } from '@mui/material';

export default function SupervisorListTable({ selected }) {
  const [cpfSelected, setCpfSelected] = useState("");
  const [phoneSelected, setPhoneSelected] = useState("");
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authToken().then((token) => {
      getAllSupervisors(token)
        .then((response) => {
          setData(response.data);
        })
        .catch((e) => {
          setError(true);
        });
    });
  }, []);

  useEffect(() => {
    if (cpfSelected !== "") {
      localStorage.setItem(
        "sellerPhone",
        displayData.find((e) => e.cellPhone === "+55" + phoneSelected).cellPhone
      );
      navigate(`/supervisor/${displayData.find((e) => e.CPF === cpfSelected).Data.uid}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpfSelected, phoneSelected]);

  const navigate = useNavigate();

  if (error)
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        Não existem vendedores cadastrados para este supervisor
      </div>
    );
  if (data.length === 0)
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );

  const displayData = data.response
    .filter((e) => e.Data.role !== "manager")
    .filter((el) => el.Data.active === selected);
  const columns = [
    {
      name: "name",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: "CPF",
      label: "CPF",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          const cleaned = ("" + value).replace(/\D/g, "");
          let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
          if (match) {
            return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
          }
        },
      },
    },
    {
      name: "cellPhone",
      label: "Telefone",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          const cleaned = ("" + value).replace("+55", "");
          let match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
          if (match) {
            return `${match[1]} ${match[2]} ${match[3]}-${match[4]}`;
          }
        },
      },
    },
    {
      name: "Data",
      label: "Status",
      options: {
        customBodyRender: (value) =>
          value.active ? (
            <span>{<BsCircleFill style={{ color: "#2DB94D" }} />}&nbsp;Ativo </span>
          ) : (
            <span>{<BsCircleFill style={{ color: "#8A8F91" }} />}&nbsp;Inativo </span>
          ),
        sort: false,
        sortThirdClickReset: false,
        filter: false,
      },
    },
  ];

  const tableOptions = {
    onRowClick: (rowData) => {
      setCpfSelected(rowData[1].replaceAll(".", "").replace("-", ""));
      setPhoneSelected(rowData[2].replaceAll(" ", "").replace("-", ""));
    },
    selectableRowsHeader: false,
    responsive: 'simple',
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    filterType: "textField",
    textLabels: {
      body: {
        noMatch: `Sem Supervisores cadastrados`,
        toolTip: `${"table.toolTip"}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `${"table.next"}`,
        previous: `${"table.previous"}`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${"table.viewColumnsTitle"}`,
      },
    },
  };

  return (
    <BottomMargin>
      <StyledDiv style={{cursor: 'pointer'}}>
        <MUIDataTable data={displayData} columns={columns} options={tableOptions} />
      </StyledDiv>
    </BottomMargin>
  );
}
