import axios from 'axios';

export function verifyCep(cep) {
  return axios
    .get(`https://viacep.com.br/ws/${cep}/json/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
