import { useEffect, useState } from 'react';

// STYLES
import { SendBox } from '../styles/DistribuitionCartelasStyle';
import { Content, InsideContent } from '../../../styled/generic';
import { Button } from '@mui/material';

// COMPONENTS
import { ModalConfirmShipping } from '../../../components/Modals/ModalConfirmShipping';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import HeaderComp from '../../../components/Header/HeaderComp';

export const SendInLote = () => {
  const [cartelas, setCartelas] = useState();
  const [value, setValue] = useState();
  const [openUpdate, setOpenUpdate] = useState(false);

  // MULTIPLICACAO DO LOTE RETORNANDO O VALOR EM CARTELAS
  useEffect(() => {
    if (value >= 0) {
      setCartelas(value * 100);
    }
  }, [value]);

  const handleModalUpdate = () => {
    setOpenUpdate(!openUpdate);
  };

  const handleInputChange = (e) => {
    // Permite apenas números e campo vazio
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    setValue(inputValue);
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <SendBox>
            <spam>
              <h4>Reserva em lote</h4>
              <p>1 lote = 100 cartelas</p>
            </spam>
            <input
              // type="number"
              min="1"
              value={value}
              onChange={handleInputChange}
              placeholder="Digite a quantidade de lotes"
            />
            <h5>Total: {cartelas} cartelas</h5>
            <Button
              disabled={value === undefined || value <= 0}
              onClick={handleModalUpdate}
              style={{ width: '13rem' }}
            >
              Selecionar credenciado
            </Button>
          </SendBox>
        </InsideContent>
      </Content>
      <ModalConfirmShipping
        handleModalUpdate={handleModalUpdate}
        openUpdate={openUpdate}
        quantity={cartelas}
      />
    </>
  );
};
