import axios from 'axios';
import { configJson } from '../library/utils/firebaseUtils';
import { authTokenWithSC } from './APIs/token';

export const apiV2 = axios.create({
  baseURL: configJson.API_HOST_V2,
});

export const verifyUserPhone = async (phone, token) => {
  try {
    const response = await apiV2.post(
      `/users/getUserBrasilCap/+55${phone}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export function authCode(cellPhone, sendType) {
  //OBTER INFORMAÇÕES DO USUÁRIO
  return new Promise((resolve, reject) => {
    authTokenWithSC()
      .then((token) => {
        axios
          .post(
            configJson.API_HOST_V2 + '/authCode',
            {
              cellphone: '+55' + cellPhone,
              platform: 'SorteSaude-admin',
              languageCode: 'pt_BR',
              sendType,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            if (result.data.success !== null) {
              resolve(result.data);
            } else {
              reject({ messageError: result.data.error.error });
            }
          })
          .catch((error) => {
            console.log('ERROR AUTH SMS ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}

export function validateSMS(uId, code, phoneNumber, transactionId) {
  return new Promise((resolve, reject) => {
    authTokenWithSC(uId)
      .then((token) => {
        axios
          .post(
            configJson.API_HOST_V2 + '/sms/validateSMS',
            {
              code,
              cellphone: '+55' + phoneNumber,
              transactionId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (result) => {
            // console.log('Result VALIDATESMS ==> ', result.data);
            resolve(result.data);
          })
          .catch((error) => {
            // console.log('ERROR VALIDATESMS ==> ', error.response.data.error);
            reject(error.response.data.error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_GET_CHANNEL_TOKEN_GET_USER_INFO ==> ', error);
        reject(error);
      });
  });
}
