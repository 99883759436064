import styled from 'styled-components';
import { Button, Paper } from '@mui/material';

export const ContainerSaleOff = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  margin-bottom: 2rem;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: end;
  gap: 2rem;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

export const ContentButtons = styled.span`
  display: flex;
  flex-direction: column;

  input {
    height: 3rem;
    margin: 0;
    padding: 1rem;
    width: 10rem;
  }

  label {
    font-size: 0.9rem;
  }
`;

export const ButtonSendDateSaleOff = styled(Button)`
  min-width: 12rem !important;
  height: 3rem;
`;

export const ActiveResumePaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  /* height: 5rem; */
  margin-bottom: 1rem;
  padding: 1rem;
  min-width: 70%;

  p {
    margin: 0;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    letter-spacing: 1px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: center;
    }
  }
`;

export const ContentActionButtonSaleOff = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px;
    min-width: 6rem;
    /* width: 38px; */
    height: 32px;
    letter-spacing: 1px;

    &:hover {
      transition: 1s;
      border: solid 1px #0066cc;
    }
  }
`;

export const ContentButtonX = styled.div`
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px;
  color: #aaa;
  cursor: pointer;

  &:hover {
    color: #757575;
    transition: 0.9s;
  }
`;

export const Box = styled.div`
  align-items: center;
  /* justify-content: ; */
  display: flex;
  flex-direction: column;
  row-gap: 14px;

  p {
    text-align: center;
  }
`;
