import axios from 'axios';
import { configJson } from '../../library/utils/firebaseUtils';
import { authToken } from './token';

export function updateNoCodeSell(uId, uidSeller, noCodeSellValue) {
  return new Promise((resolve, reject) => {
    authToken(uId).then((token) => {
      axios
        .put(
          configJson.API_HOST_V2 +
            `/brasilCap/updateNoCodeSell?uidSeller=${uidSeller}`,
          {
            noCodeSellValue,
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          // console.log("RESULTADO DO UPLOAD DE FOTO ==>", result);
          resolve(result);
        })
        .catch((error) => {
          // console.log("ERROR DO UPLOAD DE FOTO ==>", error);
          return reject(error);
        });
    });
  });
}
