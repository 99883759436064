export function validateRegistrationDate(registrationDate, minutes) {
  try {
    const mkTime = new Date(registrationDate).getTime() / 1000.0;
    const mkTimeCurrent = new Date().getTime() / 1000.0;

    const date = Math.floor((mkTimeCurrent - mkTime) / 60);

    if (date > minutes || registrationDate === null) {
      return false;
    } else {
      return true;
    }
  } catch {
    return false;
  }
}