import { useContext, useEffect, useState } from 'react';
import { DistribuitionCartelaContext } from '../../../context/distribuitionCartela';
import { toast } from 'react-toastify';

// STYLES
import { Content, InsideContent } from '../../../styled/generic';

// COMPONENTS
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import { LossTable } from '../../../components/Tables/LossTable';

// APIS
import { getLoss } from '../../../services/APIs/getLoss';

export function LossList() {
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [formattedLoss, setFormattedLoss] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getLoss(uId, serie ? serie : serieUrl, 0, 10000)
      .then((result) => {
        const dataStructuring = result.map((loss) => {
          return loss;
        });

        setFormattedLoss(dataStructuring);
      })
      .catch(() => {
        toast.error('Algo ocorreu de errado. Volte novamente mais tarde.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uId, serie, serieUrl]);

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <LossTable listLoss={formattedLoss} loadingTable={loading} />
        </InsideContent>
      </Content>
    </>
  );
}

