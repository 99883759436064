import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

//STYLES
import {
  ModalHeader,
  ModalBody,
  DivModal,
  HeaderText,
  ButtonReceived,
} from './style/ModalStyle';
import { CircularProgress } from '@mui/material';

// APIS
import { cartelaCancelation } from '../../services/APIs/cartelaCancelation';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';

export default function ModalCancelationCartela({
  isOpen,
  handleCloseModal,
  deliveryId,
}) {
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const cancelationCartela = async () => {
    setLoading(true);
    cartelaCancelation(serie === '' ? serieUrl : serie, deliveryId, uId)
      .then(() => {
        localStorage.setItem('updateList', true);
        close();
        toast.success('Reserva cancelada com sucesso.');
      })
      .catch((error) => {
        setMessage(error);
        setTimeout(() => {
          setMessage('');
        }, 2500);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const close = () => {
    handleCloseModal('cancelation');
    setMessage('');
  };

  return (
    <div className={`modal ${isOpen ? 'modal is-active' : 'modal'}`}>
      <div onClick={close} className="modal-background"></div>
      <DivModal style={{ minHeight: '12rem' }} className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">
            Cancelamento de reserva
          </HeaderText>
        </ModalHeader>
        <ModalBody className="modal-card-body">
          {message !== '' && <p style={{ color: 'red' }}>{message}</p>}
          <h4>Deseja confirmar o cancelamento dessa reserva ?</h4>
          <ButtonReceived
            onClick={cancelationCartela}
            className={window.screen.width > 450 ? 'button' : 'button is-small'}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress
                style={{ color: '#fff' }}
                size={26}
                thickness={5}
              />
            ) : (
              'CONFIRMAR'
            )}
          </ButtonReceived>
        </ModalBody>
      </DivModal>
    </div>
  );
}
