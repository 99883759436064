import { Tooltip } from '@mui/material';
import { MdOutlineReportGmailerrorred } from 'react-icons/md';
import { convertVisualDateForTimestamp } from '../../services/helpers';
import { Link } from 'react-router-dom';
import { ButtonDistribuitionTable } from '../Tables/style/EditButtomTableStyles';
import { useContext } from 'react';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';

export const CardsError = ({ serie, serieEndDate, seriesFinished, status }) => {
  const { setSerie } = useContext(DistribuitionCartelaContext);
  const statusReleasesAction = ['processedSuccess'];

  return (
    statusReleasesAction.includes(status) &&
    !seriesFinished(convertVisualDateForTimestamp(serieEndDate)) && (
      <Tooltip title="Cartelas com erro" placement="top">
        <Link to={`/operacao-cartela/gerenciar-series/erros/${serie}`}>
          <ButtonDistribuitionTable onClick={() => setSerie(serie)}>
            <MdOutlineReportGmailerrorred size={24} style={{ color: '#f52900' }} />
          </ButtonDistribuitionTable>
        </Link>
      </Tooltip>
    )
  );
};
