import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  @media screen and (max-width: 450px) {
    block-size: fit-content;
  }
`;

export const Content = styled.div`
  display: inline-block;
  margin-left: 6.5em;
  width: 90vw;
  height: 100%;
  margin-bottom: 5%;
  @media screen and (max-width: 450px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
  }
`;

export const InsideContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(e) =>
    e.theme === '/operacao-cartela/reservar-cartelas' &&
    `justify-content: flex-start !important;
    align-items: flex-start !important;
    margin: 5% 12%;
    `}
  ${(e) =>
    e.theme === '/operacao-cartela/gerenciar-credenciados' &&
    `justify-content: center !important;
    align-items: center !important;
    `}
    ${(e) =>
    e.theme === '/operacao-cartela/gerenciar-series' &&
    `justify-content: center !important;
    align-items: center !important;
    `}
  ${(e) =>
    e.theme === '/lista-supervisor-vendedor' &&
    `flex-direction: row;
    justify-content: space-around;
    align-items: center !important;
    `}
    ${(e) =>
    e.theme === '/operacao-cartela/reservar-cartelas/tipo-reserva' &&
    `flex-direction: row;
    justify-content: space-around;
    align-items: center !important;
    `}
  ${(e) =>
    e.theme === '/lista-vendedor' &&
    `justify-content: flex-start;
    height: fit-content;
    align-items: center;
    margin-top: 2.5em;
    margin-bottom: 10em;
    `}
        ${(e) =>
    e.theme === '/lista-supervisor' &&
    `justify-content: flex-start;
    align-items: center;
    margin-top: 2.5em;
    flex-direction: column;
    `}
    ${(e) =>
    e.theme === '/dashboard' &&
    `flex-direction: row;
    `}
  @media screen and (max-width: 450px) {
    justify-content: flex-start;
    margin-top: 2em;
    //margin-bottom: 20em;
    ${(e) =>
      e.theme === '/lista-supervisor-vendedor' &&
      `flex-direction: column;
    justify-content: flex-start;
  `}
    ${(e) =>
      e.theme === '/operacao-cartela/reservar-cartelas/tipo-reserva' &&
      `flex-direction: column;
    justify-content: flex-start;
  `}
    ${(e) =>
      e.theme === '/lista-vendedor' &&
      `justify-content: flex-start;
    height: fit-content;
    align-items: center;
    margin-top: 2.5em;
    `}
    ${(e) =>
      e.theme === '/dashboard' &&
      `flex-direction: column;
    `}
  }
`;

export const AlignRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Button = styled.button`
  background-color: #2d70b9;
  color: white;
  margin-top: 50px;
  width: 160px;
  border-radius: 5px;
`;

export const GenericText = styled.div`
  color: white;
  font-size: 0.8em;
  font-weight: 300;
  width: 80%;
  text-decoration: none;
  @media screen and (max-width: 450px) {
    color: white;
    font-size: 0.7em;
    font-weight: 300;
    display: flex;
    text-align: justify;
  }
`;

export const UploadImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fafafa;
  border: 3px dashed #2d70b9;
  border-radius: 10px;
  height: 300px;
  width: 280px;

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;

    color: #aaa;
    cursor: pointer;
    font-size: 0.7rem;
    text-align: center;

    transition: 0.8s;

    &:hover {
      color: #000;
    }

    svg {
      opacity: 0.3;
    }
  }

  input {
    display: none;
  }
`;

