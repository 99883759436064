import md5 from '../library/utils/md5';
import CryptoJS from 'crypto-js';

export function hashTriibo1(apiToken, passPhrase, channelToken) {
  let challenge1 = passPhrase + apiToken; //Passo 1: String a apiToken + passPhrase
  challenge1 = md5.hexMD5(challenge1); //Passo 2: challenge1 ou challenge intermediário

  let challenge2 = challenge1 + channelToken; //Passo 3: String a challenge1 + channelToken - sendo este valor o do ITEM B ou ex: keychannelclubmapfre (é a sua Private KEY)
  challenge2 = md5.hexMD5(challenge2);

  return challenge2;
}

export function encrypt(msg, key) {
  if (!msg) return null;

  const myEncrypt = {
    pass: CryptoJS.enc.Utf8.parse(key),
    iv: CryptoJS.enc.Hex.parse('0000000000000000'),
  };

  let options = {
    mode: CryptoJS.mode.CBC,
    iv: myEncrypt.iv,
  };

  let json = CryptoJS.AES.encrypt(msg, myEncrypt.pass, options);

  return json.ciphertext.toString(CryptoJS.enc.Base64);
}

export function decrypt(msg, key) {
  if (!msg) return null;

  msg = decodeURIComponent(msg);

  const myEncrypt = {
    pass: CryptoJS.enc.Utf8.parse(key),
    iv: CryptoJS.enc.Hex.parse('0000000000000000'),
  };

  let options = {
    mode: CryptoJS.mode.CBC,
    iv: myEncrypt.iv,
  };

  let json = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(msg),
    },
    myEncrypt.pass,
    options
  );
  return json.toString(CryptoJS.enc.Utf8);
}

export function findIndexInArray(array, type, value) {
  let x = null;

  if (Array.isArray(array)) {
    array.map(function (array, i) {
      if (array !== null && array[type] !== undefined && array[type] === value) {
        x = i;
      }

      return x;
    });
  }

  return x;
}

export function validateRegistrationDate(registrationDate) {
  try {
    const mkTime = new Date(registrationDate).getTime() / 1000.0;
    const mkTimeCurrent = new Date().getTime() / 1000.0;

    const date = Math.floor((mkTimeCurrent - mkTime) / 60);

    if (date > 15 || registrationDate === null || !registrationDate) {
      return false;
    } else {
      return true;
    }
  } catch {
    return false;
  }
}

export function trackEventMatomo(location, event, element, elementName) {
  const elementType = `element:${element}; elementName:${elementName}`;

  var _paq = (window._paq = window._paq || []);
  _paq.push(['trackEvent', location, event, elementType]);
}

export function trackEventMatomoElementId(location, event, element, elementId) {
  const elementType = `element:${element}; elementId:${elementId}`;

  var _paq = (window._paq = window._paq || []);
  _paq.push(['trackEvent', location, event, elementType]);
}

export function trackEventMatomoVisit(locationName) {
  var _paq = (window._paq = window._paq || []);
  _paq.push(['trackEvent', locationName, 'visualizar', window.location.href, 0]);
}

export function getUrlVariables() {
  let GET = {};
  if (document.location.toString().indexOf('?') !== -1) {
    let query = document.location
      .toString()
      .replace(/^.*?\?/, '')
      .replace(/#.*$/, '')
      .split('&');

    for (let i = 0, l = query.length; i < l; i++) {
      let aux = decodeURIComponent(query[i]).split('=');
      GET[aux[0]] = aux[1];
    }
  }

  return GET;
}

//CONVERTE DATA PARA MILISEGUNDOS
export function convertToMillis(date) {
  let converteddDate = date.split('T');

  let newDate = converteddDate[0].split('-');
  let hour = converteddDate[1].split(':');

  converteddDate = new Date(
    parseInt(newDate[0], 10),
    parseInt(newDate[1] - 1, 10),
    parseInt(newDate[2], 10),
    parseInt(hour[0], 10),
    parseInt(hour[1], 10)
  ).getTime();

  return converteddDate;
}

export function monetaryFormat(value) {
  if (value.length === 0) {
    return '0,00';
  }

  value = parseFloat(value).toFixed(2);
  value = value.replace('.', ',');
  value = value.split(',');

  let int = value[0];
  const cents = value[1];

  if (int.length === 0) {
    int = '';
  } else if (int.length === 4) {
    int = int.substr(0, 1) + '.' + int.substr(1, 3);
  } else if (int.length === 5) {
    int = int.substr(0, 2) + '.' + int.substr(2, 4);
  } else if (int.length === 6) {
    int = int.substr(0, 3) + '.' + int.substr(3, 5);
  } else if (int.length === 7) {
    int = int.substr(0, 1) + '.' + int.substr(1, 3) + '.' + int.substr(4, 5);
  }

  return int + ',' + cents;
}

// Converte millisecundos em mes e ano.
export function convertMilliForMonthAndYear(milissegundos) {
  var data = new Date(milissegundos);
  var mes = data.getMonth() + 1; // Os meses são indexados de 0 a 11, então é necessário adicionar 1
  var ano = data.getFullYear();

  return { mes: '0' + mes, ano: ano };
}

// CONVERTE A DATA yyyy-MM-dd EM TIMESTAMP
export const convertDate = (value, type) => {
  const oneDayInMilliseconds = 86400000;
  const initDate = 75540000;
  const finalDate = 10790000;
  let timeStamp = new Date(value).getTime() + oneDayInMilliseconds;

  switch (type) {
    case 'start':
      return (timeStamp = new Date(value).getTime() + oneDayInMilliseconds - initDate);
    case 'final':
      return (timeStamp = new Date(value).getTime() + oneDayInMilliseconds + finalDate);
    default:
      return timeStamp;
  }
};

// CONVERTER DATA DD/MM/YYYY em yyyy-MM-dd
export const convertDateBrForUs = (brDate) => {
  const fragmentDate = brDate.split('/');
  const day = fragmentDate[0];
  const month = fragmentDate[1];
  const year = fragmentDate[2];

  const dateObject = new Date(year, month - 1, day);
  const finallyDate = dateObject.toISOString().slice(0, 10);

  return finallyDate;
};

//CONVERTER DATA DE  DD/MM/YYYY PARA MILLISECONDS
export const convertVisualDateForTimestamp = (visualDate) => {
  // CONVERTE DE DD/MM/YYYY PARA yyyy-MM-dd
  const fragmentDate = visualDate.split('/');
  const day = fragmentDate[0];
  const month = fragmentDate[1];
  const year = fragmentDate[2];

  const dateObject = new Date(year, month - 1, day);
  const usDate = dateObject.toISOString().slice(0, 10);

  // CONVERTE yyyy-MM-dd PARA TIMESTAMP
  const oneDayInMilliseconds = 86400000;
  const timeStamp = new Date(usDate).getTime() + oneDayInMilliseconds;

  return timeStamp;
};

//CONVERTE MILISEGUNDOS PARA DATA
export function convertToFullDate(date) {
  let converteddDate = new Date(date);

  const year = converteddDate.getFullYear();
  const month =
    converteddDate.getMonth() + 1 < 10
      ? '0' + (converteddDate.getMonth() + 1)
      : converteddDate.getMonth() + 1;
  const day =
    converteddDate.getDate() < 10 ? '0' + converteddDate.getDate() : converteddDate.getDate();
  const hour =
    converteddDate.getHours() < 10 ? '0' + converteddDate.getHours() : converteddDate.getHours();
  const minute =
    converteddDate.getMinutes() < 10
      ? '0' + converteddDate.getMinutes()
      : converteddDate.getMinutes();

  converteddDate = day + '/' + month + '/' + year + ' - ' + hour + ':' + minute;

  return converteddDate;
}

//CONVERTE MILISEGUNDOS PARA DATA
export function convertToFullDateNoHours(date) {
  let converteddDate = new Date(date);

  const year = converteddDate.getFullYear();
  const month =
    converteddDate.getMonth() + 1 < 10
      ? '0' + (converteddDate.getMonth() + 1)
      : converteddDate.getMonth() + 1;
  const day =
    converteddDate.getDate() < 10 ? '0' + converteddDate.getDate() : converteddDate.getDate();

  converteddDate = day + '/' + month + '/' + year;

  return converteddDate;
}

//CONVERTE MILISEGUNDOS PARA DATA COM SEGUNDOS
export function convertToFullDateWithSeconds(date) {
  let converteddDate = new Date(date);

  const year = converteddDate.getFullYear();
  const month =
    converteddDate.getMonth() + 1 < 10
      ? '0' + (converteddDate.getMonth() + 1)
      : converteddDate.getMonth() + 1;
  const day =
    converteddDate.getDate() < 10 ? '0' + converteddDate.getDate() : converteddDate.getDate();
  const hour =
    converteddDate.getHours() < 10 ? '0' + converteddDate.getHours() : converteddDate.getHours();
  const minute =
    converteddDate.getMinutes() < 10
      ? '0' + converteddDate.getMinutes()
      : converteddDate.getMinutes();
  const seconds =
    converteddDate.getSeconds() < 10
      ? '0' + converteddDate.getSeconds()
      : converteddDate.getSeconds();

  converteddDate = day + '/' + month + '/' + year + ' - ' + hour + ':' + minute + ':' + seconds;

  return converteddDate;
}

//RETORNAR A HORA NO FORMATO CRIADO NO DATETIME DO MATERIAL UI yyyy-MM-ddTHH:mm
export function convertMillisecondsToDateTime(milliseconds) {
  const date = new Date(milliseconds);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

// VERIFICAR SE O SUPERVISOR TEM OS MODULOS DE ACESSO PARA AS CARTELAS
export const verifyModules = (modules) => {
  const result = modules?.includes('cards') && modules?.includes('series');
  localStorage.setItem('isModule', result !== undefined ? result : false);

  return result;
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onloadend = () => {
      const binaryString = reader.result;
      const base64String = btoa(binaryString);
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};
