import axios from 'axios';
import { configJson } from '../../library/utils/firebaseUtils';
import { authToken } from './token';

export function getWinners(uId) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        var config = {
          method: 'get',
          url: configJson.API_HOST_V2 + '/brasilCap/getWinners',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        };

        axios(config)
          .then((result) => {
            //console.log("RESULT GET WINNERS: ", result);
            resolve(result.data.response);
          })
          .catch((error) => {
            //console.log("ERROR WINNERS: ", error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log("ERROR_TOKEN", error);
        reject(error);
      });
  });
}
