import HeaderComp from "../../components/Header/HeaderComp";
import SideBarDesktop from "../../components/Sidebar/SideBarComp";
import { Content, InsideContent } from "../../styled/generic";
import Footer from "../../components/Footer/Footer";
import {
  ButtonAdministration,
  ContainerAdministration,
} from "./style/Adminstration";
import { Link } from "react-router-dom";

export function Administration() {
  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <ContainerAdministration>
            <ButtonAdministration>
              <Link to="/administracao/lista-de-sorteios">
                Lista de sorteios
              </Link>
            </ButtonAdministration>
            <ButtonAdministration>
              <Link to="/administracao/depoimentos">Depoimentos</Link>
            </ButtonAdministration>
          </ContainerAdministration>
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
