import React, { useState } from 'react';

//STYLES
import {
  ModalHeader,
  ModalBody,
  DivModal,
  HeaderText,
  Label,
  InputReceived,
  SendCartelaButton,
} from './style/ModalStyle';
import { CircularProgress } from '@mui/material';

// APIS
import { confirmCardsDelivered } from '../../services/APIs/confirmCardsDelivered';

// OTHERS
import { toast } from 'react-toastify';

export default function ModalReceivedCartelas({ isOpen, handleCloseModal, remessa, accreditedId }) {
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const confirmReceivedCartelas = async () => {
    setLoading(true);
    try {
      if (name === '') {
        setMessage('É necessário inserir o nome do recebedor da remessa.');

        setTimeout(() => {
          setMessage('');
        }, 2500);
      } else {
        await confirmCardsDelivered(uId, accreditedId, remessa, name);
        toast.success('Entrega confirmada com sucesso.');
        setName('');

        localStorage.setItem('updateList', true);
        handleCloseModal('received');
      }
    } catch (error) {
      setMessage(error);
      setName('');

      setTimeout(() => {
        setMessage('');
      }, 3000);
    } finally {
      setLoading(false);
      setName('');
    }
  };

  const close = () => {
    setName('');
    setMessage('');
    handleCloseModal('received');
  };

  return (
    <div className={`modal ${isOpen ? 'modal is-active' : 'modal'}`}>
      <div onClick={close} className="modal-background"></div>
      <DivModal style={{ minHeight: '12rem' }} className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">Confirmar recebimento de remessa</HeaderText>
        </ModalHeader>
        <ModalBody className="modal-card-body">
          {message !== '' && <p style={{ color: 'red' }}>{message}</p>}
          <Label>Nome do recebedor:</Label>
          <InputReceived focused onChange={(e) => setName(e.target.value)} required value={name} />
          <SendCartelaButton
            disabled={loading}
            onClick={confirmReceivedCartelas}
            variant="contained"
          >
            {loading ? (
              <CircularProgress style={{ color: '#fff' }} size={26} thickness={5} />
            ) : (
              'CONFIRMAR'
            )}
          </SendCartelaButton>
        </ModalBody>
      </DivModal>
    </div>
  );
}

