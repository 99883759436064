import Footer from '../components/Footer/Footer';
import React, { useCallback, useEffect, useState } from 'react';
import HeaderComp from '../components/Header/HeaderComp';
import SideBarDesktop from '../components/Sidebar/SideBarComp';
import { Content, InsideContent } from '../styled/generic';
import Logo from '../assets/images/LogoSquareV10.png';
import {
  BlueBox,
  SmallWhiteBox,
  LogoBlueBox,
  LabelBlueBox,
  WhiteBox,
  Input,
  SmallLabel,
  Button,
  Form,
  DivInputBlueBox,
} from '../styled/RegisterStyle';
import ReactInputMask from 'react-input-mask';
import ModalRegister from '../components/Modals/ModalRegister';
import { verifyUserPhone } from '../services/login';
import { authToken } from '../services/APIs/token';
import { Oval } from 'react-loading-icons';
import { toast } from 'react-toastify';
import { findIndexInArray } from '../services/helpers';
import { useNavigate } from 'react-router-dom';
import { createAffiliate } from '../services/register';
import { configJson } from '../library/utils/firebaseUtils';

export default function SupervisorRegister() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [setCanShow] = useState(false);
  const [user, setUser] = useState();
  const [comission, setComission] = useState(10);
  const [id, setId] = useState();

  const navigate = useNavigate();

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const tokenResponse = await authToken();
      if (tokenResponse) {
        const response = await verifyUserPhone(phoneNumber, tokenResponse);
        if (response.data.error) {
          setUser('');
          toast.error('Usuário não encontrado!');
        }
        setId(response.data.response.id);
        setUser(
          response.data.response.partnerList[
            findIndexInArray(
              response.data.response.partnerList,
              'partnerName',
              'BrasilCap'
            )
          ]
        );
        setCanShow(true);
        setIsLoading(false);
      }
    } catch (AxiosError) {
      setPhoneNumber('');
      setIsLoading(false);
      return AxiosError;
    }
  }, [phoneNumber, setCanShow]);

  useEffect(() => {
    if (phoneNumber.length === 11) {
      getUser();
    }
  }, [phoneNumber, getUser]);

  const afiliateRegister = async () => {
    const affiliations = {
      [configJson.partnerNameBBCap]: {
        status: true,
        commission: parseInt(comission, 10) / 100,
      },
    };
    const tokenResponse = await authToken(
      JSON.parse(localStorage.getItem('uid'))
    );

    const response = await createAffiliate(tokenResponse, id, affiliations);
    if (response.data.error === 'A afiliação já existe') {
      toast.error('Afiliado já cadastrado!');
    } else {
      toast.success('Afiliado cadastrado!');
      navigate('/lista-afiliados');
    }
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              afiliateRegister();
            }}
          >
            <BlueBox>
              <LogoBlueBox src={Logo} />
              <DivInputBlueBox>
                <LabelBlueBox className="label" htmlFor="tel">
                  Cadastro de novo afiliado(a)
                </LabelBlueBox>
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Oval stroke="white" />
                  </div>
                ) : (
                  <ReactInputMask
                    mask="99 99999-9999"
                    maskChar=""
                    alwaysShowMask={false}
                    id="tel"
                    name="tel"
                    type="tel"
                    placeholder="Telefone"
                    className="input is-fullwidth is-small"
                    value={phoneNumber}
                    onChange={(e) => {
                      const cleaned = e.target.value.replace(' ', '');
                      const removeSpace = cleaned.replace('-', '');
                      setPhoneNumber(removeSpace);
                    }}
                  />
                )}
              </DivInputBlueBox>
            </BlueBox>
            <WhiteBox className="box">
              <SmallLabel className="label">
                Dados Pessoais
                <Input
                  value={user && user.name}
                  type="text"
                  placeholder="Nome"
                  className={
                    window.screen.width > 450
                      ? 'input is-fullwidth'
                      : 'input is-fullwidth is-small'
                  }
                  disabled
                />
              </SmallLabel>
              <ReactInputMask
                mask="999.999.999-99"
                style={{
                  marginBottom: '1em',
                }}
                value={
                  user &&
                  user.documentList.find((el) => el.type === 'cpf').value
                }
                type="text"
                placeholder="CPF"
                className={
                  window.screen.width > 450
                    ? 'input is-fullwidth'
                    : 'input is-fullwidth is-small'
                }
                disabled
              />
              <Input
                defaultValue={
                  user &&
                  user.contactList.find((el) => el.type === 'email').value
                }
                type="email"
                placeholder="E-mail"
                className={
                  window.screen.width > 450
                    ? 'input is-fullwidth'
                    : 'input is-fullwidth is-small'
                }
                disabled
              />
            </WhiteBox>
            <SmallWhiteBox className="box">
              {/* <input
                value={userLogged}
                placeholder="Gerente responsável"
                className={
                  window.screen.width > 450 ? "input is-fullwidth" : "input is-fullwidth is-small"
                }
                disabled
              /> */}
              <strong style={{ fontSize: 17, marginRight: 10 }}>
                Comissão
              </strong>
              <Input
                style={{
                  marginBottom: 0,
                  // fontSize: 15
                }}
                value={comission}
                onChange={(e) => setComission(e.target.value)}
                type="cellphone"
                placeholder="Comissão"
                className={
                  window.screen.width > 450
                    ? 'input is-fullwidth'
                    : 'input is-fullwidth is-small'
                }
              />
              <strong style={{ fontSize: 22, marginLeft: 10 }}>%</strong>
            </SmallWhiteBox>
            <Button
              type="submit"
              className={
                window.screen.width > 450 ? 'button' : 'button is-small'
              }
              disabled={!user}
            >
              Salvar
            </Button>
          </Form>
          <ModalRegister />
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
