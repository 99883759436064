import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authToken } from "../../services/APIs/token";
import { getUsersAffiliated } from "../../services/getAll";
import { useQuery } from "@tanstack/react-query";
import { BsCircleFill } from "react-icons/bs";
import MUIDataTable from "mui-datatables";
import { StyledDiv } from "../Tables/style/MUIDatatable";
import { CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { BottomMargin } from "../../pages/SellerPage/style/SellerPageStyle";
import { decrypt } from "../../helpers/crypt";
import { configJson } from "../../library/utils/firebaseUtils";

export default function AffiliatedListTable({ selected }) {
  const role = JSON.parse(localStorage.getItem("role"));
  const [cpfSelected, setCpfSelected] = useState("");
  const [phoneSelected, setPhoneSelected] = useState("");

  const { data, isLoading, isError } = useQuery({
    queryKey: ["SellerList"],
    queryFn: async () => {
      const token = await authToken(JSON.parse(localStorage.getItem("uid")));
      if (token) {
        const response = await getUsersAffiliated(token);
        return response.data;
      }
    },
    keepPreviousData: true,
    retry: 1,
    refetchOnWindowFocus: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (cpfSelected !== "") {
      localStorage.setItem(
        "affiliatePhone",
        displayData.find(({ cellphone }) => cellphone === "+55" + phoneSelected)
          .cellphone
      );
      navigate(
        `/afiliado/${displayData.find(
          ({ cpf }) => decrypt(cpf, configJson.CRYPTO_KEY) === cpfSelected
        ).uid
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpfSelected, phoneSelected, navigate]);

  if (isError)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {`Não existem afiliados cadastrados para este ${role === "manager" ? "gerente" : "supervisor"
          }`}
      </div>
    );
  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );

  const displayData =
    data && data.result.filter((el) => el.affiliation.status === selected);

  const columns = [
    {
      name: "name",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: "cpf",
      label: "CPF",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          value = decrypt(value, configJson.CRYPTO_KEY);
          const cleaned = ("" + value).replace(/\D/g, "");
          let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
          if (match) {
            return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
          }
        },
      },
    },
    {
      name: "cellphone",
      label: "Telefone",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          const cleaned = ("" + value).replace("+55", "");
          let match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
          if (match) {
            return `${match[1]} ${match[2]} ${match[3]}-${match[4]}`;
          }
        },
      },
    },
    {
      name: "email",
      label: "E-Mail",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
      },
    },
    {
      name: "affiliation",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          if (!value.status) {
            return (
              <span>
                <BsCircleFill style={{ color: "#8A8F91" }} />
                &nbsp;Inativo
              </span>
            );
          } else {
            return (
              <span>
                <BsCircleFill style={{ color: "#2DB94D" }} />
                &nbsp;Ativo
              </span>
            );
          }
        },
      },
    },
  ];

  const tableOptions = {
    onRowClick: (rowData) => {
      setCpfSelected(rowData[1].replaceAll(".", "").replace("-", ""));
      setPhoneSelected(rowData[2].replaceAll(" ", "").replace("-", ""));
      // setSupervisor(rowData[4]);
    },
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: "simple",
    filterType: "textField",
    textLabels: {
      body: {
        noMatch: "",
        toolTip: `${"table.toolTip"}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${"table.viewColumnsTitle"}`,
      },
    },
  };
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: "85%",
            },
          },
        },
      },
    });

  return (
    <BottomMargin>
      <StyledDiv style={{ cursor: "pointer" }}>
        <ThemeProvider theme={() => getMuiTheme()}>
          <MUIDataTable
            className={"table is-mobile"}
            data={displayData}
            columns={columns}
            options={tableOptions}
          />
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}
