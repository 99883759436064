import { useContext, useEffect, useState } from 'react';

// STYLES
import { Content, InsideContent } from '../../../styled/generic';
import { FormControlLabel, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import { FormLabel } from '@mui/material';
import { SendCartelaButton } from '../../../components/Modals/style/ModalStyle';
import { Example, WhiteBoxLows } from '../styles/DistribuitionCartelasStyle';
import { MdQrCodeScanner } from 'react-icons/md';

// COMPONENTS
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import ModalGenericConfirmProccess from '../../../components/Modals/ModalGenericConfirmProccess';

// APIS
import { deactivateCards } from '../../../services/APIs/deactivateCards ';

// OTHERS
import { DistribuitionCartelaContext } from '../../../context/distribuitionCartela';
import { toast } from 'react-toastify';
import { CartelaNotActivy } from './CartelasNotActivy';
import { ModalScan } from '../../../components/Modals/ModalScanQrCode';

export function Lows() {
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [loading, setLoading] = useState(false);
  const [numberIdentifier, setNumberIdentifier] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [messageModal, setMessageModal] = useState('');
  const [finishedSerieIsEnable, setFinishedSerieIsEnable] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [openScan, setOpenScan] = useState(false);

  useEffect(() => {
    if (qrCode !== '') {
      const numberForTypeSelected = qrCode?.split('_');
      if (selected) {
        setNumberIdentifier(numberForTypeSelected && numberForTypeSelected[2]);
      } else {
        setNumberIdentifier(numberForTypeSelected && numberForTypeSelected[3]);
      }
    }
  }, [qrCode, selected]);

  const handleStateModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // SELECIONA SE É LOTE OU SERIE E ISSO DEFINE O TRATAMENTO DO INPUT
  const toggleType = (type) => {
    switch (type) {
      case 'lote':
        setSelected(true);
        setNumberIdentifier('');
        break;
      case 'cartela':
        setSelected(false);
        setNumberIdentifier('');
        break;
      default:
        break;
    }
  };

  const sanitizeInput = (event) => {
    const input = event.target.value;
    const numbersOnly = input.replace(/[^0-9]/g, '');

    setNumberIdentifier(numbersOnly);
  };

  const handleCloseScan = () => {
    setOpenScan(false);
  };

  const handleOpenScan = () => {
    setOpenScan(true);
  };

  const sendLows = () => {
    setLoading(true);

    deactivateCards(uId, serie ? serie : serieUrl, numberIdentifier)
      .then(() => {
        toast.success('Baixa efetuada com sucesso.');
        setNumberIdentifier('');
        setQrCode('');
        setMessageModal('');
        handleStateModal();
      })
      .catch((error) => {
        setMessageModal(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <WhiteBoxLows className="box">
            <ModalScan
              isOpen={openScan}
              onRequestClose={handleCloseScan}
              qrInfo={qrCode}
              setQrInfo={setQrCode}
            />
            {!finishedSerieIsEnable ? (
              <CartelaNotActivy setFinishedSerieIsEnable={setFinishedSerieIsEnable} />
            ) : (
              <>
                <FormLabel id="demo-radio-buttons-group-label">
                  Selecione a forma de realizar a baixa das cartelas:
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  style={{ margin: '0 0 1rem 1rem' }}
                >
                  <FormControlLabel
                    value="Lote"
                    control={<Radio />}
                    label="Lote"
                    onChange={() => toggleType('lote')}
                  />
                  <FormControlLabel
                    value="Cartela"
                    control={<Radio />}
                    label="Cartela"
                    onChange={() => toggleType('cartela')}
                  />
                </RadioGroup>
                <Tooltip
                  title={`${selected === undefined ? 'Selecione: lote ou cartela' : ''}`}
                  placement="right"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItens: 'center',
                    gap: '0.6rem',
                  }}
                >
                  <TextField
                    disabled={selected === undefined}
                    id="outlined-basic"
                    onChange={sanitizeInput}
                    label={selected === true ? 'Lote' : selected === false ? 'Cartela' : ''}
                    value={numberIdentifier}
                    variant="outlined"
                    style={{
                      backgroundColor: `${selected === undefined ? '#f0f0f0' : '#fff'}`,
                      minWidth: '16rem',
                    }}
                    inputProps={{ maxLength: selected === true ? 5 : 16 }}
                  />
                  <button
                    style={{ backgroundColor: 'transparent', border: 0, textDecoration: 'none' }}
                    disabled={selected === undefined}
                    onClick={handleOpenScan}
                  >
                    <MdQrCodeScanner
                      size={50}
                      style={{
                        color: `${selected === undefined ? null : '#2d70b9'}`,
                        cursor: `${selected === undefined ? 'inherit' : 'pointer'}`,
                      }}
                    />
                  </button>
                </Tooltip>
                <Example style={{ marginLeft: '6px', marginTop: '10px' }}>
                  AA - XXXX - <b style={{ color: 'red' }}>XXXXX</b> -{' '}
                  <b style={{ color: '#2d70b9' }}>XXXXXXXXXXX</b> - X
                </Example>
                <Example>
                  Serie - Caixa - <b style={{ color: 'red' }}>Lote</b> -{' '}
                  <b style={{ color: '#2d70b9' }}>Cartela</b> - Digito verificador
                </Example>
                <SendCartelaButton
                  disabled={selected === undefined || numberIdentifier.length < 4 || loading}
                  style={{ margin: '1rem auto 0 auto' }}
                  onClick={handleStateModal}
                  variant="contained"
                >
                  Dar Baixa
                </SendCartelaButton>
              </>
            )}
          </WhiteBoxLows>
        </InsideContent>
        <ModalGenericConfirmProccess
          action={sendLows}
          body={`Deseja confirmar a baixa ${
            selected === true ? 'do lote: ' : 'da cartela: '
          } ${numberIdentifier} `}
          canceled={true}
          close={handleStateModal}
          labelButton={'Confirmar'}
          labelCancelation={'Cancelar'}
          loading={loading}
          message={messageModal}
          setMessage={setMessageModal}
          open={isModalOpen}
          title={'Confirmar baixa?'}
        />
      </Content>
    </>
  );
}
