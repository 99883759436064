import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import HeaderComp from '../../components/Header/HeaderComp';
import SideBarDesktop from '../../components/Sidebar/SideBarComp';
import Footer from '../../components/Footer/Footer';

import { Content, InsideContent } from '../../styled/generic';
import { convertBase64, getUrlVariables } from '../../services/helpers';

import {
  TextField,
  Button,
  Container,
  Box,
  InputLabel,
  Input,
  CircularProgress,
  Typography,
  Switch,
} from '@mui/material';

import { authToken } from '../../services/APIs/token';
import {
  createTestimonial,
  getTestimonialById,
  updateTestimonial,
} from '../../services/updateStatus';
import { uploadFiles } from '../../services/APIs/uploadFiles';
import { toast } from 'react-toastify';

export default function TestimonyPage() {
  const navigate = useNavigate();
  const urlVars = getUrlVariables();
  const id = urlVars.id;
  const [loading, setLoading] = useState();
  const [error, setError] = useState('');
  const [loadingSubmit, setloadingSubmit] = useState(false);

  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [quote, setQuote] = useState('');
  const [image, setImage] = useState('');
  const [tempImage, setTempImage] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (id) {
      setLoading(true);
      authToken(localStorage.getItem('uid')).then((token) => {
        getTestimonialById(id, token)
          .then((response) => {
            const testimony = response.result;
            setTitle(testimony.title);
            setSubTitle(testimony.subTitle);
            setQuote(testimony.quote);
            setImage(testimony.image);
            setActive(testimony.status);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setError(e);
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    setImage(URL.createObjectURL(file));

    const base64 = await convertBase64(file);
    setTempImage(base64);
  };

  const handleSubmit = async () => {
    setloadingSubmit(true);
    toast.info('Enviando depoimento, aguarde!');
    let nameFile;

    if (tempImage)
      nameFile = await uploadFiles('depoimentos', {
        mimeType: 'image/png',
        fileName: 'winner-image',
        buffer: tempImage,
      });

    // console.log(nameFile)

    const data = {
      title: title,
      subTitle: subTitle,
      quote: quote,
      image: tempImage ? nameFile.data.result : image,
      prizeType: 'sortesaude',
      status: active,
    };
    const token = await authToken(localStorage.getItem('uid'));
    if (id) {
      updateTestimonial(id, token, data).then((response) => {
        if (response.data.success) {
          toast.success('sucesso!');
          setloadingSubmit(false);
        }
      });
    } else {
      createTestimonial(token, data).then((response) => {
        if (response.data.success) {
          toast.success('sucesso!');
          navigate('/administracao/depoimentos');
        }
      });
    }
  };

  if (error) return <></>;
  else if (loading)
    return (
      <div style={{ display: 'flex' }}>
        <CircularProgress />
      </div>
    );
  else
    return (
      <>
        <SideBarDesktop />
        <Content id="content">
          <HeaderComp />
          <InsideContent id="inside-content">
            <Container maxWidth="sm">
              <Box>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="caption">Inativo</Typography>
                  <Switch checked={active} onChange={() => setActive(!active)} />
                  <Typography variant="caption">Ativo</Typography>
                </div>
                <TextField
                  fullWidth
                  style={{
                    marginBottom: 20,
                  }}
                  label="Título"
                  placeholder="EX: José Maria da Silva - São José do Rio Preto/SP"
                  variant="standard"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                  fullWidth
                  style={{
                    marginBottom: 20,
                  }}
                  label="Subtítulo"
                  placeholder="EX: Ganhador(a) do sorteio XX/XX/XXXX do Show de Prêmios"
                  variant="standard"
                  value={subTitle}
                  onChange={(e) => setSubTitle(e.target.value)}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  style={{
                    marginBottom: 20,
                  }}
                  label="Depoimento"
                  variant="outlined"
                  value={quote}
                  onChange={(e) => setQuote(e.target.value)}
                />
                <InputLabel htmlFor="image-upload">Imagem do ganhador</InputLabel>
                <Input
                  fullWidth
                  inputProps={{ accept: 'image/png' }}
                  id="image-upload"
                  type="file"
                  onChange={handleImageUpload}
                />
                {id && !tempImage ? (
                  <img src={image} alt="saved" />
                ) : (
                  image && <img src={image} alt="Uploaded" />
                )}
                <Button
                  fullWidth
                  style={{ marginTop: 30 }}
                  variant="contained"
                  color="primary"
                  disabled={
                    loadingSubmit ||
                    !image ||
                    title.length < 5 ||
                    subTitle.length < 10 ||
                    quote.length < 10
                  }
                  onClick={handleSubmit}
                >
                  {loadingSubmit ? <CircularProgress /> : 'Salvar'}
                </Button>
              </Box>
            </Container>
          </InsideContent>
        </Content>
        <Footer />
      </>
    );
}

