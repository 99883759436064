import styled from "styled-components";

export const ContentPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  @media screen and (max-width: 450px) {
  }
`;

export const LeftDiv = styled.div`
  display: flex;
  margin-top: 1em ;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2em;
  @media screen and (max-width: 450px) {
    margin-top: 1em;
    align-content: center;
    justify-content: center;
    margin-left: 0;
  }
`;

export const ProfileBoxDesktop = styled.div`
  //margin-top: 50px;
  max-width: 40%;
  background-color: #2d70b9;
  width: 40vw;
  height: fit-content;
  border-radius: 10px;
  padding: 1.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const ProfileBoxMobile = styled.div`
  background-color: #2d70b9;
  width: 90vw;
  height: fit-content;
  border-radius: 10px;
  margin-left: 8%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;
`;

export const PictureBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: 450px) {
    height: 50%;
  }
`;

export const DataBox = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  //margin: 0;
  margin-left: 1.5em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  @media screen and (max-width: 450px) {
    margin-left: 1em;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  //flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  @media screen and (max-width: 450px) {
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`;

export const WhiteBox = styled.div`
  width: 45%;
  height: fit-content;
  border-radius: 10px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 450px) {
    width: 80vw;
    height: 12em;
    margin-top: 1em;
  }
`;

export const WhiteBoxSeller = styled.div`
  width: 35vw;
  height: fit-content;
  border-radius: 10px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.5em;
  //align-self: flex-start;
  flex-wrap: wrap;
  margin-top: -0.7em;
  @media screen and (max-width: 450px) {
    width: 90vw;
    height: fit-content;
    margin-top: 1em;
    margin-left: 10%;
  }
`;

export const Role = styled.p`
  font-size: 0.9em;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 0.6em;
  }
`;

export const NameNRole = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
  align-items: left;
  justify-content: left;
  @media screen and (max-width: 450px) {
    margin-bottom: -10px;
  }
`;

export const Name = styled.p`
  font-weight: 300;
  @media screen and (max-width: 450px) {
    font-size: 0.8em;
  }
`;

export const BottomMargin = styled.div`
  @media screen and (max-width: 450px) {
    padding-bottom: 6em;
  }
`;

export const BoxBalance = styled.div`
  //padding: 2em;
  //justify-self: unset;
  //justify-content: center;
  //min-width: 20vw;
  width: 85vw;
  height: fit-content;
  //margin-left: 1.5em;
  border-radius: 10px;
  //margin-bottom: 2em;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 80vh;
    padding: 0;
    //justify-self: center;
    //min-width: 80vw;
    margin-left: 10%;
    //align-self: center;
    //margin-top: 0.5em;
    //margin-left: 3.5em;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  //overflow: auto;
  @media screen and (max-width: 450px) {
    margin-bottom: 8em;
  }
`;
