import axios from 'axios';
import { configJson } from '../library/utils/firebaseUtils';
import { authTokenWithSC } from './APIs/token';

export function getUserBrasilCap(phone) {
  return new Promise((resolve, reject) => {
    authTokenWithSC()
      .then((token) => {
        axios
          .post(
            configJson.API_HOST_V2 + `/users/getUserBrasilCap/+55${phone}`,
            null,
            { headers: { authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            // console.log('RESULT USER BRASIL CAP ==>', result.data.response);
            resolve(result.data.response);
          })
          .catch((error) => {
            // console.log('ERROR USER BRASIL CAP ==>', error);
            reject(error.response.data.error); // Reject the promise with the error
          });
      })
      .catch((authError) => {
        reject(authError); // Reject the promise with the authentication error
      });
  });
}
