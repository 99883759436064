import styled from 'styled-components';
import {
  ContentNoHaveEstablishment,
  TableEstablishmentUser,
} from '../../../pages/DistribuitionCartelas/styles/DistribuitionCartelasStyle';
import { Button, TextField } from '@mui/material';
import Modal from 'react-modal';

export const ModalHeader = styled.header`
  background-color: #2d70b9;
  display: flex;
  align-items: center;
  height: 3em;
  @media screen and (max-width: 450px) {
    height: 1.5em;
  }
`;

export const ModalBody = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => (props.$spacing ? props.$spacing : '1rem')};
  padding: 1.5em;
`;

export const DivModal = styled.div`
  width: 45em;
  height: ${(props) => (props.radius ? '45em' : '18em')};
  border-radius: ${(props) => (props.radius ? '5px' : '10px')};
  @media screen and (max-width: 450px) {
    width: 23em;
    height: 10em;
  }
`;

export const HeaderText = styled.p`
  font-size: 1.5em;
  color: white;
  margin-top: 0.5em;
  margin-left: 1em;
  @media screen and (max-width: 450px) {
    font-size: 1em;
    margin-top: 0.8em;
  }
`;

//MODALCONFIRMSHIPPING
export const ContainerModal = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  font-family: 'Inter', Roboto, sans-serif;
  width: 100%;

  h4 {
    font-size: 1.4rem;
    max-width: 90%;
    text-align: center;
  }
`;

export const SearchInputContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const SearchInput = styled.input`
  padding-right: 30px;
  width: 100%;
  min-width: 16rem;
  min-height: 3rem;
  box-sizing: border-box;

  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;

  @media screen and (max-width: 500px) {
    min-height: 1.2rem;
    min-width: 13rem;
  }
`;

export const SearchIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  svg {
    fill: #ccc;
  }
`;

export const Container = styled.main`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const ContainerSelects = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 0.8rem;
  font-weight: 600;
  width: 48%;

  span {
    margin: 0.3rem;
  }
`;

export const ContentSelectAccredit = styled(TableEstablishmentUser)`
  border: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3) !important;

  font-size: 0.6rem;
  max-height: 14rem;
  width: 100%;

  input {
    cursor: pointer;
    height: 1rem;
    min-width: 1rem;
  }

  tr {
    justify-content: flex-start;
    margin-top: 0.4rem;
  }

  span {
    display: flex;
    flex-direction: column;
  }
`;

export const TableDataInfo = styled.td`
  &:nth-child(1) {
    font-size: 0.9rem;
  }
`;

export const QuantityValue = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
`;

export const SendCartelaButton = styled(Button)`
  background-color: ${(props) => props.$background && props.$background} !important;
  margin-top: ${(props) => props.$spacingTop && props.$spacingTop} !important;
  min-width: 10rem !important;

  .css-18lrjg1-MuiCircularProgress-root {
    color: #fff;
  }
`;

export const ContentNoHave = styled(ContentNoHaveEstablishment)`
  .css-18lrjg1-MuiCircularProgress-root {
    color: #fff;
  }
`;

export const Label = styled.label`
  font-size: 1.2rem;
  font-weight: 600;

  @media screen and (max-width: 500px) {
    font-size: 1rem;
  }
`;

export const InputReceived = styled(TextField)`
  min-width: 18rem;
  width: 18rem;
  @media screen and (max-width: 500px) {
    max-width: 12rem;

    input {
      padding: 8px !important;
    }
  }
`;

export const ButtonReceived = styled.button`
  color: white;
  background-color: #2d70b9;
  width: 10em;

  transition: 0.2s;

  &:disabled {
    background: #aaa;
  }

  &:hover {
    color: #fff;
    font-weight: 600;
    opacity: 0.9;
  }
  @media screen and (max-width: 500px) {
    margin-top: 1em;
  }
`;

export const ContentWithButtonEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  input {
    margin: 0;
  }
`;

export const TableSeeStatus = styled.table`
  text-align: center;

  thead {
    tr {
      font-size: 1.2rem;
    }
  }

  tbody {
    tr {
      border: 1px solid;

      td {
        letter-spacing: 1px;
        padding: 0.4rem 1rem;
      }
    }
  }
`;

export const Bold = styled.strong`
  color: ${(props) => (props.color === 0 ? '#ff0000' : '#1565B4')};
`;

export const ModalContainer = styled(Modal)`
  width: 100%;
`;
