import axios from "axios";
import { configJson } from "../library/utils/firebaseUtils";

const api = axios.create({
  baseURL: configJson.API_HOST_V2,
});

export const getPowerBi = async (token) => {
  try {
    const response = await api.get(`/powerBI/embedInfo?configId=yWWkv8rF5dAYa0UxZYDX`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return axios.error;
  }
};
