import { GenericText, Content, InsideContent } from "../styled/generic";
import { useLocation } from "react-router-dom";
import "../css/Input.css";
import HeaderComp from "../components/Header/HeaderComp";
import SideBarDesktop from "../components/Sidebar/SideBarComp";
import {
  ProfileBox,
  InputHolder,
} from "../components/Tables/style/SellerListStyle";
import { BsPersonFill } from "react-icons/bs";
import {
  SmallPictureProfile,
  SmallPictureProfileBox,
} from "../styled/PictureProfiel";
import Footer from "../components/Footer/Footer";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BoxBalance } from "../pages/SellerPage/style/SellerPageStyle";
import AffiliatedListTable from "../components/Tables/AffiliatedListTable";

export default function AfiliatedList() {
  const location = useLocation();
  const [selected, setSelected] = useState(true);

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent theme={location.pathname} id="inside-content">
          <InputHolder>
            <Link
              style={{ textDecoration: "none", marginBottom: "1em" }}
              to={"/registro-afiliado"}
            >
              <ProfileBox>
                <SmallPictureProfileBox>
                  <SmallPictureProfile>
                    <BsPersonFill />
                  </SmallPictureProfile>
                </SmallPictureProfileBox>
                <GenericText>Adicionar novo Afiliado(a)</GenericText>
              </ProfileBox>
            </Link>
          </InputHolder>
          <BoxBalance>
            <label style={{ marginBottom: "1em" }}>
              Selecionar status:&nbsp;
              <select onChange={(e) => setSelected(!selected)}>
                <option value={true}>Afiliados ativos</option>
                <option value={false}>Afiliados inativos</option>
              </select>
            </label>
            <AffiliatedListTable selected={selected} />
          </BoxBalance>
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
