import axios from 'axios';
import { configJson } from '../../library/utils/firebaseUtils';
import { authToken } from './token';

export function uploadFiles(folderName, file, uId) {
  return new Promise((resolve, reject) => {
    authToken(uId).then((token) => {
      axios
        .post(
          configJson.API_HOST_V2 + '/storage/upload',
          {
            folderName, // PASTA NO STORAGE
            file, // PATH
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          // console.log("RESULTADO DO UPLOAD DE FOTO ==>", result);
          resolve(result);
        })
        .catch((error) => {
          // console.log("ERROR DO UPLOAD DE FOTO ==>", error);
          return reject(error);
        });
    });
  });
}
