import axios from "axios";
import { configJson } from "../library/utils/firebaseUtils";

export const api = axios.create({
  baseURL: configJson.API_HOST_V2,
});

export const getSellerData = async (id, token) => {
  try {
    const response = await api.get(
      `/brasilCap/getCheckSeller/?uid=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      {}
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const enableSeller = async (id, token) => {
  try {
    const response = await api.put(
      `/brasilCap/activeSellerUser/?uidSeller=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const disableSeller = async (id, token) => {
  try {
    const response = await api.put(
      `/brasilCap/disableSellerUser/?uidSeller=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getSupervisorData = async (id, token) => {
  try {
    const response = await api.get(
      `/brasilCap/getCheckSupervisor/?uidSupervisor=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      {}
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const enableSupervisor = async (id, token) => {
  try {
    const response = await api.put(
      `/brasilCap/activeSupervisor/?uidSupervisor=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const disableSupervisor = async (id, token) => {
  try {
    const response = await api.put(
      `/brasilCap/disableSupervisor/?uidSupervisor=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getTestimonialById = async (id, token) => {
  try {
    const response = await api.get(
      `/brasilCap/testimonials/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      {}
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const createTestimonial = async (token, data) => {
  try {
    const response = await api.post(
      `/brasilCap/testimonials`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateTestimonial = async (id, token, data) => {
  try {
    const response = await api.put(
      `/brasilCap/testimonials/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response;
  } catch (error) {
    return error.response;
  }
};