import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DistribuitionCartelaContext } from '../../../context/distribuitionCartela';
import { toast } from 'react-toastify';

// STYLES
import { Content, GenericText, InsideContent } from '../../../styled/generic';
import { CiBarcode } from 'react-icons/ci';
import { SmallPictureProfile, SmallPictureProfileBox } from '../../../styled/PictureProfiel';
import {
  BoxBalance,
  ContainerButtonsSeries,
  InputHolder,
  PdvBoxButton,
} from '../styles/DistribuitionCartelasStyle';

// COMPONENTS
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import SeriesTable from '../../../components/Tables/SeriesTable';
import { ModalEditSerie } from '../../../components/Modals/ModalEditSerie';

// APIS
import { getAllSeries } from '../../../services/APIs/getAllSeries';
import ModalValidationAndProcessSerie from '../../../components/Modals/ModalValidationAndProcessSerie';
import ModalFinishSerie from '../../../components/Modals/ModalFinishSerie';
import ModalSeeStatusSeries from '../../../components/Modals/ModalSeeStatusSeries';

export function Series() {
  const location = useLocation();
  const uId = JSON.parse(localStorage.getItem('uid'));
  const isUpdateTable = JSON.parse(localStorage.getItem('updateList'));
  const { setSerie, setResumeSerieStatus } = useContext(DistribuitionCartelaContext);
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [isOpenModalValidationAndProcess, setIsOpenModalValidationAndProcess] = useState(false);
  const [isOpenModalFinishSeries, setIsOpenModalFinishSeries] = useState(false);
  const [isOpenModalSeeStatusSeries, setIsOpenModalSeeStatusSeries] = useState(false);
  const [loadingTable, setLoadingTanle] = useState(false);
  const [seriesInfo, setSeriesInfo] = useState([]);
  const [dataSerieSelected, setDataSerieSelected] = useState({});
  const [selected, setSelected] = useState(true);
  const [statusSerie, setStatusSerie] = useState('');

  // BUSCA AS SÉRIES
  useEffect(() => {
    localStorage.removeItem('updateList');
    setLoadingTanle(true);
    getAllSeries(uId)
      .then((series) => setSeriesInfo(series))
      .catch((error) => {
        toast.warning(error);
        console.log('getAllSeries ERROR =>', error);
      })
      .finally(() => {
        setLoadingTanle(false);
      });
  }, [uId, isUpdateTable]);

  // ENVIA AS INFOS PARA O MODAL DE EDIÇÃO E VISUALIZAÇÃO
  const handleOpenModalEdit = (value, data) => {
    setIsOpenModalEdit(true);
    setSerie(value);
    setDataSerieSelected(data);
  };

  const handleCloseModalEdit = () => {
    setIsOpenModalEdit(false);
  };

  // ENVIA AS INFOS PARA O MODAL VALIDAÇÃO E PROCESSAMENTO
  const handleOpenModalValidationAndProcess = (value, status) => {
    setIsOpenModalValidationAndProcess(true);
    setStatusSerie(status);
    setSerie(value);
  };

  const handleCloseModalValidationAndProcess = () => {
    setIsOpenModalValidationAndProcess(false);
  };

  // ENVIA AS INFOS PARA O MODAL FINALIZAÇÃO DE SERIE E ETAPAS DE POSTERIORES
  const handleOpenModalFinishSeries = (type, value) => {
    switch (type) {
      case 'finishSerie':
        setIsOpenModalFinishSeries(true);
        setSerie(value);
        break;
      case 'seeStatusSerie':
        setIsOpenModalSeeStatusSeries(true);
        setResumeSerieStatus(value);
        break;
      default:
        break;
    }
  };

  const handleCloseModalFinishSeries = (type) => {
    switch (type) {
      case 'finishSerie':
        setIsOpenModalFinishSeries(false);
        break;
      case 'seeStatusSerie':
        setIsOpenModalSeeStatusSeries(false);
        break;
      default:
        break;
    }
  };

  const isActiveSerie = (serieEndDate) => {
    const date = new Date();
    const timeStamp = date.getTime();

    if (serieEndDate >= timeStamp) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent theme={location.pathname} id="inside-content">
          <ContainerButtonsSeries>
            <InputHolder id="inputHolder">
              <Link to={'/operacao-cartela/gerenciar-series/cadastro'}>
                <PdvBoxButton>
                  <SmallPictureProfileBox>
                    <SmallPictureProfile>
                      <CiBarcode />
                    </SmallPictureProfile>
                  </SmallPictureProfileBox>
                  <GenericText>Cadastrar série</GenericText>
                </PdvBoxButton>
              </Link>
            </InputHolder>
          </ContainerButtonsSeries>
          <BoxBalance>
            <label style={{ margin: '2rem 0 1rem 1rem' }}>
              Situação da série:&nbsp;
              <select onChange={() => setSelected(!selected)}>
                <option value={true}>Séries ativas</option>
                <option value={false}>Séries encerradas</option>
              </select>
            </label>
            <SeriesTable
              openModalSerieEdit={handleOpenModalEdit}
              openModalValidationAndProcess={handleOpenModalValidationAndProcess}
              openModalFinishSerie={handleOpenModalFinishSeries}
              seriesInfo={seriesInfo}
              loadingTable={loadingTable}
              isActiveSeries={isActiveSerie}
              selected={selected}
            />
            <ModalEditSerie
              isOpen={isOpenModalEdit}
              close={handleCloseModalEdit}
              dataSerieSelected={dataSerieSelected}
            />
            <ModalValidationAndProcessSerie
              isOpen={isOpenModalValidationAndProcess}
              close={handleCloseModalValidationAndProcess}
              statusSerie={statusSerie}
            />
            <ModalFinishSerie
              isOpen={isOpenModalFinishSeries}
              close={handleCloseModalFinishSeries}
            />
            <ModalSeeStatusSeries
              isOpen={isOpenModalSeeStatusSeries}
              close={handleCloseModalFinishSeries}
            />
          </BoxBalance>
        </InsideContent>
      </Content>
    </>
  );
}

