import React, { useState, useEffect, useContext } from 'react';
import {
  Header,
  HeaderProfile,
  HeaderTitleInside,
  ProfileData,
  LogoHeader,
  HeaderTitleOutside,
} from './style/HeaderStyle';
import { XSmallPictureProfileBox, XSmallPictureProfile } from '../../styled/PictureProfiel';
import { BsPersonFill } from 'react-icons/bs';
import Logo from '../../assets/images/LogoBlueBG.png';
import { useLocation } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';

export default function HeaderComp() {
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);
  const [pageName, setPageName] = useState('');
  const location = useLocation();
  const { id } = useParams();
  const name = JSON.parse(localStorage.getItem('name'));
  const role = JSON.parse(localStorage.getItem('role'));

  useEffect(() => {
    switch (location.pathname) {
      // GERAL
      case '/dashboard':
        return setPageName('Dashboard');
      case '/perfil':
        return setPageName('Meu Perfil');
      case `/vendedor/${id}`:
        return setPageName('Ficha do vendedor(a)');
      case `/supervisor/${id}`:
        return setPageName('Ficha do Supervisor(a)');
      case '/registro-vendedor':
        return setPageName('Cadastro de vendedor(a)');
      case '/registro-supervisor':
        return setPageName('Cadastro de supervisor(a)');
      case '/lista-vendedor':
        return setPageName('Lista dos vendedores');
      case '/lista-supervisor':
        return setPageName('Lista dos supervisores(a)');
      case '/lista-afiliados':
        return setPageName('Lista dos afiliados(a)');
      case '/registro-afiliado':
        return setPageName('Cadastro de afiliado(a)');
      case '/administracao':
        // SORTEIO
        return setPageName('Administração');
      case '/administracao/criar-sorteios':
        return setPageName('Criar sorteio');
      case '/administracao/lista-de-sorteios':
        return setPageName('Lista de sorteios');
      case `/administracao/lista-de-sorteios/${id}`:
        return setPageName('Editar sorteio');
      case `/administracao/depoimentos`:
        // DEPOIMENTO
        return setPageName('Lista de depoimentos');
      case `/administracao/depoimento/`:
        return setPageName('Criar / Editar depoimento');

      // CARTELAS
      case `/operacao-cartela`:
        return setPageName('Operação cartela física');

      // SERIES (CARTELAS)
      case `/operacao-cartela/gerenciar-series`:
        return setPageName('Gerenciar séries');
      case `/operacao-cartela/gerenciar-series/cadastro`:
        return setPageName('Cadastro de séries');
      case `/operacao-cartela/gerenciar-series/baixa/${serie === '' ? serieUrl : serie}`:
        return setPageName('Baixa de cartelas');
      case `/operacao-cartela/gerenciar-series/extravios/${serie === '' ? serieUrl : serie}`:
        return setPageName('Extravios');
      case `/operacao-cartela/gerenciar-series/lista-de-baixas/${serie === '' ? serieUrl : serie}`:
        return setPageName('Baixadas');
      case `/operacao-cartela/gerenciar-series/erros/${serie === '' ? serieUrl : serie}`:
        return setPageName('Emissões com erro');

      // LIQUIDAÇÃO
      case `/operacao-cartela/gerenciar-series/liquidacao/${serie === '' ? serieUrl : serie}`:
        return setPageName('Liquidação');

      // RESERVAR CARTELAS E LOTES
      case `/operacao-cartela/gerenciar-series/reservar-cartelas/${
        serie === '' ? serieUrl : serie
      }`:
        return setPageName('Reservar cartelas');
      case `/operacao-cartela/gerenciar-series/reservar-cartelas/tipo-reserva/${
        serie === '' ? serieUrl : serie
      }`:
        return setPageName('Tipo de reserva');
      case `/operacao-cartela/gerenciar-series/reservar-cartelas/tipo-reserva/fracionada/${
        serie === '' ? serieUrl : serie
      }`:
        return setPageName('Reserva fracionada');
      case `/operacao-cartela/gerenciar-series/reservar-cartelas/tipo-reserva/lote/${
        serie === '' ? serieUrl : serie
      }`:
        return setPageName('Reserva em lote');

      // CREDENCIADOS (CARTELAS)
      case `/operacao-cartela/gerenciar-credenciados/`:
        return setPageName('Gerenciar credenciados');
      case `/operacao-cartela/gerenciar-credenciados/cadastro`:
        return setPageName('Cadastro de credenciado');
      case `/operacao-cartela/gerenciar-credenciados/${id}`:
        return setPageName('Página do credenciado');
      case `/operacao-cartela/gerenciar-credenciados/${id}/vincular`:
        return setPageName('Vincular estabelecimento');

      // GERAL
      default:
        return role === 'manager'
          ? setPageName('Página do afiliado')
          : setPageName('Lista dos vendedores');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location]);

  return (
    <>
      <Header id="header">
        {window.screen.width <= 450 && (
          <div>
            <LogoHeader src={Logo} />
          </div>
        )}
        <HeaderTitleInside>
          <p>{pageName}</p>
        </HeaderTitleInside>
        <Link
          to="/perfil"
          style={{
            textDecoration: 'none',
            color: 'black',
          }}
        >
          <HeaderProfile>
            <XSmallPictureProfileBox className="box">
              <XSmallPictureProfile>
                <BsPersonFill />
              </XSmallPictureProfile>
            </XSmallPictureProfileBox>
            <ProfileData>
              <p
                style={{
                  fontSize: '0.8em',
                  fontWeight: 600,
                  marginBottom: 0,
                }}
              >
                {role === 'manager' ? 'Gerente' : 'Supervisor'}
              </p>
              <p>{name}</p>
            </ProfileData>
          </HeaderProfile>
        </Link>
      </Header>
      <HeaderTitleOutside>
        <p>{pageName}</p>
      </HeaderTitleOutside>
    </>
  );
}
