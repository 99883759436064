// STYLES
import { ButtonDistribuitionTable } from '../Tables/style/EditButtomTableStyles';
import { LuClipboardList } from 'react-icons/lu';
import Tooltip from '@mui/material/Tooltip';

// OTHERS
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { convertVisualDateForTimestamp } from '../../services/helpers';

export const DeactivatedCardListButton = ({ serie, stopSellingDate, seriesFinished, status }) => {
  const { setSerie } = useContext(DistribuitionCartelaContext);
  const statusReleasesAction = [
    'registered',
    'validation',
    'validationSuccess',
    'validationError',
    'processing',
    'processedError',
  ];

  return !statusReleasesAction.includes(status) &&
    seriesFinished(convertVisualDateForTimestamp(stopSellingDate)) ? (
    <Tooltip title="Cartelas Baixadas" placement="top">
      <Link to={`/operacao-cartela/gerenciar-series/lista-de-baixas/${serie}`}>
        <ButtonDistribuitionTable onClick={() => setSerie(serie)}>
          <LuClipboardList size={20} style={{ color: '#54BBFE' }} />
        </ButtonDistribuitionTable>
      </Link>
    </Tooltip>
  ) : null;
};

