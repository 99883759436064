import React, { useEffect, useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { StyledDiv } from "../Tables/style/MUIDatatable";
import { getAllTestimonials } from "../../services/getAll";
import { authToken } from "../../services/APIs/token";
import { BottomMargin } from "../../pages/SellerPage/style/SellerPageStyle";
import { createTheme, ThemeProvider, Typography } from "@mui/material";

export default function TestimonyTable({ selected }) {
  const [id, setId] = useState("");
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authToken().then((token) => {
      getAllTestimonials(token)
        .then((r) => {
          if (!r.success) {
            setError(r.error);
          } else {
            setData(r.result);
          }
        })
        .catch((e) => {
          setError(true);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (id !== "") {
      localStorage.setItem(
        "id",
        id
      );
      navigate(`/administracao/depoimento/?id=${id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (error)
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="body2" color="secondary">
          Erro: {error}
        </Typography>
      </div>
    );
  if (data?.length === 0)
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        "Carregando..."
      </div>
    );

  const displayData = data.filter((el) => el.status === selected);

  const columns = [
    {
      name: "id",
      label: "ID"
    },
    {
      name: "title",
      label: "Nome do Ganhador",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: "subTitle",
      label: "Data do sorteio e tipo de prêmio",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          if (!value) {
            return (
              <span>
                <BsCircleFill style={{ color: "#8A8F91" }} />
                &nbsp;Inativo
              </span>
            );
          } else {
            return (
              <span>
                <BsCircleFill style={{ color: "#2DB94D" }} />
                &nbsp;Ativo
              </span>
            );
          }
        },
      },
    },

  ];

  const tableOptions = {
    onRowClick: (rowData) => {
      setId(rowData[0]);
    },
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: "simple",
    filterType: "textField",
    textLabels: {
      body: {
        noMatch: "",
        toolTip: `${"table.toolTip"}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${"table.viewColumnsTitle"}`,
      },
    },
  };
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
            },
          },
        },
      },
    });

  return (
    <BottomMargin>
      <StyledDiv style={{ cursor: "pointer" }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            className={"table is-mobile"}
            data={displayData}
            columns={columns}
            options={tableOptions}
          />
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}
