// STYLES
import { ButtonDistribuitionTable } from '../Tables/style/EditButtomTableStyles';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import Tooltip from '@mui/material/Tooltip';

// OTHERS
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';
import { Link } from 'react-router-dom';
import { useContext } from 'react';

export const SaleOff = ({ serie, status }) => {
  const { setSerie } = useContext(DistribuitionCartelaContext);
  const statusReleasesAction = [
    'registered',
    'validation',
    'validationSuccess',
    'validationError',
    'processing',
    'processedError',
  ];

  return (
    !statusReleasesAction.includes(status) && (
      <Tooltip title="Liquidação" placement="top">
        <Link to={`/operacao-cartela/gerenciar-series/liquidacao/${serie}`}>
          <ButtonDistribuitionTable onClick={() => setSerie(serie)}>
            <RiMoneyDollarBoxLine size={24} style={{ color: '#558B2F' }} />
          </ButtonDistribuitionTable>
        </Link>
      </Tooltip>
    )
  );
};

