import { Box, TextField } from "@mui/material";
import styled from "styled-components";

export const GeneralForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 1.6rem 0.8rem;
  margin-left: 2rem;
`;

export const GeneralFormBanners = styled(GeneralForm)`
  flex-direction: row;
`;

export const ContainerBasicInfos = styled.div`
  border: 0.5rem solid rgba(45, 112, 185, 0.9);
  border-radius: 1.5rem;
  box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  height: auto;
  justify-content: center;
  padding: 2rem;
  width: 80%;
  max-width: 50rem;
`;

export const ContainerUploadImage = styled.div.attrs((props) => ({
  heightContainer: props.heightContainer === "" ? "auto" : "22rem",
}))`
  background: rgba(45, 112, 185, 0.9);
  border-radius: 1.5rem;
  box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  height: ${(props) => props.heightContainer};
  justify-content: center;
  padding: 2rem;
  width: 80%;
  max-width: 50rem;
`;

export const BoxForm = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 1rem;
`;

export const InputForm = styled(TextField)`
  background: #fff;
  border-radius: 0.5rem;
`;

export const FormGroupDates = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  gap: 1rem;
`;

export const FormGroupUploads = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const ButtonUploadImage = styled.button`
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.3)) top/100% 800%;
  background-color: #edb622;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-weight: 600;
  padding: 0.4rem;
  transition: 0.3s ease-in-out;
  width: 14rem;

  &:hover {
    background-position: bottom;
  }

  &:disabled {
    background-color: gray;
    cursor: default;
  }

  @media (min-width: 900px) {
    min-width: 5%;
    padding: 0.5rem 1.8rem;
  }
`;

export const PreviewImageMainThumb = styled.img.attrs((props) => ({
  alt: props.alt,
}))`
  background: #fff;
  width: 100%;
  height: auto;
  max-height: 14rem;
`;

export const PreviewImageMobile = styled.img.attrs((props) => ({
  alt: props.alt,
}))`
  background: #fff;
  width: auto;
  height: 13rem;
`;

export const PreviewImageBack = styled.img.attrs((props) => ({
  alt: props.alt,
}))`
  background: #fff;
  width: auto;
  height: 13rem;
`;

export const ButtonSave = styled(ButtonUploadImage)`
  background: rgba(45, 112, 185, 0.9);
  margin-top: 2rem;
  min-width: 12rem;
  width: auto;

  &:hover {
    filter: opacity(0.9);
  }
`;
