import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DistribuitionCartelaContext } from '../../../context/distribuitionCartela';

// APIS
import { verifyBeforeLoss } from '../../../services/APIs/verifyBeforeLoss';
import { StepVerifyQuantityCarterlas } from './steps/StepVerifyQuantityCarterlas';
import { StepAlertStopSellingDate } from './steps/StepAlertStopSellingDate';

export function CartelaNotActivy({ setFinishedSerieIsEnable }) {
  const navigate = useNavigate();
  const uId = JSON.parse(localStorage.getItem('uid'));
  const { serie, serieUrl, stopSellingDate } = useContext(DistribuitionCartelaContext);

  const [loadingApiValueCartelas, setLoadingApiValueCartelas] = useState(false);
  const [quantityCartela, setQuantityCartela] = useState(0);

  const [step] = useState(() => {
    const date = new Date();
    const dateNow = date.getTime();

    return dateNow > stopSellingDate ? 0 : 1;
  });

  // VERIFICAR SE AINDA EXISTE CARTELA NÃO DISTRIBUÍDA
  useEffect(() => {
    if (serie ? serie : serieUrl !== '') {
      setLoadingApiValueCartelas(true);
      verifyBeforeLoss(uId, serie)
        .then((res) => {
          /* res.hasOwnProperty('count')
            ? setFinishedSerieIsEnable(false)
            : setFinishedSerieIsEnable(true); */

          res.hasOwnProperty('count') && setQuantityCartela(res.count);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingApiValueCartelas(false);
        });
    }
  }, [serie, serieUrl, setFinishedSerieIsEnable, uId]);

  return step === 0 ? (
    <StepVerifyQuantityCarterlas
      loadingApiValueCartelas={loadingApiValueCartelas}
      quantityCartela={quantityCartela}
      navigate={navigate}
      setFinishedSerieIsEnable={setFinishedSerieIsEnable}
    />
  ) : (
    <StepAlertStopSellingDate
      navigate={navigate}
      setFinishedSerieIsEnable={setFinishedSerieIsEnable}
    />
  );
}
