import { GenericText, Content, InsideContent } from '../../styled/generic';
import { BsPersonFill } from 'react-icons/bs';
import HeaderComp from '../../components/Header/HeaderComp';
import SideBarDesktop from '../../components/Sidebar/SideBarComp';
import { ProfileBox, GenericHeader, TextBox } from './style/SellerNSuperStyle';
import { useLocation } from 'react-router-dom';
import {
  MediumPictureProfile,
  MediumPictureProfileBox,
} from '../../styled/PictureProfiel';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import React from 'react';

export default function SellerNSupervisor() {
  const location = useLocation();
  const role = JSON.parse(localStorage.getItem('role'));

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content" theme={location.pathname}>
          <Link
            to={'/lista-supervisor'}
            style={{
              textDecoration: 'none',
              display: role !== 'manager' ? 'none' : '',
            }}
          >
            <ProfileBox>
              <MediumPictureProfileBox>
                <MediumPictureProfile>
                  <BsPersonFill />
                </MediumPictureProfile>
              </MediumPictureProfileBox>
              <TextBox>
                <GenericHeader>Lista dos supervisores</GenericHeader>
                <GenericText>
                  Você gerente pode adicionar um novo supervisor. Desativar ou
                  ativar
                </GenericText>
              </TextBox>
            </ProfileBox>
          </Link>
          <Link
            to={'/lista-vendedor'}
            style={{
              textDecoration: 'none',
            }}
          >
            <ProfileBox>
              <MediumPictureProfileBox>
                <MediumPictureProfile>
                  <BsPersonFill />
                </MediumPictureProfile>
              </MediumPictureProfileBox>
              <TextBox>
                <GenericHeader>Lista dos vendedores</GenericHeader>
                <GenericText>
                  Você gerente pode adicionar um novo Vendedor. Desativar ou
                  ativar
                </GenericText>
              </TextBox>
            </ProfileBox>
          </Link>
          <Link
            to={'/lista-afiliados'}
            style={{
              textDecoration: 'none',
            }}
          >
            <ProfileBox>
              <MediumPictureProfileBox>
                <MediumPictureProfile>
                  <BsPersonFill />
                </MediumPictureProfile>
              </MediumPictureProfileBox>
              <TextBox>
                <GenericHeader>Lista de afiliados</GenericHeader>
                <GenericText>
                  Você pode adicionar novos afiliados. Desativar ou ativar
                </GenericText>
              </TextBox>
            </ProfileBox>
          </Link>
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
