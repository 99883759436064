import styled from 'styled-components';

export const ProfileBox = styled.div`
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #2d70b9;
  width: 40%;
  height: 65vh;
  min-width: 30em;
  border-radius: 15px;
  flex-wrap: wrap;
  //margin-top: 1.5em;
  @media screen and (max-width: 450px) {
    margin-left: 10%;
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2d70b9;
    width: 90vw;
    height: 40vh;
    max-width: 90vw;
    min-width: 0;
    border-radius: 15px;
  }
`;

export const PictureNData = styled.div`
  width: 90%;
  display: flex;
  //justify-content: center;
  align-items: center;
`;

export const ProfileData = styled.div`
  font-size: 2em;
  color: white;
  margin-left: 0.5em;
  @media screen and (max-width: 450px) {
    font-size: 0.8em;
    color: white;
  }
`;

export const UserData = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  border-radius: 15px;
  min-width: 100%;
  height: 50%;
  padding-left: 5%;
  padding-right: 5%;
  justify-content: space-around;
  align-items: flex-start;
  @media screen and (max-width: 450px) {
    margin-top: 1%;
    display: flex;
    width: 100%;
    height: 60%;
    padding: 5%;
    padding-top: 5%;
    align-items: flex-start;
    justify-content: space-around;
    background-color: #2d70b9;
  }
`;

export const UserDataText = styled.span`
  color: gray;
  font-size: 0.8em;
  margin-bottom: -5%;
  @media screen and (max-width: 450px) {
    display: flex;
    text-align: left;
    padding-bottom: 5%;
    color: white;
    font-size: 0.7em;
  }
`;

export const NewProfileBox = styled(ProfileBox)`
  @media screen and (max-width: 500px) {
    min-width: 0 !important;
    width: 20rem;
  }
`;
