import React from 'react';

//STYLES
import { ModalHeader, DivModal, HeaderText } from './style/ModalStyle';

export function ModalLowsLog({ close, open, log }) {
  return (
    <div className={`modal ${open ? 'modal is-active' : 'modal'}`}>
      <div onClick={close} className="modal-background"></div>
      <DivModal style={{ minHeight: `${log.length > 1 ? '80%' : ''}` }} className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">Listagem de baixas</HeaderText>
        </ModalHeader>
        <div className="modal-card-body">
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <table>
              <thead>
                <tr style={{ fontSize: '1.2rem', textAlign: 'center' }}>
                  <th>Cartelas</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {log.map((low, index) => (
                  <tr
                    key={low.cardId}
                    style={{
                      border: '1px solid',
                    }}
                  >
                    <td
                      style={{
                        letterSpacing: '1px',
                        padding: '0.4rem 1rem',
                      }}
                    >
                      <strong>{low.cardId}</strong>
                    </td>
                    <td
                      style={{
                        fontSize: '0.8rem',
                        letterSpacing: '1px',
                        padding: '0.4rem 1rem',
                      }}
                    >
                      <strong>
                        {low.status === 'disablePending' ? (
                          <span style={{ color: '#ffa500' }}>Baixa pendente</span>
                        ) : low.status === 'disabledError' ? (
                          <span style={{ color: '#EB1E0A' }}>Baixa com erro</span>
                        ) : low.status === 'disableConfirmed' ? (
                          <span style={{ color: '#2DB94D' }}>Baixa confirmada</span>
                        ) : null}
                      </strong>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </span>
        </div>
      </DivModal>
    </div>
  );
}

