import React, { createContext, useState } from "react";

export const RegisterContext = createContext();

export const RegisterProvider = ({ children }) => {
  const [closeModal, setCloseModal] = useState(false);

  const [user, setUser] = useState({
    name: "",
    sup: "",
    phone: "",
    mail: "",
    doc: "",
    id: "",
  });

  const handleModal = () => {
    setCloseModal(!closeModal);
  };

  return (
    <RegisterContext.Provider
      value={{
        handleModal,
        closeModal,
        user,
        setUser,
      }}>
      {children}
    </RegisterContext.Provider>
  );
};
