import { Content, InsideContent } from "../../styled/generic";
import HeaderComp from "../../components/Header/HeaderComp";
import SideBarDesktop from "../../components/Sidebar/SideBarComp";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import PowerBI from "../../components/PowerBI";
import React from 'react';

export default function DashBoard() {
  const location = useLocation();
  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent theme={location.pathname} id="inside-content">
          <PowerBI />
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
