import { ButtonContainer } from '../Sidebar/style/SideBar';
import Statistic from '../../assets/images/Grupo 11316.svg';
import Card from '../../assets/images/Grupo 11320.svg';
import { useLocation, Link } from 'react-router-dom';
import { FooterStyle } from './style/FooterStyle';
import React, { useContext } from 'react';
import { AuthContext } from '../../context/auth';
import { MdLogout } from 'react-icons/md';

export default function Footer() {
  const { logout } = useContext(AuthContext);
  const location = useLocation();
  return (
    <>
      <FooterStyle id="footer">
        <ButtonContainer>
          <Link to="/dashboard">
            <span
              aria-label="dash"
              className={
                location.pathname === '/dashboard' ? 'buttonMargin' : ''
              }
            >
              <img src={Statistic} width="30px" alt="imagem gráfico" />
            </span>
          </Link>
          <Link
            style={{
              fontDecoration: 'none',
            }}
            to="/lista-supervisor-vendedor"
          >
            <span
              aria-label="dash"
              className={
                location.pathname === '/lista-supervisor-vendedor'
                  ? 'buttonMargin'
                  : ''
              }
            >
              <img src={Card} width="50px" alt="imagem card" />
            </span>
          </Link>
          <span
            style={{
              fontSize: window.screen.width > '450px' ? '3em' : '1.8em',
            }}
            onClick={logout}
          >
            <MdLogout width="30px" />
          </span>
        </ButtonContainer>
      </FooterStyle>
    </>
  );
}
