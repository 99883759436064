import axios from "axios";
import { configJson } from "../library/utils/firebaseUtils";

export const api = axios.create({
  baseURL: configJson.API_HOST_V2,
});

export const getSalesData = async (uid, startTime, endTime, token) => {
  try {
    const response = await api.get(
      `brasilCap/getLeadsPeriods/?uid=${uid}&startTime=${startTime}&endTime=${endTime}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getOpenLeads = async (uid, token) => {
  try {
    const response = await api.get(`/brasilCap/getOpenLeads?uid=${uid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getValidCode = async (leadId, token) => {
  try {
    const response = await api.get(`/brasilCap/getValidCode?leadId=${leadId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
