// STYLES
import { ButtonDistribuitionTable } from '../Tables/style/EditButtomTableStyles';
import Tooltip from '@mui/material/Tooltip';
import { FaEdit, FaRegEye } from 'react-icons/fa';

// OTHERS
import { convertVisualDateForTimestamp } from '../../services/helpers';

export const ViewAndEditSerieButton = ({
  infosSerie,
  openModal,
  serie,
  serieEndDate,
  seriesFinished,
  status,
}) => {
  return status !== 'registered' ? (
    <Tooltip title="Visualizar série" placement="top">
      <ButtonDistribuitionTable onClick={() => openModal(serie, infosSerie)}>
        <FaRegEye size={20} style={{ color: '#EDB622' }} />
      </ButtonDistribuitionTable>
    </Tooltip>
  ) : status === 'processedSuccess' &&
    seriesFinished(convertVisualDateForTimestamp(serieEndDate)) ? null : (
    <Tooltip title="Editar série" placement="top">
      <ButtonDistribuitionTable onClick={() => openModal(serie, infosSerie)}>
        <FaEdit size={20} style={{ color: '#E74C3C' }} />
      </ButtonDistribuitionTable>
    </Tooltip>
  );
};
