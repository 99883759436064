import { useContext /* useState */ } from 'react';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';
import { Link } from 'react-router-dom';

// STYLES
import { BsEnvelopeArrowDown } from 'react-icons/bs';
import { ButtonDistribuitionTable } from '../Tables/style/EditButtomTableStyles';
import Tooltip from '@mui/material/Tooltip';

export const DeactivatedCardButton = ({
  serie,
  serieStartDate,
  serieEndDate,
  status,
  stopSellingDate,
}) => {
  const { setSerie, setStopSellingDate } = useContext(DistribuitionCartelaContext);
  // const convertStartDate = new Date(serieStartDate);
  const convertEndDate = new Date(serieEndDate);
  // const currentDate = new Date();

  // Subtrai um dia da data de término da série
  const endDateMinusOneDay = new Date(convertEndDate);
  endDateMinusOneDay.setDate(convertEndDate.getDate() - 1);

  /*  const [effectiveDate] = useState(() => {
    if (currentDate >= convertStartDate && currentDate <= endDateMinusOneDay) {
      return true;
    } else {
      return false;
    }
  }); */

  const setInfosInContext = () => {
    setSerie(serie);
    setStopSellingDate(stopSellingDate);
  };

  return status === 'processedSuccess' /* && effectiveDate */ ? (
    <Tooltip title="Dar baixa" placement="top">
      <Link to={`/operacao-cartela/gerenciar-series/baixa/${serie}`}>
        <ButtonDistribuitionTable onClick={setInfosInContext}>
          <BsEnvelopeArrowDown size={20} style={{ color: '#2D70B9' }} />
        </ButtonDistribuitionTable>
      </Link>
    </Tooltip>
  ) : null;
};
