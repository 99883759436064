import styled from 'styled-components';

export const ContainerAdministration = styled.div`
  display: flex;
  gap: 1rem;
  /* justify-content: flex-start;
  align-items: flex-start; */
`;

export const ButtonAdministration = styled.button`
  background-color: #2d70b9;
  border: 0;
  border-radius: 0.8rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  height: 3rem;
  padding: 0 1rem;
  width: 16rem;

  transition: 0.3s;

  :hover {
    filter: brightness(90%);
  }

  a {
    text-decoration: none;
    color: #fff;
  }
`;
