import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// STYLES
import { Content, InsideContent } from '../../../styled/generic';
import Logo from '../../../assets/images/LogoSquareV10.png';
import {
  BlueBox,
  LogoBlueBox,
  LabelBlueBox,
  Form,
  DivInputBlueBox,
} from '../../../styled/RegisterStyle';
import {
  WhiteBox,
  Input,
  Button,
  Label,
  ContainerSwitch,
  ContainerAddress,
  TextArea,
} from '../styles/DistribuitionCartelasStyle';
import { Oval } from 'react-loading-icons';
import { OnSwitch } from '../../../components/UpdateSellerSupervisor/style/SelectOnSellerPage';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

// COMPONENTS
import Footer from '../../../components/Footer/Footer';
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import ModalRegister from '../../../components/Modals/ModalRegister';
import { SwitchButton } from '../../../components/UpdateSellerSupervisor/UpdateSellerSupervisor';

// APIS
import { getUserBrasilCap } from '../../../services/getUsersBrasilCap';
import { registerAccredited } from '../../../services/APIs/registerAccredited';
import { verifyCep } from '../../../services/APIs/viaCep';

export function RegisterAccredited() {
  const navigate = useNavigate();
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cellphone, setCellphone] = useState('');
  const [documentType, setDocumentType] = useState(null);
  const [user, setUser] = useState({
    uIdOwner: '',
    pdvName: '',
    description: '',
    commission: '',
    document: '',
    address: '',
    status: true,
  });
  const [address, setAddress] = useState({
    cep: '',
    street: '',
    streetNumber: '',
    city: '',
    state: '',
    neighborhood: '',
    extra: '',
    landmark: '',
  });

  // BUSCA O USUARIO NO PARTNER DO SS
  useEffect(() => {
    if (cellphone.length === 11) {
      setIsLoading(true);

      getUserBrasilCap(cellphone)
        .then((info) => {
          setIsLoading(false);

          setUser((prevUser) => ({ ...prevUser, uIdOwner: info.uId }));
          toast.success('Usuário encontrado com sucesso. Preencha os dados a seguir!');
        })
        .catch((error) => {
          toast.error('Usuário não encontrado');
          console.log(error);

          setIsLoading(false);
        });
    }
  }, [cellphone]);

  // BUSCA O ENDEREÇO PELO CEP
  useEffect(() => {
    if (address.cep.length === 8) {
      setLoading(true);
      verifyCep(address.cep)
        .then((res) => {
          setAddress((prevAddress) => ({
            ...prevAddress,
            street: res.logradouro,
            city: res.localidade,
            state: res.uf,
            neighborhood: res.bairro,
          }));
        })
        .catch((error) => {
          console.log('CEP Não encontrado.', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [address.cep]);

  const handleCellphone = (e) => {
    const sanitizeCellphone = e.target.value.replace(/[-\s]/g, '');

    setCellphone(sanitizeCellphone);
  };

  const treatmentCommission = (e) => {
    const inputValue = e.target.value;
    const regexNumber = /^[0-9]+(\.[0-9]{0,2})?$/; // Verifica se o input é um número com no máximo duas casas decimais;

    if (regexNumber.test(inputValue) || inputValue === '') {
      setUser({ ...user, commission: inputValue });
    }
  };

  const treatmentState = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/g, '');

    setAddress({
      ...address,
      state: sanitizedValue.toUpperCase(),
    });
  };

  const treatmentDocument = (e) => {
    const sanitizeDocument = e.target.value.replace(/[./-]/g, '');

    setUser({ ...user, document: sanitizeDocument });
  };

  const treatmentCep = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

    setAddress({
      ...address,
      cep: sanitizedValue,
    });
  };

  const selectDocumentType = (e) => {
    const document = e.target.value;

    setDocumentType(document);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingRegister(true);
    if (
      documentType === null ||
      user.pdvName === '' ||
      user.document === '' ||
      user.description === '' ||
      user.commission === '' ||
      address.cep === '' ||
      address.street === '' ||
      address.streetNumber === '' ||
      address.city === '' ||
      address.state === '' ||
      address.neighborhood === ''
    ) {
      toast.error('Preencha todas as informações para prosseguir.');
      setIsLoadingRegister(false);
    } else {
      try {
        const payload = {
          name: user.pdvName,
          description: user.description,
          commission: user.commission,
          cnpj: user.document,
          status: user.status,
          addressList: [
            {
              city: address.city,
              streetAve: address.street,
              number: address.streetNumber,
              neighborhood: address.neighborhood,
              state: address.state,
              zipCode: address.cep,
              extra: address.extra,
              landmark: address.landmark,
            },
          ],
          accreditedId: user.uIdOwner,
        };

        const result = await registerAccredited(uId, payload);
        if (result.success) {
          setIsLoadingRegister(false);
          setCellphone('');
          setUser({
            uIdOwner: '',
            pdvName: '',
            document: '',
            description: '',
            commission: 0,
            address: '',
            status: true,
          });
          toast.success('Credenciado registrado com sucesso.');
          navigate('/operacao-cartela/gerenciar-credenciados');
        } else {
          toast.error('Algo de errado ocorreu, tente novamente mais tarde.');
          setIsLoadingRegister(false);
        }
      } catch (e) {
        toast.error(
          e === 'Ponto de venda já cadastrado!'
            ? e
            : 'Algo de errado ocorreu, tente novamente mais tarde.'
        );
        setIsLoadingRegister(false);
      }
    }
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <Form onSubmit={handleSubmit}>
            <BlueBox>
              <LogoBlueBox src={Logo} />
              <DivInputBlueBox>
                <LabelBlueBox className="label" htmlFor="tel">
                  Cadastro de novo credenciado
                </LabelBlueBox>
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Oval stroke="white" />
                  </div>
                ) : (
                  <>
                    <Label color>Telefone*:</Label>
                    <ReactInputMask
                      mask="99 99999-9999"
                      maskChar=""
                      alwaysShowMask={false}
                      id="tel"
                      name="tel"
                      type="tel"
                      placeholder="00 00000-0000"
                      className="input is-fullwidth is-small"
                      onChange={handleCellphone}
                      value={cellphone}
                    />
                  </>
                )}
              </DivInputBlueBox>
            </BlueBox>
            <WhiteBox className="box">
              <Label>Documento*:</Label>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="cpf"
                  control={<Radio size="small" />}
                  label="CPF"
                  onChange={selectDocumentType}
                />
                <FormControlLabel
                  value="cnpj"
                  control={<Radio size="small" />}
                  label="CNPJ"
                  onChange={selectDocumentType}
                />
              </RadioGroup>

              <Label>
                {documentType === null ? 'CPF/CNPJ*:' : documentType === 'cpf' ? 'CPF*:' : 'CNPJ*:'}
              </Label>
              <ReactInputMask
                mask={documentType === 'cpf' ? '999.999.999-99' : '99.999.999/9999-99'}
                maskChar=""
                alwaysShowMask={false}
                disabled={user.uIdOwner === '' || documentType === null}
                type="tel"
                placeholder={
                  documentType === null
                    ? 'Selecione um documento'
                    : documentType === 'cpf'
                    ? '000.000.000-00'
                    : '00.000.000/0000-00'
                }
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={treatmentDocument}
                value={user.document}
              />
              <Label>Nome do credenciado*:</Label>
              <Input
                disabled={user.uIdOwner === ''}
                type="text"
                placeholder="Triibo Tecnologia"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) => setUser({ ...user, pdvName: e.target.value })}
                value={user.pdvName}
              />
              <Label>Descrição*:</Label>
              <TextArea
                disabled={user.uIdOwner === ''}
                placeholder="Plataforma de engajamento baseado em comunidade."
                aria-multiline
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) => setUser({ ...user, description: e.target.value })}
                value={user.description}
              />
              <Label>Comissão*:</Label>
              <Input
                disabled={user.uIdOwner === ''}
                type="text"
                placeholder="12%"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={treatmentCommission}
                value={user.commission}
              />
              <ContainerAddress>
                <Label>Cep*:</Label>
                <Input
                  disabled={user.uIdOwner === ''}
                  type="text"
                  placeholder="00000000"
                  className={
                    window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                  }
                  required
                  onChange={treatmentCep}
                  maxLength={8}
                  value={address.cep}
                />
                <div>
                  <span>
                    <Label>Rua*:</Label>
                    <Input
                      disabled={user.uIdOwner === ''}
                      type="text"
                      placeholder="Rua guapiaçu"
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      required
                      onChange={(e) => setAddress({ ...address, street: e.target.value })}
                      value={address.street}
                    />
                  </span>
                  <span style={{ width: '30%' }}>
                    <Label>Número*:</Label>
                    <Input
                      disabled={user.uIdOwner === ''}
                      type="tel"
                      placeholder="0000"
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      required
                      onChange={(e) =>
                        setAddress({
                          ...address,
                          streetNumber: e.target.value,
                        })
                      }
                      value={address.streetNumber}
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <Label>Cidade*:</Label>
                    <Input
                      disabled={user.uIdOwner === ''}
                      type="text"
                      placeholder="São Paulo"
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      required
                      onChange={(e) => setAddress({ ...address, city: e.target.value })}
                      value={address.city}
                    />
                  </span>
                  <span style={{ width: '30%' }}>
                    <Label>Estado*:</Label>
                    <Input
                      disabled={user.uIdOwner === ''}
                      type="text"
                      placeholder="SP"
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      required
                      onChange={treatmentState}
                      maxLength={2}
                      value={address.state}
                    />
                  </span>
                </div>
                <div>
                  <span>
                    <Label>Bairro*:</Label>
                    <Input
                      disabled={user.uIdOwner === ''}
                      type="text"
                      placeholder="Centro"
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      required
                      onChange={(e) => setAddress({ ...address, neighborhood: e.target.value })}
                      value={address.neighborhood}
                    />
                  </span>
                  <span>
                    <Label>Complemento:</Label>
                    <Input
                      disabled={user.uIdOwner === ''}
                      type="text"
                      placeholder="Torre, apto, casa, etc..."
                      className={
                        window.screen.width > 450
                          ? 'input is-fullwidth'
                          : 'input is-fullwidth is-small'
                      }
                      onChange={(e) => setAddress({ ...address, extra: e.target.value })}
                      value={address.extra}
                    />
                  </span>
                </div>
              </ContainerAddress>
              <span>
                <Label>Ponto de referência:</Label>
                <Input
                  disabled={user.uIdOwner === ''}
                  type="text"
                  placeholder="Próximo à triibo"
                  className={
                    window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                  }
                  onChange={(e) => setAddress({ ...address, landmark: e.target.value })}
                  value={address.landmark}
                />
              </span>
              <ContainerSwitch>
                <OnSwitch>Inativo</OnSwitch>
                <SwitchButton
                  checked={user.status}
                  disabled={user.uIdOwner === ''}
                  onChange={(e) => setUser({ ...user, status: e.target.checked })}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <OnSwitch>Ativo</OnSwitch>
              </ContainerSwitch>
            </WhiteBox>
            <div className={`modal ${isLoadingRegister || loading ? 'modal is-active' : 'modal'}`}>
              <div
                className="modal-background"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Oval stroke="#EDB622" />
              </div>
            </div>
            <Button
              type="submit"
              className={window.screen.width > 450 ? 'button' : 'button is-small'}
            >
              Salvar
            </Button>
          </Form>
          <ModalRegister />
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}

