//STYLES
import { Content, InsideContent } from '../../styled/generic';
import { BoxBalance, ContainerButton } from './style/ListOfRaffles';

//COMPONENTS
import HeaderComp from '../../components/Header/HeaderComp';
import SideBarDesktop from '../../components/Sidebar/SideBarComp';
import { Link } from 'react-router-dom';
import { ButtonAdministration } from '../Administration/style/Adminstration';
import RafflesTable from '../../components/Tables/RafflesTable';

export function ListOfRaffles() {
  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <ContainerButton>
            <ButtonAdministration>
              <Link to="/administracao/criar-sorteios">Criar sorteio</Link>
            </ButtonAdministration>
          </ContainerButton>
          <BoxBalance>
            <RafflesTable />
          </BoxBalance>
        </InsideContent>
      </Content>
    </>
  );
}
