import { createContext, useEffect, useState } from "react";
import { getWinners } from "../services/APIs/getWinners";
import { convertToFullDate } from "../services/helpers";

export const CampaingnContext = createContext();

export const CampaignProvider = ({ children }) => {
  const uId = JSON.parse(localStorage.getItem("uid"));
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    (async function getRaffles() {
      const data = await getWinners(uId);

      data.map((campaign) => {
        campaign.startDateEdit = campaign.startDate;
        campaign.drawDateEdit = campaign.drawDate;
        campaign.endDateEdit = campaign.endDate;
        campaign.startDate = convertToFullDate(campaign.startDate);
        campaign.drawDate = convertToFullDate(campaign.drawDate);
        campaign.endDate = convertToFullDate(campaign.endDate);
        campaign.urlRedirect = `/administracao/lista-de-sorteios/${campaign.id}`;
        return data;
      });
      setCampaigns(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CampaingnContext.Provider value={{ campaigns }}>
      {children}
    </CampaingnContext.Provider>
  );
};
