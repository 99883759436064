// STYLES
import { ContainerRow, ContentBody } from '../../styles/DistribuitionCartelasStyle';
import { CircularProgress } from '@mui/material';
import { SendCartelaButton } from '../../../../components/Modals/style/ModalStyle';

export const StepVerifyQuantityCarterlas = ({
  loadingApiValueCartelas,
  quantityCartela,
  navigate,
  setFinishedSerieIsEnable,
}) => {
  return loadingApiValueCartelas ? (
    <ContainerRow>
      <CircularProgress size={25} thickness={6} />
    </ContainerRow>
  ) : quantityCartela === 1 ? (
    <ContentBody>
      <h4>Atenção:</h4>
      <h5>
        Ainda existe <strong style={{ color: '#2D70B9' }}>{quantityCartela}</strong> cartela não
        distribuída.
      </h5>
      <h5>Tem certeza que deseja prosseguir com a baixa das cartelas?</h5>
      <>
        <SendCartelaButton
          onClick={() => setFinishedSerieIsEnable(true)}
          variant="contained"
          $spacingTop="1rem"
        >
          Prosseguir
        </SendCartelaButton>
        <SendCartelaButton
          onClick={() => navigate(-1)}
          variant="contained"
          $background="#E5AA1A"
          $spacingTop="0.5rem"
        >
          Cancelar
        </SendCartelaButton>
      </>
    </ContentBody>
  ) : (
    <ContentBody>
      <h4>Atenção:</h4>

      <h5>
        Ainda existem <strong style={{ color: '#2D70B9' }}>{quantityCartela}</strong> cartelas não
        distribuídas.
      </h5>
      <h5>Tem certeza que deseja prosseguir com a baixa das cartelas ?</h5>
      <>
        <SendCartelaButton
          onClick={() => setFinishedSerieIsEnable(true)}
          variant="contained"
          $spacingTop="1rem"
        >
          Prosseguir
        </SendCartelaButton>
        <SendCartelaButton
          onClick={() => navigate(-1)}
          variant="contained"
          $background="#E5AA1A"
          $spacingTop="0.5rem"
        >
          Cancelar
        </SendCartelaButton>
      </>
    </ContentBody>
  );
};
