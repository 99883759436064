import { useEffect, useState } from 'react';

// STYLES
import {
  ButtonBox,
  ContainerButtonLinkEstablishment,
  ContainerIndividualPage,
  ContainerTable,
  ContentText,
  DataBox,
  LinkEstablishmentButton,
  PdvBox,
  Text,
  TextTitle,
  WhiteBoxOwner,
} from '../styles/DistribuitionCartelasStyle';
import { BsPersonFill } from 'react-icons/bs';
import { HiClipboardDocumentList } from 'react-icons/hi2';
import { FaExchangeAlt, FaStore } from 'react-icons/fa';
import { MdAttachMoney, MdBusinessCenter } from 'react-icons/md';
import { CircularProgress, Tooltip } from '@mui/material';
import { Oval } from 'react-loading-icons';
import { Content, InsideContent } from '../../../styled/generic';
import { ReactComponent as VinculoImg } from '../../../assets/images/vinculo.svg';

// COMPONENTS
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import ModalGenericConfirmProccess from '../../../components/Modals/ModalGenericConfirmProccess';
import EstablishmentsLinkWithAccreditedTable from '../../../components/Tables/EstablishmentsLinkWithAccreditedTable';
import { ModalToggleOwnerAccredited } from '../../../components/Modals/ModalToggleOwnerAccredited';
import { ModalEditAccredited } from '../../../components/Modals/ModalEditAccredited';

//LIBS
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { mask } from 'remask';
import { Link } from 'react-router-dom';

// APIS
import { toggleAccreditedStatus } from '../../../services/APIs/toggleAccreditedStatus';
import { getPDVById } from '../../../services/APIs/getPdvById';
import { getUserInfo_v1 } from '../../../services/getUserInfo';
import { unlinkEstablishmentPdv } from '../../../services/APIs/unlinkEstablishmentPdv';

export function AccreditedIndividualPage() {
  const { pathname } = document.location;
  const idPdv = pathname.split('/')[3];
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [pdv, setPdv] = useState([]);
  const [editPdv, setEditPdv] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selected, setSelected] = useState('todos os estabelecimentos');
  const [loadingDisableAccredited, setLoadingDisableAccredited] = useState(false);
  const [openModalToogleOwnerAccredited, setOpenModalToogleOwnerAccredited] = useState(false);
  const [openModalEditAccredited, setOpenModalEditAccredited] = useState(false);
  const [origin, setOrigin] = useState('');
  const [updatePage, setUpdatePage] = useState('false');
  const [accreditationOwner, setAccreditationOwner] = useState({
    name: '',
    cellphone: '',
    cpf: '',
    email: '',
  });
  const [establishmentId, setEstablishmentId] = useState('');

  // PEGA AS INFORMAÇÕES DO CREDENCIADO
  useEffect(() => {
    getPDVById(uId, idPdv).then((info) => {
      // ENDEREÇO FOI ALTERADO NO MEIO DO PROJETO ALGUNS USUARIOS CRIARAM ADDRESS E ERAM SETADOS COMO STRING POSTERIORMENTE FOI POSTADO COMO OBJECT E POR ISSO ESSAS CONDIÇÕES ABAIXO DE VALIDAÇÃO.
      const x = info.addressList ? info.addressList[0] : info.address;
      const ajustInfo = {
        ...info,
        address:
          typeof x === 'string'
            ? x
            : x.streetAve +
              ', nº ' +
              x.number +
              ', ' +
              x.neighborhood +
              ', ' +
              x.city +
              '-' +
              x.state +
              ', ' +
              x.zipCode +
              ', ' +
              x.extra +
              (x.landmark !== undefined ? ' - ' + x.landmark : ''),
      };
      delete ajustInfo.addressList;
      setPdv(ajustInfo);
      setEditPdv(info);
    });
  }, [uId, idPdv, updatePage]);

  // PEGAS AS INFORMAÇÕES DO DONO DO CREDENCIAMENTO
  useEffect(() => {
    const uIdOwnerPdv = pdv.accreditedId;
    const userInfo = {
      uId: uIdOwnerPdv,
    };

    if (pdv.length !== 0)
      getUserInfo_v1(['getUserInfo'], userInfo).then((item) => {
        item?.partnerList?.map((partner) => {
          //ENTRA NO PARTNER BRASIL CAP
          if (partner.partnerName === 'BrasilCap') {
            //PEGA AS INFORMAÇÕES DE CONTATO
            partner.contactList.map((contacts) => {
              switch (contacts.type) {
                case 'cellPhone':
                  setAccreditationOwner((prevAccreditationOwner) => ({
                    ...prevAccreditationOwner,
                    cellphone: contacts.value,
                  }));
                  break;
                case 'email':
                  setAccreditationOwner((prevAccreditationOwner) => ({
                    ...prevAccreditationOwner,
                    email: contacts.value,
                  }));
                  break;
                default:
                  break;
              }
              return null;
            });

            //PEGA O CPF
            partner.documentList.map((document) => {
              if (document.type === 'cpf') {
                setAccreditationOwner((prevAccreditationOwner) => ({
                  ...prevAccreditationOwner,
                  cpf: document.value,
                }));
              }
              return null;
            });

            //PEGA O NOME DO OWNER
            setAccreditationOwner((prevAccreditationOwner) => ({
              ...prevAccreditationOwner,
              name: partner.name,
            }));
          }
          return null;
        });
        return null;
      });
  }, [pdv]);

  const handleModalOpen = (type) => {
    switch (type) {
      case 'unlinkPdv':
        setOrigin('unlinkPdv');
        break;
      case 'disabledAccredited':
        setOrigin('disabledAccredited');
        break;
      default:
        break;
    }
    setIsOpenModal(true);
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
  };

  const treatmentDocument = () => {
    switch (pdv.id.length) {
      case 11:
        return mask(pdv.id, ['999.999.999-99']);
      case 14:
        return mask(pdv.id, ['99.999.999/9999-99']);
      default:
        return null;
    }
  };

  // ATIVA E DESATIVA O PDV
  const toggleStatusPdv = async () => {
    !isOpenModal && setLoading(true);
    setLoadingDisableAccredited(true);
    try {
      const result = await toggleAccreditedStatus(uId, idPdv, !pdv.status);
      setPdv({ ...pdv, status: result.response.status });
      setLoading(false);
    } catch {
      toast.error(
        'Não foi possível atualizar o status do credenciado, tente novamente mais tarde.'
      );
    } finally {
      setLoading(false);
      setLoadingDisableAccredited(false);
      handleModalClose();
    }
  };

  const handleModal = (type) => {
    switch (type) {
      case 'toggleOwnerAccredited':
        setOpenModalToogleOwnerAccredited(!openModalToogleOwnerAccredited);
        break;
      case 'editAccredited':
        setOpenModalEditAccredited(!openModalEditAccredited);
        break;
      default:
        break;
    }
  };

  const unlinkPdv = async () => {
    setLoadingDisableAccredited(true);

    try {
      const payload = { pdvId: establishmentId, accreditedId: idPdv };
      await unlinkEstablishmentPdv(uId, payload);
      setUpdatePage(!updatePage);
      toast.success('Ponto de venda desvinculado com sucesso.');
      handleModalClose();
    } catch (e) {
      toast.error(e);
    } finally {
      setLoadingDisableAccredited(false);
    }
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <ContainerIndividualPage>
            {pdv.length === 0 ? (
              <PdvBox>
                <CircularProgress size={230} style={{ color: '#fff' }} />
              </PdvBox>
            ) : (
              <PdvBox id={pdv.status ? '' : 'inactive'}>
                <DataBox>
                  <ContentText>
                    <TextTitle>
                      <MdBusinessCenter /> {pdv.id.length === 11 ? 'CPF' : 'CNPJ'}
                    </TextTitle>
                    <Text>{treatmentDocument()}</Text>
                  </ContentText>
                  <ContentText>
                    <TextTitle>
                      <BsPersonFill /> Nome do credenciado
                    </TextTitle>
                    <Text>{pdv.name}</Text>
                  </ContentText>
                  <ContentText>
                    <TextTitle>
                      <HiClipboardDocumentList size={16} /> Descrição
                    </TextTitle>
                    <Text>{pdv.description}</Text>
                  </ContentText>
                  <ContentText>
                    <TextTitle>
                      <FaStore /> Endereço
                    </TextTitle>
                    <Text>{pdv.address}</Text>
                  </ContentText>
                  <ContentText>
                    <TextTitle>
                      <MdAttachMoney style={{ height: '1.2rem', width: '1.2rem' }} />
                      Comissão
                    </TextTitle>
                    <Text style={{ marginLeft: '2.8rem' }}>{pdv.commission}%</Text>
                  </ContentText>
                </DataBox>
                <DataBox></DataBox>
                <ButtonBox style={{ gap: '1rem' }}>
                  <button
                    style={{
                      fontSize: '0.8em',
                      color: '#2d70b9',
                      width: 'fit-content',
                    }}
                    className="button"
                    onClick={() =>
                      !pdv.status ? toggleStatusPdv() : handleModalOpen('disabledAccredited')
                    }
                    id={pdv.status ? '' : 'inactiveButton'}
                    disabled={loading}
                  >
                    {pdv.status ? 'Desativar credenciado' : 'Ativar credenciado'}
                  </button>
                  <button
                    style={{
                      fontSize: '0.8em',
                      color: '#2d70b9',
                      width: 'fit-content',
                    }}
                    className="button"
                    onClick={() => handleModal('editAccredited')}
                  >
                    Editar dados
                  </button>
                </ButtonBox>
              </PdvBox>
            )}
            <WhiteBoxOwner className="box">
              <span>
                <p>Proprietário do credenciamento</p>
                <Tooltip title="Alterar proprietário do credenciamento" placement="top">
                  <button onClick={() => handleModal('toggleOwnerAccredited')}>
                    <FaExchangeAlt size={20} />
                  </button>
                </Tooltip>
              </span>
              <input
                className="input is-fullwidth"
                style={{
                  marginBottom: '1em',
                }}
                disabled
                placeholder="Nome não encontrado"
                value={accreditationOwner.name}
              />
              <ReactInputMask
                className="input is-fullwidth"
                mask="(+55) 99 9 9999-9999"
                style={{
                  marginBottom: '1em',
                }}
                disabled
                placeholder="Telefone não encontrado"
                value={accreditationOwner.cellphone}
              />
              <ReactInputMask
                className="input is-fullwidth"
                mask="999.999.999-99"
                style={{
                  marginBottom: '1em',
                }}
                placeholder="CPF não encontrado"
                disabled
                value={accreditationOwner.cpf}
              />
              <input
                className="input is-fullwidth"
                style={{
                  marginBottom: '1em',
                }}
                placeholder="E-mail não encontrado"
                disabled
                value={accreditationOwner.email}
              />
            </WhiteBoxOwner>
            <div className={`modal ${loading ? 'modal is-active' : 'modal'}`}>
              <div
                className="modal-background"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Oval stroke="#EDB622" />
              </div>
            </div>
          </ContainerIndividualPage>
          <ContainerButtonLinkEstablishment>
            <Link to={`/operacao-cartela/gerenciar-credenciados/${idPdv}/vincular`}>
              <LinkEstablishmentButton>
                <VinculoImg />
              </LinkEstablishmentButton>
              Vincular estabelecimentos
            </Link>
          </ContainerButtonLinkEstablishment>
          <ContainerTable>
            <label style={{ margin: '2rem 0 1rem 1rem' }}>
              Selecionar status:&nbsp;
              <select onChange={(e) => setSelected(e.target.value)}>
                <option value="todos os estabelecimentos">Todos os estabelecimentos</option>
                <option value="ativos">Estabelecimentos ativos</option>
                <option value="inativos">Estabelecimentos inativos</option>
              </select>
            </label>
            <EstablishmentsLinkWithAccreditedTable
              idPDV={idPdv}
              selected={selected}
              establishmentList={
                pdv.hasOwnProperty('establishments')
                  ? Object.values(pdv.establishments)
                  : 'not found'
              }
              openModalUnlinkPdv={() => handleModalOpen('unlinkPdv')}
              setEstablishmentId={setEstablishmentId}
              setUpdatePage={setUpdatePage}
              updatePage={updatePage}
            />
          </ContainerTable>
        </InsideContent>
      </Content>
      <ModalGenericConfirmProccess
        action={origin === 'unlinkPdv' ? unlinkPdv : toggleStatusPdv} // API OU FUNÇÃO DO BOTÃO DE CONFIRMAR
        body={
          origin === 'unlinkPdv'
            ? 'Tem certeza que deseja desvincular esse estabelecimento?'
            : 'Tem certeza que deseja desativar este credenciado?'
        } // MENSAGEM DO CORPO DO MODAL
        canceled={'Cancelar'} // TRUE OU FALSE SE DESEJAR QUE EXIBA UM BOTÃO DE CANCELAR
        close={handleModalClose} // FECHA MODAL
        labelButton={origin === 'unlinkPdv' ? 'Desvincular' : 'Desativar'} // LABEL DO BOTÃO DE CONFIRMAR
        labelCancelation={'Voltar'} // LABEL DO BOTÃO DE CANCELAR
        open={isOpenModal} // ESTADO DO MODAL
        title={origin === 'unlinkPdv' ? 'Desvincular ponto de venda' : 'Desativação de credenciado'}
        loading={loadingDisableAccredited}
      />
      <ModalToggleOwnerAccredited
        close={() => handleModal('toggleOwnerAccredited')}
        open={openModalToogleOwnerAccredited}
        accreditedId={idPdv}
        oldAccreditedUid={pdv.accreditedId}
        setUpdatePage={setUpdatePage}
        updatePage={updatePage}
      />
      <ModalEditAccredited
        close={() => handleModal('editAccredited')}
        open={openModalEditAccredited}
        pdv={editPdv}
        uId={uId}
        setUpdatePage={setUpdatePage}
        updatePage={updatePage}
      />
    </>
  );
}
