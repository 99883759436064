import React from 'react';
import MUIDataTable from 'mui-datatables';

// STYLES
import { StyledDiv } from './style/MUIDatatable';
import { Checkbox, CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { BottomMargin } from '../../pages/SellerPage/style/SellerPageStyle';
import { BsCircleFill } from 'react-icons/bs';

export default function CardErrosTable({ infosForTable, loading, erroChecked, setErroChecked }) {
  const displayData = infosForTable;

  const selectLeads = (e, value) => {
    const checked = e.target.checked;

    if (checked) {
      setErroChecked((prevErroChecked) => [...prevErroChecked, value]);
    } else {
      setErroChecked((prevErroChecked) => prevErroChecked.filter((id) => id !== value));
    }
  };

  const columns = [
    {
      name: 'leadId',
      label: 'Selecione',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: false,
        customBodyRender: (value, data) =>
          !data.rowData[5] && (
            <Checkbox
              checked={erroChecked.includes(value)}
              onChange={(e) => selectLeads(e, value)}
            />
          ),
      },
    },
    {
      name: 'leadId',
      label: 'Lead',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'cardId',
      label: 'Bilhete',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => value ?? '--',
      },
    },
    {
      name: 'lastTimeReprocessed',
      label: 'Data do último reprocessamento',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => value ?? '--',
      },
    },
    {
      name: 'processError',
      label: 'Erro',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'timesReprocessed',
      label: 'Quantidade de reprocessamentos',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => value ?? '--',
      },
    },
    {
      name: 'isReprocessed',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          if (value) {
            return (
              <span>
                <BsCircleFill style={{ color: '#ffa500' }} />
                &nbsp; Em processamento
              </span>
            );
          } else {
            return (
              <span>
                <BsCircleFill style={{ color: '#EB1E0A' }} />
                &nbsp; Pendente de processamento
              </span>
            );
          }
        },
      },
    },
  ];

  const tableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: true,
    responsive: 'simple',
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: '',
        toolTip: `${'table.toolTip'}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${'table.viewColumnsTitle'}`,
      },
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              textAlign: 'center',
              alignContent: 'center',
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              cursor: 'default',
              fontSize: '85%',
              alignContent: 'center',
              textAlign: 'center',
            },
          },
        },
      },
    });

  return (
    <BottomMargin style={{ width: '70%' }}>
      <StyledDiv
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ThemeProvider theme={getMuiTheme()}>
          {loading ? (
            <CircularProgress size={80} thickness={4} style={{ marginTop: '3rem' }} />
          ) : (
            <MUIDataTable
              className={'table is-mobile'}
              data={displayData}
              columns={columns}
              options={tableOptions}
            />
          )}
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}
