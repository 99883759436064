import React, { useEffect, useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { StyledDiv } from "../Tables/style/MUIDatatable";
import { getAllSellers } from "../../services/getAll";
import { authToken } from "../../services/APIs/token";
import { BottomMargin } from "../../pages/SellerPage/style/SellerPageStyle";
import { CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

export default function SellerListTable({ selected }) {
  const uidSupervisor = JSON.parse(localStorage.getItem("uid"));
  const role = JSON.parse(localStorage.getItem("role"));
  const [cpfSelected, setCpfSelected] = useState("");
  const [phoneSelected, setPhoneSelected] = useState("");
  const [supervisor, setSupervisor] = useState();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['SellerList'],
    queryFn: async () => {
      const token = await authToken()
      if (token) {
        const response = await getAllSellers(uidSupervisor, token, role);
        return response.data
      }
    },
    keepPreviousData: true,
    retry: 1,
    refetchOnWindowFocus: false,
  })

  const navigate = useNavigate();

  useEffect(() => {
    if (cpfSelected !== "") {
      localStorage.setItem(
        "sellerPhone",
        displayData.find((e) => e.cellPhone === "+55" + phoneSelected).cellPhone
      );
      localStorage.setItem("supervisorName", supervisor);
      navigate(`/vendedor/${displayData.find((e) => e.CPF === cpfSelected).Data.uid}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpfSelected, phoneSelected, navigate]);

  if (isError)
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        {`Não existem vendedores cadastrados para este ${role === "manager" ? "gerente" : "supervisor"
          }`}
      </div>
    );
  if (isLoading)
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );

  const displayData = data && data.response.filter((el) => el.Data.active === selected);

  const columns = [
    {
      name: "name",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: "CPF",
      label: "CPF",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          const cleaned = ("" + value).replace(/\D/g, "");
          let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
          if (match) {
            return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
          }
        },
      },
    },
    {
      name: "cellPhone",
      label: "Telefone",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          const cleaned = ("" + value).replace("+55", "");
          let match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
          if (match) {
            return `${match[1]} ${match[2]} ${match[3]}-${match[4]}`;
          }
        },
      },
    },
    {
      name: "Data",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          if (!value.active) {
            return (
              <span>
                <BsCircleFill style={{ color: "#8A8F91" }} />
                &nbsp;Inativo
              </span>
            );
          } else {
            return (
              <span>
                <BsCircleFill style={{ color: "#2DB94D" }} />
                &nbsp;Ativo
              </span>
            );
          }
        },
      },
    },
    {
      name: "nameSupervisor",
      label: "Supervisor",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
      },
    },
    {
      name: "Data",
      label: "Saldo",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data.balance;
            let val2 = obj2.data.balance;
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        customBodyRender: (value) => {
          return (
            <span>
              {(value.balance / 100).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          );
        },
      },
    },
  ];

  const tableOptions = {
    onRowClick: (rowData) => {
      setCpfSelected(rowData[1].replaceAll(".", "").replace("-", ""));
      setPhoneSelected(rowData[2].replaceAll(" ", "").replace("-", ""));
      setSupervisor(rowData[4]);
    },
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: "simple",
    filterType: "textField",
    textLabels: {
      body: {
        noMatch: "",
        toolTip: `${"table.toolTip"}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${"table.viewColumnsTitle"}`,
      },
    },
  };
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
            },
          },
        },
      },
    });

  return (
    <BottomMargin>
      <StyledDiv style={{ cursor: "pointer" }}>
        <ThemeProvider theme={() => getMuiTheme()}>
          <MUIDataTable
            className={"table is-mobile"}
            data={displayData}
            columns={columns}
            options={tableOptions}
          />
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}
