import React, { useContext, useState } from 'react';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';
import { toast } from 'react-toastify';

//STYLES
import {
  ModalHeader,
  ModalBody,
  DivModal,
  HeaderText,
  ButtonReceived,
} from './style/ModalStyle';
import { CircularProgress } from '@mui/material';
import { MessageAlert } from '../../pages/DistribuitionCartelas/styles/DistribuitionCartelasStyle';

// APIS
import { validateSerie } from '../../services/APIs/ValidateSerie';
import { processSerie } from '../../services/APIs/processSerie';

export default function ModalValidationAndProcessSerie({
  isOpen,
  close,
  statusSerie,
}) {
  const { serie } = useContext(DistribuitionCartelaContext);
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const validationSerie = () => {
    setLoading(true);

    validateSerie(uId, serie)
      .then(() => {
        toast.success('Série validada com sucesso.');
        localStorage.setItem('updateList', true);
        close();
      })
      .catch((err) => {
        if (err.includes('registered')) {
          const ajustMessageError = err.replace('registered', 'cadastrados');
          setMessage(ajustMessageError);
        } else {
          setMessage(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const proccessingSerie = () => {
    setLoading(true);

    processSerie(uId, serie)
      .then(() => {
        toast.success('Série em processamento...');
        localStorage.setItem('updateList', true);
        close();
      })
      .catch((err) => {
        setMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeModalSerie = () => {
    setMessage('');
    close();
  };

  return (
    <div className={`modal ${isOpen ? 'modal is-active' : 'modal'}`}>
      <div onClick={() => closeModalSerie()} className="modal-background"></div>
      <DivModal style={{ minHeight: '12rem' }} className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">
            {statusSerie === 'registered'
              ? 'Validar arquivo'
              : 'Processar arquivo'}
          </HeaderText>
        </ModalHeader>
        <ModalBody className="modal-card-body">
          {message !== '' ? (
            <MessageAlert style={{ fontSize: '1.2rem', fontWeight: 700 }}>
              {message}
            </MessageAlert>
          ) : (
            <>
              <h4>
                Deseja iniciar{' '}
                {statusSerie === 'registered'
                  ? 'a validação '
                  : 'o processamento'}{' '}
                dessa série ?
              </h4>
              <ButtonReceived
                onClick={
                  statusSerie === 'registered'
                    ? validationSerie
                    : proccessingSerie
                }
                className={
                  window.screen.width > 450 ? 'button' : 'button is-small'
                }
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: '#fff' }}
                    size={26}
                    thickness={5}
                  />
                ) : statusSerie === 'registered' ? (
                  'Validar arquivo'
                ) : (
                  'Processar arquivo'
                )}
              </ButtonReceived>
            </>
          )}
        </ModalBody>
      </DivModal>
    </div>
  );
}
