import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { StyledDiv } from '../Tables/style/MUIDatatable';
import { BottomMargin } from '../../pages/SellerPage/style/SellerPageStyle';
import {
  CircularProgress,
  createTheme,
  Input,
  ThemeProvider,
} from '@mui/material';
import { LoadingTable } from '../../pages/ListOfRaffles/style/ListOfRaffles';
import { CampaingnContext } from '../../context/campaigns';

export default function RafflesTable({ selected }) {
  const { campaigns } = useContext(CampaingnContext);
  const [id, setId] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/administracao/lista-de-sorteios/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (campaigns?.length === 0)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingTable>
          <CircularProgress size="100px" />
        </LoadingTable>
      </div>
    );

  const displayData = campaigns.filter((el) => el.status === selected);

  const columns = [
    {
      name: 'id',
      label: 'Id da campanha',
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Input
              type="text"
              value={value}
              onChange={(event) => {
                updateValue(event.target.value);
                this.updateSomeArray({});
              }}
            />
          );
        },
      },
    },
    {
      name: 'startDate',
      label: 'Data de ativação',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'drawDate',
      label: 'Data do sorteio',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
      },
    },
    {
      name: 'endDate',
      label: 'Data de desativação',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
      },
    },
  ];
  const tableOptions = {
    onRowClick: (rowData) => {
      setId(rowData[0]);
    },
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: 'simple',
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: '',
        toolTip: `${'table.toolTip'}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${'table.viewColumnsTitle'}`,
      },
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
            },
          },
        },
      },
    });

  return (
    <BottomMargin>
      <StyledDiv style={{ cursor: 'pointer' }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            className={'table is-mobile'}
            data={displayData}
            columns={columns}
            options={tableOptions}
          />
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}
