// STYLES
import { ButtonDistribuitionTable } from '../Tables/style/EditButtomTableStyles';
import { FaTruckArrowRight } from 'react-icons/fa6';
import Tooltip from '@mui/material/Tooltip';

// COMPONENTS AND OTHERS
import { convertVisualDateForTimestamp } from '../../services/helpers';
import { Link } from 'react-router-dom';

export const CardReservationbutton = ({ date, reservedInfos, serie, status }) => {
  const statusReleasesAction = ['processedSuccess', 'lossInitiated', 'lossError', 'lossCompleted'];

  return statusReleasesAction.includes(status) ? (
    <Tooltip title="Reservar cartela" placement="top">
      <Link to={`/operacao-cartela/gerenciar-series/reservar-cartelas/${serie}`}>
        <ButtonDistribuitionTable
          onClick={() => reservedInfos(serie, convertVisualDateForTimestamp(date))}
        >
          <FaTruckArrowRight size={20} style={{ color: '#2D70B9' }} />
        </ButtonDistribuitionTable>
      </Link>
    </Tooltip>
  ) : null;
};

