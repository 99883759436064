import styled from "styled-components";

export const StyledDiv = styled.div`
  table[class*="MuiTable-root tss-5jmwmz-MUIDataTableFooter-root css-rqglhn-MuiTable-root"] {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  div[class*="MuiTablePagination-root tss-1v1f2er-MUIDataTablePagination-root css-jtlhu6-MuiTablePagination-root"] {
    display: flex;
    align-items: baseline;
  }
  th[class="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeMedium tss-10syd3x-MUIDataTableHeadCell-root tss-gm6zfk-MUIDataTableHeadCell-fixedHeader css-1ygcj2i-MuiTableCell-root"] {
    z-index: 0;
  }
  thead[class="MuiTableHead-root tss-1937jnc-MUIDataTableHead-main css-15wwp11-MuiTableHead-root"] {
    z-index: 0;
  }
  span[class*="-MUIDataTableHeadCell-contentWrapper"] {
    justify-content: left;
    button {
      transition: 0.3s;
      :hover {
        background-color: #2d70b9;
        color: white;
      }
    }
  }
`;
