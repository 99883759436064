import React from "react";
import { Button } from "../../styled/RegisterStyle";
import { ModalHeader, ModalBody, DivModal, HeaderText } from "./style/ModalStyle";
import { NumericFormat } from "react-number-format";
import currencyFormatter from "../../helpers/currencyFormatter";

export default function ModalBalance({
  openUpdate,
  handleModalUpdate,
  updateSeller,
  updateCredits,
  setUpdateCredits,
}) {
  let checkbtn = updateCredits === 0;
  return (
    <div className={`modal ${openUpdate ? "modal is-active" : "modal"}`}>
      <div onClick={() => handleModalUpdate()} className="modal-background"></div>
      <DivModal className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">Adicionar saldo</HeaderText>
        </ModalHeader>
        <ModalBody className="modal-card-body">
          <p>Insira o valor que deseja adicionar</p>
          <NumericFormat
            prefix="R$ "
            placeholder="R$ 0,00"
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            fixedDecimalScale
            format={currencyFormatter}
            onValueChange={(value) => {
              setUpdateCredits(parseInt(value.floatValue * 100));
            }}
          />
          <div>
            <Button
              onClick={() => handleModalUpdate()}
              className={
                window.screen.width > 450 ? "button is-ghost" : "button is-small is-ghost"
              }>
              Cancelar
            </Button>
            <Button
              style={{ backgroundColor: checkbtn ? "#464646" : null }}
              onClick={() => updateSeller()}
              disabled={checkbtn}
              className={window.screen.width > 450 ? "button" : "button is-small"}>
              Salvar
            </Button>
          </div>
        </ModalBody>
      </DivModal>
    </div>
  );
}
