// STYLES
import { ButtonDistribuitionTable } from '../Tables/style/EditButtomTableStyles';
import { GrValidate } from 'react-icons/gr';
import Tooltip from '@mui/material/Tooltip';
import { VscServerProcess } from 'react-icons/vsc';

export const ValidAndProccessButton = ({ openModal, serie, status }) => {
  return status === 'registered' ? (
    <Tooltip title="Validar arquivo" placement="top">
      <ButtonDistribuitionTable onClick={() => openModal(serie, status)}>
        <GrValidate size={20} style={{ color: '#2DB94D' }} />
      </ButtonDistribuitionTable>
    </Tooltip>
  ) : status === 'validationSuccess' ? (
    <Tooltip title="Processar arquivo" placement="top">
      <ButtonDistribuitionTable onClick={() => openModal(serie, status)}>
        <VscServerProcess size={20} style={{ color: '#E74C3C' }} />
      </ButtonDistribuitionTable>
    </Tooltip>
  ) : (
    <ButtonDistribuitionTable
      style={{ visibility: 'hidden', cursor: 'default' }}
    >
      <VscServerProcess size={20} />
    </ButtonDistribuitionTable>
  );
};
