import React, { useEffect } from 'react';
import NoteBook from '../../assets/images/89360 [Convertido]@2x.png';
import FormLogin from '../../components/Login/FormLogin';
import {
  HeaderText,
  Container,
  LeftDiv,
  RightDiv,
  NoteBookImg,
  NoteBookBoxImg,
  AddressText,
} from './style/Login';

import { useNavigate } from 'react-router-dom';

export default function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('redirect')) {
      navigate('/dashboard');
    }
  }, [navigate]);

  return (
    <Container className="cloumns is-gapless">
      <LeftDiv className="column">
        <FormLogin />
      </LeftDiv>
      <RightDiv className="column">
        <HeaderText>
          Crie relatórios de
          <br /> vendas. e controle
          <br />
          os resultados.
        </HeaderText>
        <NoteBookBoxImg>
          <NoteBookImg src={NoteBook} />
        </NoteBookBoxImg>
        <AddressText>
          Av. Brigadeiro Faria Lima, 5544, 15090-000 - São José do Rio Preto
        </AddressText>
      </RightDiv>
    </Container>
  );
}
