import React, { useContext, useEffect, useState } from 'react';

//STYLES
import { ModalHeader, ModalBody, DivModal, HeaderText, ButtonReceived } from './style/ModalStyle';
import { CircularProgress } from '@mui/material';

// APIS
import { lossSerie } from '../../services/APIs/lossSerie';

// OTHERS
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';
import { toast } from 'react-toastify';
import { verifyBeforeLoss } from '../../services/APIs/verifyBeforeLoss';

export default function ModalFinishSerie({ isOpen, close }) {
  const { serie } = useContext(DistribuitionCartelaContext);
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [finishedSerieIsEnable, setFinishedSerieIsEnable] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [quantityCartela, setQuantityCartela] = useState(0);

  // VERIFICAR SE AINDA EXISTE CARTELA NÃO DISTRIBUÍDA
  useEffect(() => {
    if (serie !== '') {
      setLoadingModal(true);
      verifyBeforeLoss(uId, serie)
        .then((res) => {
          res.hasOwnProperty('count')
            ? setFinishedSerieIsEnable(false)
            : setFinishedSerieIsEnable(true);

          res.hasOwnProperty('count') && setQuantityCartela(res.count);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingModal(false);
        });
    }
  }, [uId, serie]);

  const finishedSerie = async () => {
    setLoading(true);
    await lossSerie(uId, serie)
      .then(() => {
        toast.success('Série finalizada com sucesso.');
        localStorage.setItem('updateList', true);
        close('finishSerie');
      })
      .catch((error) => {
        setMessage(error);
        setTimeout(() => {
          setMessage('');
        }, 2500);
      })
      .finally(() => {
        setMessage('');
        setLoading(false);
      });
  };

  const handleCloseModal = () => {
    close('finishSerie');
    setMessage('');
  };

  return (
    <div className={`modal ${isOpen ? 'modal is-active' : 'modal'}`}>
      <div onClick={handleCloseModal} className="modal-background"></div>
      <DivModal style={{ minHeight: '12rem' }} className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">Finalizar série</HeaderText>
        </ModalHeader>
        <ModalBody className="modal-card-body" style={{ textAlign: 'center' }}>
          {message !== '' && <p style={{ color: 'red' }}>{message}</p>}
          {loadingModal ? (
            <CircularProgress size={50} thickness={5} />
          ) : (
            <>
              <h5>
                {finishedSerieIsEnable
                  ? `Deseja iniciar o processo de finalização da série ${serie} ? `
                  : `${
                      quantityCartela === 1
                        ? 'Ainda existe ' +
                          quantityCartela +
                          ' cartela não distribuída que não foi baixada. Ao prosseguir essas cartelas serão consideradas extraviadas. Você tem certeza de que deseja continuar com a finalização da série?'
                        : 'Ainda existem ' +
                          quantityCartela +
                          ' cartelas não distribuídas que não tiveram baixa. Ao prosseguir essas cartelas serão consideradas extraviadas. Você tem certeza de que deseja continuar com a finalização da série?'
                    }`}
              </h5>
              <span style={{ display: 'flex', gap: '1rem' }}>
                <ButtonReceived
                  onClick={handleCloseModal}
                  className={window.screen.width > 450 ? 'button' : 'button is-small'}
                  style={{ background: '#E5AA1A' }}
                >
                  CANCELAR
                </ButtonReceived>
                <ButtonReceived
                  onClick={
                    finishedSerieIsEnable ? finishedSerie : () => setFinishedSerieIsEnable(true)
                  }
                  className={window.screen.width > 450 ? 'button' : 'button is-small'}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress style={{ color: '#fff' }} size={26} thickness={5} />
                  ) : finishedSerieIsEnable ? (
                    'CONFIRMAR'
                  ) : (
                    'PROSSEGUIR'
                  )}
                </ButtonReceived>
              </span>
            </>
          )}
        </ModalBody>
      </DivModal>
    </div>
  );
}

