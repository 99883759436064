import { createContext, useState } from 'react';

export const DistribuitionCartelaContext = createContext();

export const DistribuitionCartelaProvider = ({ children }) => {
  const urlArray = document.location.pathname.split('/');
  const serieUrl = urlArray[urlArray.length - 1];
  const [serie, setSerie] = useState('');
  const [distrEndDate, setDistrEndDate] = useState(0);
  const [stopSellingDate, setStopSellingDate] = useState(0);
  const [resumeSerieStatus, setResumeSerieStatus] = useState([]);
  //PEGA AS INFOS NECESSÁRIAS PARA A RESERVA DE CARTELA
  const reservedInfos = (serie, distrEndDate) => {
    setSerie(serie);
    setDistrEndDate(distrEndDate);
    localStorage.setItem('distrEndDate', distrEndDate);
  };

  return (
    <DistribuitionCartelaContext.Provider
      value={{
        distrEndDate,
        reservedInfos,
        serie,
        serieUrl,
        setSerie,
        resumeSerieStatus,
        setResumeSerieStatus,
        stopSellingDate,
        setStopSellingDate,
      }}
    >
      {children}
    </DistribuitionCartelaContext.Provider>
  );
};
