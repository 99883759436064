import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// STYLES
import {
  BindEstablishmentContainer,
  Button,
  ContentNoHaveEstablishment,
  ContentSearchOperator,
  ContentTextSelectAll,
  DividerPage,
  DividerTable,
  InputSearchOperator,
  TableEstablishmentUser,
  VisibleScreenContent,
} from '../styles/DistribuitionCartelasStyle';
import { Content, InsideContent } from '../../../styled/generic';
import { Oval } from 'react-loading-icons';

// LIBS
import { toast } from 'react-toastify';
import { mask } from 'remask';

// COMPONENTS
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';

// APIS
import { getUserBrasilCap } from '../../../services/getUsersBrasilCap';
import { insertEstablishment } from '../../../services/APIs/insertEstablishment';
import { checkNotLinkedEstablishments } from '../../../services/APIs/checkNotLinkedEstablishments';
import { FaMagnifyingGlass } from 'react-icons/fa6';

export function BindEstablishment() {
  const { pathname } = document.location;
  const pdvId = pathname.split('/')[3];
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [phoneUser, setPhoneUser] = useState('');
  const [loading, setLoading] = useState(false);
  const [establishmentUser, setEstablishmentUser] = useState([]);
  const [establishmentSelected, setEstablishmentSelected] = useState([]);
  const [establishmentsFilter, setEstablishmentFilter] = useState([]);
  const [visibleInput, setVisibleInput] = useState(false);
  const [search, setSearch] = useState('');

  // BUSCA O USUARIO E RETORNA OS ESTABELECIMENTOS DOS QUAIS ELE É ADMIN
  useEffect(() => {
    const treatmentPhone = phoneUser.replace(/[^a-zA-Z0-9]/g, '');

    // LIMPA TELA CASO O TELEFONE MUDE
    setEstablishmentUser([]);
    setEstablishmentSelected([]);

    if (phoneUser.length === 15) {
      setLoading(true);

      getUserBrasilCap(treatmentPhone)
        .then((user) => {
          const uIdOperator = user.uId;

          checkNotLinkedEstablishments(uIdOperator)
            .then((establishments) => {
              const establishmentList = establishments.estList;
              const orderListEstablishment = establishmentList.sort(order); // Ordenação alfabética

              // Função de comparação para ordenar por uma propriedade em ordem alfabética
              function order(a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              }

              // TESTAR APÓS API VOLTAR A EXIBIR OS ESTABLISHMENT
              if (establishmentList.length === 0) {
                toast.warning(
                  'O usuário não é administrador de nenhum estabelecimento ou todos os seus estabelecimentos já foram vinculados a algum credenciado.'
                );
              }

              setEstablishmentUser(orderListEstablishment);
            })
            .catch((error) => {
              toast.error(error);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch((error) => {
          toast.error(error);
          setLoading(false);
        });
    }
  }, [phoneUser]);

  // FILTRA OS ESTABELECIMENTOS
  useEffect(() => {
    if (search.length >= 2) {
      const filteredEstablishments = establishmentUser.filter((establishment) => {
        return establishment.name.toLowerCase().includes(search.toLowerCase());
      });
      setEstablishmentFilter(filteredEstablishments);
    }
  }, [establishmentUser, search]);

  // FOCA NO INPUT DE BUSCA QUANDO ELE ESTIVER ATIVO
  useEffect(() => {
    if (visibleInput) {
      inputRef.current.focus();
    }
  }, [visibleInput]);

  const handlePhoneOperator = (e) => {
    const cellphoneMask = mask(e.target.value, ['(99) 99999-9999']);

    setPhoneUser(cellphoneMask);
  };

  const addAllEstablishment = () => {
    setEstablishmentSelected([...establishmentSelected, ...establishmentUser]);

    setEstablishmentUser([]);
  };

  const addEstablishment = (index) => {
    const selectedEstablishment = establishmentUser[index];

    // Remove o item do array original
    const updatedEstablishmentUser = [...establishmentUser];
    updatedEstablishmentUser.splice(index, 1);

    // Adiciona o item ao novo array
    setEstablishmentUser(updatedEstablishmentUser);
    setEstablishmentSelected([...establishmentSelected, selectedEstablishment]);
  };

  const removeEstablishment = (index) => {
    const removedEstablishment = establishmentSelected[index];

    // Remova o item do array secundário
    const updatedEstablishmentSelected = [...establishmentSelected];
    updatedEstablishmentSelected.splice(index, 1);

    // Adicione o item de volta ao array original
    setEstablishmentSelected(updatedEstablishmentSelected);
    setEstablishmentUser([...establishmentUser, removedEstablishment]);
  };

  const toggleScreenInput = () => {
    setVisibleInput(!visibleInput);
  };

  const saveEstablishmentInPdv = async () => {
    setLoading(true);
    if (establishmentSelected.length === 0) {
      toast.warning(
        'Você deve selecionar algum estabelecimento para ser vinculado antes de salvar.'
      );
      setLoading(false);
    } else {
      try {
        // Atualiza cada objeto em establishmentSelected
        const updatedEstablishmentSelected = establishmentSelected.map((establishment) => {
          // Remover o address e adicionar status true
          const { address, name, ...rest } = establishment;
          return { ...rest, status: true, establishmentName: name };
        });

        const payload = {
          pdvId,
          establishmentsList: updatedEstablishmentSelected,
        };

        await insertEstablishment(uId, payload);
        toast.success('Estabelecimentos vinculados com sucesso.');
        navigate(-1);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <BindEstablishmentContainer>
            <h4>Buscar Operador</h4>

            <ContentSearchOperator>
              <p>Número de telefone</p>
              <InputSearchOperator
                onChange={handlePhoneOperator}
                placeholder="(XX) XXXXX-XXXX"
                required
                value={phoneUser}
              />
            </ContentSearchOperator>

            <ContentTextSelectAll isButton>
              <button>
                <FaMagnifyingGlass onClick={toggleScreenInput} />
              </button>
              {visibleInput && (
                <VisibleScreenContent>
                  <input
                    type="text"
                    placeholder="Buscar estabelecimento"
                    ref={inputRef}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </VisibleScreenContent>
              )}
              <button onClick={addAllEstablishment}>Selecionar todos</button>
            </ContentTextSelectAll>
            <TableEstablishmentUser>
              {establishmentUser.length === 0 ? (
                <ContentNoHaveEstablishment>
                  Nenhum estabelecimento encontrado
                </ContentNoHaveEstablishment>
              ) : search.length >= 2 ? (
                establishmentsFilter.map((establishment, index) => (
                  <>
                    <table key={establishment.establishmentId}>
                      <tbody>
                        <tr>
                          <td>{establishment.name}</td>
                          <td>{establishment.address}</td>
                          <button
                            style={{ color: '#92cf59' }}
                            onClick={() => addEstablishment(index)}
                          >
                            +
                          </button>
                        </tr>
                      </tbody>
                    </table>
                    <DividerTable />
                  </>
                ))
              ) : (
                establishmentUser.map((establishment, index) => (
                  <>
                    <table key={establishment.establishmentId}>
                      <tbody>
                        <tr>
                          <td>{establishment.name}</td>
                          <td>{establishment.address}</td>
                          <button
                            style={{ color: '#92cf59' }}
                            onClick={() => addEstablishment(index)}
                          >
                            +
                          </button>
                        </tr>
                      </tbody>
                    </table>
                    <DividerTable />
                  </>
                ))
              )}
            </TableEstablishmentUser>

            <DividerPage />

            <ContentTextSelectAll>Selecionados</ContentTextSelectAll>
            <TableEstablishmentUser>
              {establishmentSelected.length === 0 ? (
                <ContentNoHaveEstablishment>
                  Nenhum estabelecimento selecionado
                </ContentNoHaveEstablishment>
              ) : (
                establishmentSelected.map((establishment, index) => (
                  <>
                    <table key={establishment.establishmentId}>
                      <tbody>
                        <tr>
                          <td>{establishment.name}</td>
                          <td>{establishment.address}</td>
                          <button
                            style={{ color: 'red' }}
                            onClick={() => removeEstablishment(index)}
                          >
                            -
                          </button>
                        </tr>
                      </tbody>
                    </table>
                    <DividerTable />
                  </>
                ))
              )}
            </TableEstablishmentUser>

            <Button
              className={window.screen.width > 450 ? 'button' : 'button is-small'}
              onClick={saveEstablishmentInPdv}
            >
              Salvar
            </Button>

            {/* LOADING */}
            <div className={`modal ${loading ? 'modal is-active' : 'modal'}`}>
              <div
                className="modal-background"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Oval stroke="#EDB622" />
              </div>
            </div>
          </BindEstablishmentContainer>
        </InsideContent>
      </Content>
    </>
  );
}
