import Footer from '../components/Footer/Footer';
import React, { useEffect, useState } from 'react';
import HeaderComp from '../components/Header/HeaderComp';
import SideBarDesktop from '../components/Sidebar/SideBarComp';
import { Content, InsideContent } from '../styled/generic';
import Logo from '../assets/images/LogoSquareV10.png';
import {
  BlueBox,
  SmallWhiteBox,
  LogoBlueBox,
  LabelBlueBox,
  WhiteBox,
  Input,
  SmallLabel,
  Button,
  Form,
  DivInputBlueBox,
} from '../styled/RegisterStyle';
import ReactInputMask from 'react-input-mask';
import ModalRegister from '../components/Modals/ModalRegister';
import { verifyUserPhone } from '../services/login';
import { authToken, authTokenWithSC } from '../services/APIs/token';
import { Oval } from 'react-loading-icons';
import { createSeller } from '../services/register';
import { getAllSupervisors } from '../services/getAll';
import { toast } from 'react-toastify';
import { findIndexInArray } from '../services/helpers';
import { useNavigate } from 'react-router-dom';

export default function SellerRegister() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [setCanShow] = useState(false);
  const [supervisor, setSupervisor] = useState([]);
  const [user, setUser] = useState();
  const [selectedSuper, setSelectedSuper] = useState(
    JSON.parse(localStorage.getItem('uid'))
  );
  const [id, setId] = useState();
  // eslint-disable-next-line
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);

  const userLogged = JSON.parse(localStorage.getItem('name'));
  const role = JSON.parse(localStorage.getItem('role'));
  const supervisorUid = JSON.parse(localStorage.getItem('uid'));

  const navigate = useNavigate();

  useEffect(() => {
    getSupervisors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSupervisors = async () => {
    authToken().then((token) => {
      getAllSupervisors(token)
        .then((response) => {
          setSupervisor(response.data.response);
        })
        .catch((e) => {});
      //setSelectedSuper(response.data.response[0].Data.uid);
    });
  };

  useEffect(() => {
    if (phoneNumber.length === 11) {
      getSellerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  const getSellerData = async () => {
    authTokenWithSC().then((token) => {
      setIsLoading(true);
      verifyUserPhone(phoneNumber, token).then((response) => {
        setIsLoading(false);
        setId(response.data.response.id);
        setUser(
          response.data.response.partnerList[
            findIndexInArray(
              response.data.response.partnerList,
              'partnerName',
              'BrasilCap'
            )
          ]
        );
        setCanShow(true);
      });
    });
  };
  const sellerReg = async () => {
    authToken().then((token) => {
      createSeller(id, selectedSuper, token).then((response) => {
        if (response.data.error === 'Vendedor já existente.') {
          toast.error('Vendedor já cadastrado!');
        } else {
          toast.success('Vendedor cadastrado!');
          navigate('/lista-vendedor');
        }
      });
    });
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              sellerReg();
            }}
          >
            <BlueBox>
              <LogoBlueBox src={Logo} />
              <DivInputBlueBox>
                <LabelBlueBox className="label" htmlFor="tel">
                  Cadastro de novo vendedor(a)
                </LabelBlueBox>
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Oval stroke="white" />
                  </div>
                ) : (
                  <ReactInputMask
                    mask="99 99999-9999"
                    maskChar=""
                    alwaysShowMask={false}
                    id="tel"
                    name="tel"
                    type="tel"
                    placeholder="Telefone"
                    className="input is-fullwidth is-small"
                    value={phoneNumber}
                    onChange={(e) => {
                      const cleaned = e.target.value.replace(' ', '');
                      const removeSpace = cleaned.replace('-', '');
                      setPhoneNumber(removeSpace);
                    }}
                  />
                )}
              </DivInputBlueBox>
            </BlueBox>
            <WhiteBox className="box">
              <SmallLabel className="label">
                Dados Pessoais
                <Input
                  value={user && user.name}
                  type="text"
                  placeholder="Nome"
                  className={
                    window.screen.width > 450
                      ? 'input is-fullwidth'
                      : 'input is-fullwidth is-small'
                  }
                  disabled
                />
              </SmallLabel>
              <ReactInputMask
                mask="999.999.999-99"
                style={{
                  marginBottom: '1em',
                }}
                value={
                  user &&
                  user.documentList.find((el) => el.type === 'cpf').value
                }
                type="text"
                placeholder="CPF"
                className={
                  window.screen.width > 450
                    ? 'input is-fullwidth'
                    : 'input is-fullwidth is-small'
                }
                disabled
              />
              <Input
                value={
                  user &&
                  user.contactList.find((el) => el.type === 'email').value
                }
                type="email"
                placeholder="E-mail"
                className={
                  window.screen.width > 450
                    ? 'input is-fullwidth'
                    : 'input is-fullwidth is-small'
                }
                disabled
              />
            </WhiteBox>
            <SmallWhiteBox className="box">
              <select
                className="select is-fullwidth"
                onClick={(e) => setSelectedSuper(e.target.value)}
              >
                {role !== 'manager' ? (
                  <option value={supervisorUid}>{userLogged}</option>
                ) : (
                  <>
                    {supervisor.map((supervisors) => (
                      <option
                        key={supervisors.name}
                        value={supervisors.Data.uid}
                      >
                        {supervisors.name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </SmallWhiteBox>
            <div
              className={`modal ${
                isLoadingRegister ? 'modal is-active' : 'modal'
              }`}
            >
              <div
                className="modal-background"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Oval stroke="#EDB622" />
              </div>
            </div>
            <Button
              type="submit"
              className={
                window.screen.width > 450 ? 'button' : 'button is-small'
              }
              disabled={selectedSuper === ''}
            >
              Salvar
            </Button>
          </Form>
          <ModalRegister />
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
