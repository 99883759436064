import { Button } from "../../styled/RegisterStyle";
import { ModalHeader, ModalBody, DivModal, HeaderText } from "./style/ModalStyle";
import React, { useContext } from "react";
import { RegisterContext } from "../../context/register";

export default function ModalRegister() {
  const { closeModal, handleModal } = useContext(RegisterContext);
  return (
    <div className={`modal ${closeModal ? "modal is-active" : "modal"}`}>
      <div onClick={() => handleModal()} className="modal-background"></div>
      <DivModal className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">Novo cadastro</HeaderText>
        </ModalHeader>
        <ModalBody className="modal-card-body">
          <p>Cadastro realizado com sucesso!</p>
          <Button
            onClick={() => handleModal()}
            className={window.screen.width > 450 ? "button" : "button is-small"}>
            Fechar
          </Button>
        </ModalBody>
      </DivModal>
    </div>
  );
}
