import React, { useState, useEffect } from 'react';
import { Content, InsideContent } from '../../styled/generic';
import { useParams } from 'react-router-dom';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import {
  MediumPictureProfile,
  MediumPictureProfileBox,
} from '../../styled/PictureProfiel';
import {
  BoxBalance,
  LeftDiv,
  ProfileBoxDesktop,
  ProfileBoxMobile,
  PictureBox,
  DataBox,
  ButtonBox,
  Role,
  NameNRole,
  Name,
  WhiteBoxSeller,
} from './style/SellerPageStyle';
import { Typography } from '@mui/material';
import { BsPersonFill } from 'react-icons/bs';

//Components
import ModalBalance from '../../components/Modals/ModalBalance';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import UpdateSellerSupervisor from '../../components/UpdateSellerSupervisor/UpdateSellerSupervisor';
import SingleSell from '../../components/Tables/SingleSell';
import Footer from '../../components/Footer/Footer';
import HeaderComp from '../../components/Header/HeaderComp';
import SideBarDesktop from '../../components/Sidebar/SideBarComp';
import SellerleadsTable from '../../components/Tables/SellerLeadsTable';

//Libs
import { toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

//Apis
import {
  getSellerData,
  enableSeller,
  disableSeller,
} from '../../services/updateStatus';
import {
  getSellerBalance,
  updateSellerBalance,
} from '../../services/updateBalance';
import { getSalesData } from '../../services/salesData';
import { verifyUserPhone } from '../../services/login';
import { authToken, authTokenWithSC } from '../../services/APIs/token';
import { updateSupervisor } from '../../services/updateSupervisor';
import { updateNoCodeSell } from '../../services/APIs/updateNoCodeSell';
import ModalGenericConfirmProccess from '../../components/Modals/ModalGenericConfirmProccess';

//Others
registerLocale('pt-BR', ptBR);

export default function SellerPage() {
  const [inactive, setInactive] = useState(false);
  const { id } = useParams();
  const [credits, setCredits] = useState();
  const [updateCredits, setUpdateCredits] = useState();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [sales, setSales] = useState();
  const actualDate = new Date().getTime();
  const [initial, setInitial] = useState(
    actualDate - (actualDate % 86400000) + 10800000
  );
  const [final, setFinal] = useState(new Date().getTime());
  const [isLoading, setIsLoading] = useState(false);
  const [seller, setSeller] = useState();
  const phone = localStorage.getItem('sellerPhone').replace('+55', '');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [sellerList, setSellerList] = useState();
  // eslint-disable-next-line
  const [filtering, setFiltering] = useState(false);
  const [checkedCodeSell, setCheckedCodeSell] = useState('');
  const [quantity, setQuantity] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleModalUpdate = () => {
    setOpenUpdate(!openUpdate);
  };

  useEffect(() => {
    const getSeller = async () => {
      authToken()
        .then((token) => {
          getSellerData(id, token).then((response) => {
            if (response.data.success) {
              setCheckedCodeSell(response.data.response?.noCodeSell);
              localStorage.setItem('uidSeller', response.data.response.uid);
            } else {
              setInactive(
                response.data.error === 'Vendedor desativado.' ? true : false
              );
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    };
    getSeller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getSeller = async () => {
      authTokenWithSC().then((token) => {
        verifyUserPhone(phone, token).then((response) => {
          response = response.data.response.partnerList.find(
            (e) => e.partnerName === 'BrasilCap'
          );
          setSeller(response);
        });
      });
    };
    getSeller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSellerValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credits]);

  const getSellerValue = () => {
    authToken().then((token) => {
      getSellerBalance(id, token).then((getSellerBal) => {
        setCredits(
          (getSellerBal.data.response / 100).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })
        );
      });
    });
  };

  const updateSeller = async () => {
    if (updateCredits === 0) {
      toast.error('Valor não pode ser igual a zero');
    } else {
      authToken().then((token) => {
        updateSellerBalance(id, updateCredits, token);
        setCredits(updateCredits);
        setUpdateCredits(0);
        handleModalUpdate();
      });
    }
  };

  const toggleSeller = async () => {
    authToken().then((token) => {
      if (inactive) {
        setInactive(!inactive);
        enableSeller(id, token);
      } else {
        setInactive(!inactive);
        disableSeller(id, token);
        handleModal();
      }
    });
  };

  const getSalesDates = () => {
    setIsLoading(true);
    authToken().then((token) => {
      getSalesData(id, initial, final, token).then((response) => {
        setSellerList(response.data.response);
        setSales(response);
        setIsLoading(false);

        if (response.data.response.length !== 0) {
          const values = response.data.response.map(
            (item) => item.status === 'issue' && item.quantity
          );
          setQuantity(values.reduce((acc, cv) => acc + cv, 0));
        }
      });
    });
  };

  useEffect(() => {
    getSalesDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateNameSupervisor = async (uidSupervisor) => {
    setIsLoadingUpdate(true);
    authToken().then((token) => {
      const uidSeller = localStorage.getItem('uidSeller');
      updateSupervisor(uidSupervisor, uidSeller, token).then((response) => {
        if (!response.data || !response.data.success) {
          toast.error('Este supervisor já é responsável por este vendedor!');
          setIsLoadingUpdate(false);
        } else {
          toast.success('Supervisor Atribuido!');
          setIsLoadingUpdate(false);
          setIsDisabled(true);
        }
      });
    });
  };

  const handleChangeCodeSell = async (event) => {
    const isChecked = event.target.checked;
    setCheckedCodeSell(isChecked);
    const resultUpdateNoCodeSell = await updateNoCodeSell(null, id, isChecked);

    if (resultUpdateNoCodeSell.data.success) {
      toast.success('Autenticação de SMS atualizada.');
    } else {
      toast.error('A autenticação de SMS não pode ser atualizada.');
    }
  };

  const handleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <ModalGenericConfirmProccess
            action={toggleSeller} // API OU FUNÇÃO DO BOTÃO DE CONFIRMAR
            body={'Tem certeza que deseja desativar este usuário?'} // MENSAGEM DO CORPO DO MODAL
            canceled={'Cancelar'} // TRUE OU FALSE SE DESEJAR QUE EXIBA UM BOTÃO DE CANCELAR
            close={handleModal} // FECHA MODAL
            labelButton={'Desativar'} // LABEL DO BOTÃO DE CONFIRMAR
            labelCancelation={'Voltar'} // LABEL DO BOTÃO DE CANCELAR
            open={isOpenModal} // ESTADO DO MODA
            title={`Desativação de ${
              window.location.pathname === `/vendedor/${id}`
                ? 'vendedor'
                : window.location.pathname === `/afiliado/${id}`
                ? 'afiliado'
                : 'supervisor'
            }
            (a)`}
          />
          <ModalBalance
            handleModalUpdate={handleModalUpdate}
            openUpdate={openUpdate}
            setOpenUpdate={setOpenUpdate}
            credits={credits}
            setCredits={setCredits}
            updateSeller={updateSeller}
            updateCredits={updateCredits}
            setUpdateCredits={setUpdateCredits}
          />

          <LeftDiv>
            <ProfileBoxDesktop id={!inactive ? '' : 'inactive'}>
              <div>
                <PictureBox>
                  <MediumPictureProfileBox>
                    <MediumPictureProfile>
                      <BsPersonFill id={!inactive ? '' : 'inactivePic'} />
                    </MediumPictureProfile>
                  </MediumPictureProfileBox>
                </PictureBox>
              </div>
              <DataBox>
                <NameNRole>
                  <Role>vendedor</Role>
                  <Name>{seller && seller.name}</Name>
                </NameNRole>
                <NameNRole>
                  <Role>Supervisor</Role>
                  <div
                    className={`modal ${
                      isLoadingUpdate ? 'modal is-active' : 'modal'
                    }`}
                  >
                    <div
                      className="modal-background"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Oval stroke="#EDB622" />
                    </div>
                  </div>
                  <Name>
                    <UpdateSellerSupervisor
                      isDisabled={isDisabled}
                      setIsDisabled={setIsDisabled}
                      updateNameSupervisor={updateNameSupervisor}
                      stateCodeSell={checkedCodeSell}
                      functionCodeSell={handleChangeCodeSell}
                    />
                  </Name>
                </NameNRole>
              </DataBox>
              <ButtonBox className="column">
                <button
                  style={{
                    fontSize: '0.8em',
                    color: '#2d70b9',
                    width: 'fit-content',
                  }}
                  className="button"
                  onClick={inactive ? () => toggleSeller() : handleModal}
                  id={!inactive ? '' : 'inactiveButton'}
                >
                  {!inactive ? 'Desativar vendedor' : 'Ativar vendedor'}
                </button>
              </ButtonBox>
            </ProfileBoxDesktop>
            {window.screen.width <= 450 && (
              <ProfileBoxMobile
                className="columns"
                id={!inactive ? '' : 'inactive'}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <PictureBox>
                      <MediumPictureProfileBox>
                        <MediumPictureProfile>
                          <BsPersonFill id={!inactive ? '' : 'inactivePic'} />
                        </MediumPictureProfile>
                      </MediumPictureProfileBox>
                    </PictureBox>
                    <DataBox className="column">
                      <NameNRole>
                        <Role>vendedor</Role>
                        <Name>{seller && seller.name}</Name>
                      </NameNRole>
                      <NameNRole>
                        <Role>Supervisor</Role>
                        <div
                          className={`modal ${
                            isLoadingUpdate ? 'modal is-active' : 'modal'
                          }`}
                        >
                          <div
                            className="modal-background"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Oval stroke="#EDB622" />
                          </div>
                        </div>
                        <Name>
                          <UpdateSellerSupervisor
                            isDisabled={isDisabled}
                            setIsDisabled={setIsDisabled}
                            updateNameSupervisor={updateNameSupervisor}
                          />
                        </Name>
                      </NameNRole>
                    </DataBox>
                  </div>
                  <button
                    style={{
                      fontSize: '0.8em',
                      color: '#2d70b9',
                      width: 'fit-content',
                    }}
                    className="button"
                    onClick={inactive ? () => toggleSeller() : handleModal}
                    id={!inactive ? '' : 'inactiveButton'}
                  >
                    {!inactive ? 'Desativar vendedor' : 'Ativar vendedor'}
                  </button>
                </div>
              </ProfileBoxMobile>
            )}
            <WhiteBoxSeller className="box">
              <p
                style={{
                  color: 'grey',
                  fontSize: '0.8em',
                }}
              >
                Dados pessoais
              </p>
              <ReactInputMask
                className="input is-fullwidth"
                mask="(+55) 99 9 9999-9999"
                style={{
                  marginBottom: '1em',
                }}
                disabled
                value={
                  seller &&
                  seller.contactList.find((e) => e.type === 'cellPhone').value
                }
              />
              <ReactInputMask
                className="input is-fullwidth"
                mask="999.999.999-99"
                style={{
                  marginBottom: '1em',
                }}
                disabled
                value={
                  seller &&
                  seller.documentList.find((e) => e.type === 'cpf').value
                }
              />
              <input
                className="input is-fullwidth"
                style={{
                  marginBottom: '1em',
                }}
                disabled
                value={
                  seller &&
                  seller.contactList.find((e) => e.type === 'email').value
                }
              />
              <label
                style={{
                  color: 'grey',
                  fontSize: '0.8em',
                }}
              >
                Saldo Atual
              </label>
              <div style={{ display: 'flex' }}>
                <input
                  className="input is-fullwidth"
                  value={credits}
                  disabled
                />
                <button
                  onClick={handleModalUpdate}
                  className="button"
                  style={{ marginLeft: '0.5em' }}
                >
                  Editar saldo
                </button>
              </div>
            </WhiteBoxSeller>
          </LeftDiv>
          <SingleSell />
          <BoxBalance id="sellerPeriod">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                getSalesDates();
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: window.innerWidth < 380 ? 'column' : 'row',
                  justifyContent: 'space-between',
                  padding: 10,
                  height: window.innerWidth < 380 ? 115 : 'auto',
                }}
              >
                <span style={{ display: 'inline-flex' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Data inicial</label>
                    <ReactDatePicker
                      mainPosition="top"
                      fixMainPosition
                      className="input is-fullwidth is-small"
                      selected={initial}
                      onChange={(date) =>
                        setInitial(date.setHours('00', '00', '00'))
                      }
                      locale="pt-BR"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '0.5%',
                    }}
                  >
                    <label>Data final</label>
                    <ReactDatePicker
                      mainPosition="top"
                      fixMainPosition
                      style={{ zIndex: 1 }}
                      className="input is-fullwidth is-small"
                      selected={final}
                      onChange={(date) =>
                        setFinal(date.setHours('23', '59', '59'))
                      }
                      locale="pt-BR"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <button
                    style={{
                      display: 'flex',
                      alignSelf: 'flex-end',
                      marginLeft: '0.5em',
                    }}
                    className="button is-small"
                    type="submit"
                  >
                    Pesquisar
                  </button>
                </span>
                {!isLoading && (
                  <span
                    style={{ display: 'inline-flex', alignItems: 'flex-end' }}
                  >
                    <Typography variant="body1">
                      Qtd de títulos vendidos:
                    </Typography>
                    <Typography variant="body1" style={{ marginRight: 10 }}>
                      {quantity}
                    </Typography>
                    <Typography variant="body1">Vendas concluídas:</Typography>
                    <Typography variant="body1" style={{ marginRight: 10 }}>
                      {sales && sales.data.success === false
                        ? 0
                        : sales &&
                          sales.data.response
                            .filter((el) => el.status === 'issue')
                            .filter(
                              (el) => el.date >= initial && el.date <= final
                            ).length}
                    </Typography>
                    <Typography variant="body1">Vendas frustradas:</Typography>
                    <Typography variant="body1">
                      {sales && sales.data.success === false
                        ? 0
                        : sales &&
                          sales.data.response
                            .filter((el) => el.status === 'unpaid')
                            .filter(
                              (el) => el.date >= initial && el.date <= final
                            ).length}
                    </Typography>
                  </span>
                )}
              </div>
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Oval stroke="#EDB622" />
                </div>
              ) : (
                <SellerleadsTable
                  sellerList={sellerList}
                  initial={initial}
                  final={final}
                  filtering={filtering}
                />
              )}
            </form>
          </BoxBalance>
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
