import styled from 'styled-components';

export const XSmallPictureProfileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: xx-large;
  width: 5%;
  height: 0.5em;
  margin-top: 5%;
  @media screen and (max-width: 450px) {
    margin-top: 13%;
  }
`;

export const SmallPictureProfile = styled.div`
  color: #edb622;
  font-size: 1.3em;
  padding: 0.5rem;
  display: flex;
  @media screen and (max-width: 450px) {
    font-size: 0.9em;
  }
`;

export const SmallPictureProfileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8em;
  width: 1.7em;
  height: 1.7em;
  margin-right: 0.9em;
  border-radius: 5px;
  background-color: white;
  @media screen and (max-width: 450px) {
    width: 1em;
    height: 1em;
  }
`;

export const XSmallPictureProfile = styled.div`
  color: #edb622;
  font-size: 1.2em;
  display: flex;
`;

export const MediumPictureProfileBox = styled.div`
  display: flex;
  background-color: white;
  min-height: 15%;
  max-width: 95%;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 450px) {
    display: flex;
    background-color: white;
    height: 4.5em;
    width: 4.5em;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }
`;

export const MediumPictureProfile = styled.div`
  color: #edb622;
  font-size: 7em;
  display: flex;
  align-items: center;
  @media screen and (max-width: 450px) {
    color: #edb622;
    display: flex;
    align-items: center;
    font-size: 4.5em;
  }
`;

export const BigPictureProfileBox = styled.div`
  display: flex;
  background-color: white;
  height: 10%;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 450px) {
    display: flex;
    background-color: white;
    min-height: 20%;
    min-width: 20%;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }
`;

export const BigPictureProfile = styled.div`
  color: #edb622;
  font-size: 900%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 450px) {
    color: #edb622;
    display: flex;
    align-items: center;
    font-size: 400%;
  }
`;
