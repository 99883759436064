import axios from 'axios';
import { configJson } from '../../library/utils/firebaseUtils';
import { authToken } from '../APIs/token';

export function checkNotLinkedEstablishments(uId) {
  return new Promise((resolve, reject) => {
    authToken()
      .then((token) => {
        axios
          .get(
            configJson.API_HOST_V2 +
              `/sorteSaude/cards/checkNotLinkedEstablishments?uid=${uId}`,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
          .then((result) => {
            // console.log('RESULT  ==>', result.data.response);
            resolve(result.data.response);
          })
          .catch((error) => {
            // console.log('ERROR  ==>', error);
            reject(error.response.data.error); // Reject the promise with the error
          });
      })
      .catch((authError) => {
        reject(authError); // Reject the promise with the authentication error
      });
  });
}
