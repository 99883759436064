import axios from 'axios';
import { configJson } from '../../library/utils/firebaseUtils';
import { authToken } from './token';

export function createCampaign(payload, uId) {
  return new Promise((resolve, reject) => {
    authToken(uId).then((token) => {
      axios
        .post(
          configJson.API_HOST_V2 + '/brasilCap/draw',
          {
            ...payload,
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          // console.log("RESULTADO DO UPLOAD DE FOTO ==>", result);
          resolve(result.data);
        })
        .catch((error) => {
          // console.log("ERROR DO UPLOAD DE FOTO ==>", error);
          return reject(error);
        });
    });
  });
}

export function updateCampaign(payload, uId, idDraw) {
  return new Promise((resolve, reject) => {
    authToken(uId).then((token) => {
      axios
        .put(
          configJson.API_HOST_V2 + `/brasilCap/draw/${idDraw}`,
          {
            ...payload,
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          // console.log("RESULTADO DO UPLOAD DE FOTO ==>", result);
          resolve(result.data);
        })
        .catch((error) => {
          // console.log("ERROR DO UPLOAD DE FOTO ==>", error);
          return reject(error);
        });
    });
  });
}
