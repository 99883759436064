import React, { useContext, useEffect, useState } from 'react';

import {
  SideBarDesktopStyle,
  Button,
  ButtonContainer,
  SideBarLogo,
} from './style/SideBar';
import Logo from '../../assets/images/LogoBlueBG.png';
import Statistic from '../../assets/images/Grupo 11316.svg';
import Card from '../../assets/images/Grupo 11320.svg';
import Engrenagem from '../../assets/images/engrenagem.svg';
import EnvioCartelas from '../../assets/images/envioCartelas.svg';
import { MdLogout } from 'react-icons/md';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import { verifyModules } from '../../services/helpers';
import { getCheckSupervisor } from '../../services/APIs/getCheckSupervisor';

export default function SideBarDesktop() {
  const { logout } = useContext(AuthContext);
  const uidSupervisor = JSON.parse(localStorage.getItem('uid'));
  const [haveModules, setHaveModules] = useState([]);

  useEffect(() => {
    if (uidSupervisor !== null && uidSupervisor !== undefined) {
      getCheckSupervisor(uidSupervisor)
        .then((supervisor) => {
          setHaveModules(verifyModules(supervisor.modules));
        })
        .catch((error) => {
          console.log('Error de api getCheckSupervisor ==>', error);
        });
    }
  }, [uidSupervisor]);

  const location = useLocation();
  return (
    <>
      <SideBarDesktopStyle id="sidebar">
        <div>
          <SideBarLogo src={Logo} />
        </div>
        <ButtonContainer>
          <Link
            style={{
              fontDecoration: 'none',
            }}
            to="/dashboard"
          >
            <div
              className={
                location.pathname === '/dashboard' ? 'buttonMargin' : ''
              }
            >
              <Button>
                <img src={Statistic} alt="imagem gráfico" />
              </Button>
            </div>
          </Link>
          <Link
            style={{
              fontDecoration: 'none',
            }}
            to="/lista-supervisor-vendedor"
          >
            <div
              className={
                location.pathname === '/lista-supervisor-vendedor'
                  ? 'buttonMargin'
                  : ''
              }
            >
              <Button>
                <img src={Card} alt="imagem card" />
              </Button>
            </div>
          </Link>
          {localStorage.getItem('role') === '"manager"' && (
            <>
              <Link
                style={{
                  fontDecoration: 'none',
                }}
                to="/administracao"
              >
                <div
                  className={
                    location.pathname === '/administracao' ? 'buttonMargin' : ''
                  }
                >
                  <Button>
                    <img src={Engrenagem} alt="Imagem de engrenagem" />
                  </Button>
                </div>
              </Link>
              {haveModules ? (
                <Link
                  style={{
                    fontDecoration: 'none',
                  }}
                  to="/operacao-cartela"
                >
                  <div
                    className={
                      location.pathname === '/operacao-cartela'
                        ? 'buttonMargin'
                        : ''
                    }
                  >
                    <Button>
                      <img
                        style={{ width: '4rem' }}
                        src={EnvioCartelas}
                        alt="Imagem de carta"
                      />
                    </Button>
                  </div>
                </Link>
              ) : null}
            </>
          )}

          <Button style={{ fontSize: '3em' }} onClick={logout}>
            <MdLogout />
          </Button>
        </ButtonContainer>
      </SideBarDesktopStyle>
    </>
  );
}
