import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';

// STYLES
import {
  MediumPictureProfile,
  MediumPictureProfileBox,
} from '../../../styled/PictureProfiel';
import {
  GenericHeader,
  TextBox,
} from '../../SellerNSupervisor/style/SellerNSuperStyle';
import { NewProfileBox } from '../../MyProfile/style/MyPofileStyle';
import { Content, GenericText, InsideContent } from '../../../styled/generic';
import { GiCardBurn, GiCardboardBoxClosed } from 'react-icons/gi';
import { ContainerSendType } from '../styles/DistribuitionCartelasStyle';

// COMPONENTS
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import { DistribuitionCartelaContext } from '../../../context/distribuitionCartela';

export function SendType() {
  const location = useLocation();
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content" theme={location.pathname}>
          {/* Escolhe o tipo de reserva */}
          <ContainerSendType>
            <Link
              to={`/operacao-cartela/gerenciar-series/reservar-cartelas/tipo-reserva/lote/${
                serie === '' ? serieUrl : serie
              }`}
            >
              <NewProfileBox style={{ minWidth: '25em' }}>
                <MediumPictureProfileBox>
                  <MediumPictureProfile>
                    <GiCardboardBoxClosed />
                  </MediumPictureProfile>
                </MediumPictureProfileBox>
                <TextBox>
                  <GenericHeader style={{ fontWeight: '700' }}>
                    Reserva em lote
                  </GenericHeader>
                  <GenericText>
                    Utilize essa opção para reservas de lotes completos.
                  </GenericText>
                </TextBox>
              </NewProfileBox>
            </Link>
            <Link
              to={`/operacao-cartela/gerenciar-series/reservar-cartelas/tipo-reserva/fracionada/${
                serie === '' ? serieUrl : serie
              }`}
            >
              <NewProfileBox style={{ minWidth: '25em' }}>
                <MediumPictureProfileBox>
                  <MediumPictureProfile>
                    <GiCardBurn />
                  </MediumPictureProfile>
                </MediumPictureProfileBox>
                <TextBox>
                  <GenericHeader style={{ fontWeight: '700' }}>
                    Reserva fracionada
                  </GenericHeader>
                  <GenericText>
                    Utilize essa opção para reservas inferiores a um lote.
                  </GenericText>
                </TextBox>
              </NewProfileBox>
            </Link>
          </ContainerSendType>
        </InsideContent>
      </Content>
    </>
  );
}
