import styled from "styled-components";

export const Header = styled.header`
  /* width: 90%; */
  display: flex;
  height: 5em;
  align-items: center;
  margin-top: 1em;
  border-bottom: 1px lightgray solid;
  justify-content: space-between;
  margin-left: 2em;
  @media screen and (max-width: 450px) {
    justify-content: space-around;
    position: fixed;
    top: 0;
    background-color: #2d70b9;
    width: 100vw;
    margin: 0;
    margin-left: 0;
    margin-right: 0;
    z-index: 9999;
  }
`;

export const HeaderTitleInside = styled.div`
  font-size: 2.5em;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const HeaderTitleOutside = styled.div`
  max-width: 90vw;
  height: fit-content;
  font-size: 1.2em;
  font-weight: 600;
  margin-left: 5%;
  border-bottom: 1px lightgray solid;
  margin-top: 12vh;
  @media screen and (min-width: 450px) {
    display: none;
  }
`;

export const HeaderProfile = styled.div`
  display: flex;
  @media screen and (max-width: 450px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ProfileData = styled.div`
  font-size: 0.9em;
  display: flex;
  margin-top: 4%;
  flex-direction: column;
  margin-left: 3%;
  @media screen and (max-width: 450px) {
    //font-size: 0.9em;
    color: white;
    margin-top: 5%;
    //padding: 0;
    margin-left: 5%;
  }
`;

export const LogoHeader = styled.img`
  width: 107px;
  padding-left: 10px;
  @media screen and (min-width: 450px) {
    display: none;
  }
`;
