// STYLES
import { ButtonDistribuitionTable } from '../Tables/style/EditButtomTableStyles';
import { GrStatusUnknown } from 'react-icons/gr';
import Tooltip from '@mui/material/Tooltip';
import { convertVisualDateForTimestamp } from '../../services/helpers';

export const SeeStatusSeriesButton = ({
  openModal,
  serieInfoStatus,
  serieEndDate,
  seriesFinished,
  status,
}) => {
  return status === 'lossCompleted' &&
    seriesFinished(convertVisualDateForTimestamp(serieEndDate)) ? (
    <Tooltip title="Ver status da série" placement="top">
      <ButtonDistribuitionTable onClick={() => openModal('seeStatusSerie', serieInfoStatus)}>
        <GrStatusUnknown size={20} style={{ color: '#f96a03' }} />
      </ButtonDistribuitionTable>
    </Tooltip>
  ) : null;
};

