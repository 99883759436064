import axios from "axios";
import { configJson } from "../library/utils/firebaseUtils";

export const api = axios.create({
  baseURL: configJson.API_HOST_V2,
});

export const createSeller = async (uidSeller, uidSupervisor, token) => {
  try {
    const response = await api.post(
      `/brasilCap/createSellerUser/?uidSeller=${uidSeller}&uidSupervisor=${uidSupervisor}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createSupervisor = async (uidSupervisor, role, token) => {
  try {
    const response = await api.post(
      `/brasilCap/createSupervisor/?uidSupervisor=${uidSupervisor}&role=${role}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createAffiliate = async (token, uid, 
  affiliations = {
    [configJson.partnerNameBBCap]: {
      status: true,
      commission: 0.1,
    }
  } 
) => {
  try {
    const response = await api.post(
      `/affiliations/new`,
      {uid, affiliations},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (AxiosError) {
    return AxiosError.response;
  }
};
