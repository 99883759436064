import axios from 'axios';
import { configJson } from '../../library/utils/firebaseUtils';
import { decrypt, encrypt } from '../../helpers/crypt';
import { validateRegistrationDate } from '../../helpers/validateRegistrationDate';
import { hashTriibo1 } from '../helpers';

function getSecurityCode() {
  return new Promise(async (resolve, reject) => {
    let auth = window.btoa(configJson.username + ':' + configJson.password);

    const config = {
      method: 'get',
      url: configJson.API_HOST_V2 + '/auth',
      headers: {
        Authorization: 'Basic ' + auth,
      },
    };

    axios(config)
      .then(function (sc) {
        let decry = JSON.parse(decrypt(sc.data.securityCode, configJson.SECURITYCODE_KEY));
        let result = { code: decry.code * 3 };
        result = encrypt(JSON.stringify(result), configJson.SECURITYCODE_KEY);
        resolve(result);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function authTokenWithSC(uId) {
  const sc = await getSecurityCode();
  const auth = window.btoa(configJson.username + ':' + configJson.password);

  return new Promise(async (resolve, reject) => {
    const config = {
      method: 'post',
      url: configJson.API_HOST_V2 + '/auth',
      headers: {
        Authorization: 'Basic ' + auth,
        securityCode: sc,
        uid: uId,
      },
    };

    axios(config)
      .then(function (response) {
        localStorage.setItem(
          'taas',
          JSON.stringify({
            token: response.data.token,
            date: Date.now(),
            uId,
          })
        );
        resolve(response.data.token);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function authToken(uId) {
  return new Promise(async (resolve, reject) => {
    const token = JSON.parse(localStorage.getItem('taas'));
    let auth = window.btoa(configJson.username + ':' + configJson.password);

    const config = {
      method: 'post',
      url: configJson.API_HOST_V2 + '/auth',
      headers: {
        Authorization: 'Basic ' + auth,
        uid: uId && uId,
      },
    };

    const validateDate = validateRegistrationDate(token?.date, 15);

    if (token === null || token === undefined || !validateDate || uId !== token?.uId) {
      axios(config)
        .then(function (response) {
          localStorage.setItem(
            'taas',
            JSON.stringify({
              token: response.data.token,
              date: Date.now(),
              uId,
            })
          );
          resolve(response.data.token);
        })
        .catch(function (error) {
          reject(error);
        });
    } else {
      resolve(token.token);
    }
  });
}

export const getToken = async () => {
  await getChannelTokenUidNull(null);
};

export function getChannelToken_v1(uId) {
  return new Promise((resolve, reject) => {
    //capturando dados do Storage
    const channelObj = JSON.parse(localStorage.getItem('channelToken'));

    const valideDate = validateRegistrationDate(
      channelObj === null ? null : channelObj.registrationDate
    );
    const channelUId = channelObj === null ? null : channelObj.uId;

    //token não existe no storage
    if (
      channelObj === null ||
      channelObj.id === null ||
      channelObj.id === '' ||
      channelObj.id === undefined ||
      !valideDate ||
      channelUId === undefined ||
      channelUId === null ||
      channelUId !== uId
    ) {
      const passPhrase = generateRandom(10);

      function generateRandom(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

      const challenge = hashTriibo1(configJson.apiToken, passPhrase, configJson.channeltoken);

      axios
        .post(configJson.API_HOST_V1 + 'getChannelToken_v1', {
          triiboHeader: {
            apiToken: configJson.apiToken,
            channelGroup: configJson.channelGroup,
            channelName: configJson.channelName,
            uId: uId,
            passPhrase: passPhrase,
            challenge: challenge,
            channelId: configJson.channelId,
          },
        })
        .then((result) => {
          localStorage.setItem(
            'channelToken',
            '{ "id":"' +
              challenge +
              '", "registrationDate":' +
              Date.now() +
              ', "uId":"' +
              uId +
              '" }'
          );

          resolve(challenge);
        })
        .catch((error) => {
          reject(error);
        });
    }
    //token existe no storage
    else {
      resolve(channelObj.id);
    }
  });
}

export function getChannelTokenUidNull() {
  return new Promise((resolve, reject) => {
    //capturando dados do Storage
    const channelObj = JSON.parse(localStorage.getItem('channelTokenUidNull'));

    const valideDate = validateRegistrationDate(
      channelObj === null ? null : channelObj.registrationDate
    );

    //token não existe no storage
    if (
      channelObj === null ||
      channelObj.id === null ||
      channelObj.id === '' ||
      channelObj.id === undefined ||
      !valideDate
    ) {
      const passPhrase = generateRandom(10);

      function generateRandom(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

      const challenge = hashTriibo1(configJson.apiToken, passPhrase, configJson.channeltoken);

      axios
        .post(configJson.API_HOST_V1 + 'getChannelToken_v1', {
          triiboHeader: {
            apiToken: configJson.apiToken,
            channelGroup: configJson.channelGroup,
            channelName: configJson.channelName,
            uId: null,
            passPhrase: passPhrase,
            challenge: challenge,
            channelId: configJson.channelId,
          },
        })
        .then((result) => {
          localStorage.setItem(
            'channelTokenUidNull',
            '{ "id":"' + challenge + '", "registrationDate":' + Date.now() + '}'
          );

          resolve(challenge);
        })
        .catch((error) => {
          reject(error);
        });
    }
    //token existe no storage
    else {
      resolve(channelObj.id);
    }
  });
}

