import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Content, InsideContent } from '../styled/generic';
import 'react-datepicker/dist/react-datepicker.css';
import Footer from '../components/Footer/Footer';
import HeaderComp from '../components/Header/HeaderComp';
import SideBarDesktop from '../components/Sidebar/SideBarComp';
import {
  MediumPictureProfile,
  MediumPictureProfileBox,
} from '../styled/PictureProfiel';
import { BsPersonFill } from 'react-icons/bs';
import {
  LeftDiv,
  ProfileBoxDesktop,
  ProfileBoxMobile,
  PictureBox,
  DataBox,
  ButtonBox,
  Role,
  NameNRole,
  Name,
  WhiteBoxSeller,
} from '../pages/SellerPage/style/SellerPageStyle';
import { RegisterContext } from '../context/register';
import ReactInputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';

import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { authToken } from '../services/APIs/token';
import { getAffiliation } from '../services/getAll';
import { configJson } from '../library/utils/firebaseUtils';
import { decrypt } from '../helpers/crypt';
import { updateAffiliation } from '../services/updateAffiliation';
registerLocale('pt-BR', ptBR);

export default function AffiliatedPage() {
  const uid = JSON.parse(localStorage.getItem('uid'));
  const { handleModal } = useContext(RegisterContext);
  const { id } = useParams();
  const [status, setStatus] = useState(false);
  const [isLoadingAffiliate, setIsLoadingAffiliate] = useState(true);
  const [affiliated, setAffiliated] = useState();

  const getAffiliate = useCallback(async () => {
    try {
      const token = await authToken(uid);
      const affiliateName = await getAffiliation(token, id);
      setAffiliated(affiliateName);
      setStatus(affiliateName.affiliation.status);
    } catch (err) {
      console.log(err);
    }
    setIsLoadingAffiliate(false);
  }, [id, uid]);

  useEffect(() => {
    getAffiliate();
  }, [getAffiliate]);

  const toggleSeller = async () => {
    const token = await authToken();
    if (status) {
      const isUpdateSuccess = await updateAffiliation(token, uid, false);
      if (isUpdateSuccess.data.success) setStatus(false);
    } else {
      const isUpdateSuccess = await updateAffiliation(token, uid, true);
      if (isUpdateSuccess.data.success) {
        setStatus(true);
        handleModal();
      }
    }
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          {isLoadingAffiliate ? (
            <Oval stroke="#EDB622" />
          ) : (
            <>
              <LeftDiv>
                <ProfileBoxDesktop id={status ? '' : 'inactive'}>
                  <div>
                    <PictureBox>
                      <MediumPictureProfileBox>
                        <MediumPictureProfile>
                          <BsPersonFill id={status ? '' : 'inactivePic'} />
                        </MediumPictureProfile>
                      </MediumPictureProfileBox>
                    </PictureBox>
                  </div>
                  <DataBox>
                    <NameNRole>
                      <Role>Afiliado</Role>
                      <Name>{affiliated && affiliated.name}</Name>
                    </NameNRole>
                  </DataBox>
                  <ButtonBox className="column">
                    <button
                      style={{
                        fontSize: '0.8em',
                        color: '#2d70b9',
                        width: 'fit-content',
                      }}
                      className="button"
                      onClick={toggleSeller}
                      id={status ? '' : 'inactiveButton'}
                    >
                      {status ? 'Desativar afiliado' : 'Ativar afiliado'}
                    </button>
                  </ButtonBox>
                </ProfileBoxDesktop>
                {window.screen.width <= 450 && (
                  <ProfileBoxMobile
                    className="columns"
                    id={status ? '' : 'inactive'}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <PictureBox>
                          <MediumPictureProfileBox>
                            <MediumPictureProfile>
                              <BsPersonFill id={status ? '' : 'inactivePic'} />
                            </MediumPictureProfile>
                          </MediumPictureProfileBox>
                        </PictureBox>
                        <DataBox className="column">
                          <NameNRole>
                            <Role>vendedor</Role>
                            <Name>{affiliated && affiliated.name}</Name>
                          </NameNRole>
                        </DataBox>
                      </div>
                      <button
                        style={{
                          fontSize: '0.8em',
                          color: '#2d70b9',
                          width: 'fit-content',
                        }}
                        className="button"
                        onClick={toggleSeller}
                        id={status ? '' : 'inactiveButton'}
                      >
                        {status ? 'Desativar afiliado' : 'Ativar afiliado'}
                      </button>
                    </div>
                  </ProfileBoxMobile>
                )}
                <WhiteBoxSeller className="box">
                  <p
                    style={{
                      color: 'grey',
                      fontSize: '0.8em',
                    }}
                  >
                    Dados pessoais
                  </p>
                  <ReactInputMask
                    className="input is-fullwidth"
                    mask="(+55) 99 9 9999-9999"
                    style={{
                      marginBottom: '1em',
                    }}
                    disabled
                    value={localStorage.getItem('affiliatePhone')}
                  />
                  <ReactInputMask
                    className="input is-fullwidth"
                    mask="999.999.999-99"
                    style={{
                      marginBottom: '1em',
                    }}
                    disabled
                    value={
                      affiliated &&
                      decrypt(affiliated.cpf, configJson.CRYPTO_KEY)
                    }
                  />
                  <input
                    className="input is-fullwidth"
                    style={{
                      marginBottom: '1em',
                    }}
                    disabled
                    value={affiliated && affiliated.email}
                  />
                </WhiteBoxSeller>
              </LeftDiv>
            </>
          )}
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
