import "react-toastify/dist/ReactToastify.css";
import ReactInputMask from "react-input-mask";
import { Button } from "../../pages/Login/style/Login";
import React from 'react';

export default function InputPhone({ handleSubmit, setPhoneNumber, phoneNumber }) {
  return (
    <>
      <form onSubmit={(e) => handleSubmit(e, true)}>
        <ReactInputMask
          className="input is-medium is-fullwidth"
          maskChar=""
          alwaysShowMask={false}
          type="text"
          mask="99 99999-9999"
          placeholder="11 99999-9999"
          value={phoneNumber}
          onChange={(e) => {
            const cleaned = e.target.value.replace(/[^+\d]/g, "");
            setPhoneNumber(cleaned);
          }}
        />
        <Button className="button is-fullwidth" type="submit">
          Entrar
        </Button>
      </form>
    </>
  );
}
