import { useContext, useEffect, useRef, useState } from 'react';

//Styles
import { Content, InsideContent } from '../../styled/generic';
import {
  BoxForm,
  ButtonSave,
  ButtonUploadImage,
  ContainerBasicInfos,
  ContainerUploadImage,
  FormGroupDates,
  FormGroupUploads,
  GeneralForm,
  GeneralFormBanners,
  InputForm,
  PreviewImageBack,
  PreviewImageMainThumb,
  PreviewImageMobile,
} from './style/CreateRaffle';

//LIBS
import { toast } from 'react-toastify';

//Components
import Footer from '../../components/Footer/Footer';
import HeaderComp from '../../components/Header/HeaderComp';
import SideBarDesktop from '../../components/Sidebar/SideBarComp';
import {
  convertToMillis,
  convertMilliForMonthAndYear,
  convertMillisecondsToDateTime,
} from '../../services/helpers';

//APIS
import {
  createCampaign,
  updateCampaign,
} from '../../services/APIs/generateCampaign';
import { CampaingnContext } from '../../context/campaigns';
import { uploadFiles } from '../../services/APIs/uploadFiles';
import { CircularProgress } from '@mui/material';

export function CreateRaffle() {
  const { campaigns } = useContext(CampaingnContext);
  const { pathname } = document.location;
  const uId = JSON.parse(localStorage.getItem('uid'));
  const handleFileInputBannerDesktop = useRef(null);
  const handleFileInputBannerMobile = useRef(null);
  const handleFileInputImagePromo = useRef(null);
  const [idCampaign] = useState(pathname.split('/')[3]);
  const [campaignInfosForEdit, setCampaignInfosForEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignName, setCampaignName] = useState('');
  const [dates, setDates] = useState({
    startDate: '',
    drawDate: '',
    endDate: '',
  });
  const [images, setImages] = useState({
    bannerDesktop: '',
    bannerMobile: '',
    imagePromo: '',
  });
  const [imageTypes, setImageTypes] = useState({
    mimeDesktop: '',
    mimeMobile: '',
    mimeImagePromo: '',
  });
  const [filesForUpload, setFilesForUpload] = useState({
    bannerDesktop: '',
    bannerMobile: '',
    imagePromo: '',
    bannerDesktopBuffer: '',
    bannerMobileBuffer: '',
    imagePromoBuffer: '',
  });
  const months = {
    '01': 'jan',
    '02': 'feb',
    '03': 'mar',
    '04': 'apr',
    '05': 'may',
    '06': 'jun',
    '07': 'jul',
    '08': 'aug',
    '09': 'sep',
    '010': 'oct',
    '011': 'nov',
    '012': 'dec',
  };

  useEffect(() => {
    if (idCampaign !== undefined) {
      let campaignEdit = null;
      setIsEdit(true);

      campaigns.forEach((campaign) => {
        if (campaign.id === idCampaign) {
          campaignEdit = campaign;
        }
      });
      setCampaignInfosForEdit(campaignEdit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCampaign]);

  useEffect(() => {
    if (isEdit) {
      setCampaignName(campaignInfosForEdit.name);
      setImages({
        ...images,
        bannerDesktop: campaignInfosForEdit.bannerDesktop,
        bannerMobile: campaignInfosForEdit.bannerMobile,
        imagePromo: campaignInfosForEdit.imagePromo,
      });
      setDates({
        ...dates,
        startDate: convertMillisecondsToDateTime(
          campaignInfosForEdit.startDateEdit
        ),
        drawDate: convertMillisecondsToDateTime(
          campaignInfosForEdit.drawDateEdit
        ),
        endDate: convertMillisecondsToDateTime(
          campaignInfosForEdit.endDateEdit
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  function handleDates(type) {
    return function (event) {
      switch (type) {
        case 'startDate':
          setDates({ ...dates, startDate: event.target.value });
          break;
        case 'drawDate':
          setDates({ ...dates, drawDate: event.target.value });
          break;
        case 'endDate':
          setDates({ ...dates, endDate: event.target.value });
          break;
        default:
        //Se nada for como previsto.
      }
    };
  }

  function handleClick(uploadType) {
    if (uploadType === 'bannerDesktop') {
      handleFileInputBannerDesktop.current.click();
    }
    if (uploadType === 'bannerMobile') {
      handleFileInputBannerMobile.current.click();
    }
    if (uploadType === 'imagePromo') {
      handleFileInputImagePromo.current.click();
    }
  }

  function handleChange(event, uploadType) {
    let files;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }

    if (uploadType === 'bannerDesktop') {
      setImages({
        ...images,
        bannerDesktop: files[0].name.replace(/ /g, '_') + '--desktop',
      });
      setImageTypes({
        ...imageTypes,
        mimeDesktop: files[0].type,
      });
    }
    if (uploadType === 'bannerMobile') {
      setImages({
        ...images,
        bannerMobile: files[0].name.replace(/ /g, '_') + '--mobile',
      });
      setImageTypes({
        ...imageTypes,
        mimeMobile: files[0].type,
      });
    }
    if (uploadType === 'imagePromo') {
      setImages({
        ...images,
        imagePromo: files[0].name.replace(/ /g, '_') + '--imagePromo',
      });
      setImageTypes({
        ...imageTypes,
        mimeImagePromo: files[0].type,
      });
    }

    const reader = new FileReader();
    reader.onload = () => {
      const binaryString = reader.result;
      const base64String = btoa(binaryString);
      const imageExibition = `data:${files[0].type};base64,${base64String}`;

      if (uploadType === 'bannerDesktop') {
        setFilesForUpload({
          ...filesForUpload,
          bannerDesktop: imageExibition,
          bannerDesktopBuffer: base64String,
        });
      }

      if (uploadType === 'bannerMobile') {
        setFilesForUpload({
          ...filesForUpload,
          bannerMobile: imageExibition,
          bannerMobileBuffer: base64String,
        });
      }

      if (uploadType === 'imagePromo') {
        setFilesForUpload({
          ...filesForUpload,
          imagePromo: imageExibition,
          imagePromoBuffer: base64String,
        });
      }
    };
    reader.readAsBinaryString(files[0]);
  }

  function handleCampaignName(event) {
    setCampaignName(event.target.value);
  }

  async function generateCampaign() {
    setLoading(true);
    const startDateConverter =
      dates.startDate !== '' && convertToMillis(dates.startDate);
    const endDateConverter =
      dates.endDate !== '' && convertToMillis(dates.endDate);
    const drawDateConverter =
      dates.drawDate !== '' && convertToMillis(dates.drawDate);
    const mesAno =
      startDateConverter !== '' &&
      convertMilliForMonthAndYear(startDateConverter);

    if (
      campaignName !== '' &&
      images.bannerDesktop !== '' &&
      images.bannerMobile !== '' &&
      images.imagePromo !== '' &&
      dates.startDate !== '' &&
      dates.endDate !== '' &&
      dates.drawDate !== ''
    ) {
      if (
        startDateConverter > drawDateConverter &&
        startDateConverter > endDateConverter
      ) {
        toast.warning(
          'A data inicial da campanha deve ser anterior as datas do sorteio e de encerramento da campanha.'
        );
      } else if (drawDateConverter < endDateConverter) {
        //
        toast.warning(
          'A data do sorteio deve ser posterior a data de encerramento da campanha.'
        );
      } else {
        const promises = [];
        const informImgs = [
          {
            mimeType: imageTypes.mimeDesktop,
            fileName: images.bannerDesktop,
            buffer: filesForUpload.bannerDesktopBuffer,
          },
          {
            mimeType: imageTypes.mimeMobile,
            fileName: images.bannerMobile,
            buffer: filesForUpload.bannerMobileBuffer,
          },
          {
            mimeType: imageTypes.mimeImagePromo,
            fileName: images.imagePromo,
            buffer: filesForUpload.imagePromoBuffer,
          },
        ];

        const payload = {
          drawId: '-' + months[mesAno.mes] + (mesAno.ano % 100),
          name: campaignName,
          startDate: startDateConverter,
          endDate: endDateConverter,
          drawDate: drawDateConverter,
        };

        if (!isEdit) {
          informImgs.forEach((info) => {
            promises.push(
              uploadFiles('brasilcap-banners-campanhas', info, uId)
            );
          });

          Promise.all(promises)
            .then((results) => {
              toast.info('Imagens adicionadas ao banco de dados!');
              results.forEach((response) => {
                if (response.data.result.includes('--desktop')) {
                  payload.bannerDesktop = response.data.result;
                } else if (response.data.result.includes('--mobile')) {
                  payload.bannerMobile = response.data.result;
                } else if (response.data.result.includes('--imagePromo')) {
                  payload.imagePromo = response.data.result;
                }
              });

              createCampaign(payload, uId)
                .then((createSuccessfull) => {
                  if (!createSuccessfull.success) {
                    toast.error(createSuccessfull.error);
                  } else {
                    setLoading(false);
                    toast.success('Campanha criada com sucesso!');
                    setTimeout(() => {
                      return (window.location.href =
                        window.location.hash +
                        '/administracao/lista-de-sorteios');
                    }, 1600);
                  }
                })
                .catch((error) => {
                  console.log('Erro na criação da campanha', error);
                });
            })
            .catch((error) => {
              console.log('Erro na chamada da API', error);
            });
        } else {
          // Verifique se há alguma imagem sem o mimeType ou buffer preenchidos
          const hasValidImages = informImgs.some(
            (info) => info.mimeType !== '' || info.buffer !== ''
          );
          // Crie uma matriz de promessas para o upload das imagens
          const uploadPromises = informImgs.map((info) => {
            if (info.mimeType !== '' && info.buffer !== '') {
              return uploadFiles('brasilcap-banners-campanhas', info, uId);
            }
            return null;
          });

          // Executa todas as promessas de upload em paralelo
          Promise.all(uploadPromises)
            .then((results) => {
              toast.info('Imagens adicionadas ao banco de dados!');

              // Inicializa o payload com as informações da campanha
              const payload = {
                name: campaignName,
                startDate: startDateConverter,
                endDate: endDateConverter,
                drawDate: drawDateConverter,
                bannerDesktop:
                  campaignInfosForEdit && campaignInfosForEdit.bannerDesktop,
                bannerMobile:
                  campaignInfosForEdit && campaignInfosForEdit.bannerMobile,
                imagePromo:
                  campaignInfosForEdit && campaignInfosForEdit.imagePromo,
              };

              if (hasValidImages) {
                // Processa os resultados do upload e atualiza o payload
                results.forEach((response) => {
                  if (response !== undefined) {
                    if (response.data.result.includes('--desktop')) {
                      payload.bannerDesktop = response.data.result;
                    } else if (response.data.result.includes('--mobile')) {
                      payload.bannerMobile = response.data.result;
                    } else if (response.data.result.includes('--imagePromo')) {
                      payload.imagePromo = response.data.result;
                    }
                  }
                });
              }

              // Atualiza a campanha apenas uma vez, incluindo as informações das imagens
              updateCampaign(payload, uId, idCampaign)
                .then((editSuccessfull) => {
                  if (!editSuccessfull.success) {
                    toast.error(editSuccessfull.error);
                  } else {
                    setLoading(false);
                    toast.success('Campanha editada com sucesso!');
                    setTimeout(() => {
                      return (window.location.href =
                        window.location.hash +
                        '/administracao/lista-de-sorteios');
                    }, 1600);
                  }
                })
                .catch((error) => {
                  console.log('Erro na edição da campanha', error);
                });
            })
            .catch((error) => {
              console.log('Erro na chamada da API', error);
            });
        }
      }
    } else {
      toast.warning('Verifique se todos os campos estão preenchindos.');
    }
  }

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <GeneralForm>
            <ContainerBasicInfos>
              <BoxForm>
                <InputForm
                  fullWidth
                  required
                  label="Nome da campanha"
                  id="outlined-required"
                  onChange={handleCampaignName}
                  value={campaignName}
                  placeholder="EX: Campanha de Dezembro 2099"
                />
                <FormGroupDates>
                  <InputForm
                    id="datetime-local"
                    label="Data inicial de participação"
                    type="datetime-local"
                    value={dates.startDate}
                    onChange={handleDates('startDate')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    color="primary"
                  />
                  <InputForm
                    id="datetime-local"
                    label="Data final de participação"
                    type="datetime-local"
                    value={dates.endDate}
                    onChange={handleDates('endDate')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    color="primary"
                  />
                  <InputForm
                    id="datetime-local"
                    label="Data do sorteio"
                    type="datetime-local"
                    value={dates.drawDate}
                    onChange={handleDates('drawDate')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    color="primary"
                  />
                </FormGroupDates>
              </BoxForm>
            </ContainerBasicInfos>
            <GeneralFormBanners>
              <ContainerUploadImage>
                <FormGroupUploads
                  heightContainer={filesForUpload.bannerDesktop}
                >
                  <ButtonUploadImage
                    style={{
                      marginBottom: `${
                        filesForUpload.bannerDesktop === '' ? 0 : '2.5rem'
                      }`,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick('bannerDesktop');
                    }}
                  >
                    Banner Desktop
                  </ButtonUploadImage>
                  {!isEdit ? (
                    filesForUpload.bannerDesktop !== '' ? (
                      <PreviewImageMainThumb
                        src={filesForUpload.bannerDesktop}
                      />
                    ) : null
                  ) : filesForUpload.bannerDesktop !== '' ? (
                    <PreviewImageMainThumb src={filesForUpload.bannerDesktop} />
                  ) : (
                    <PreviewImageMainThumb
                      src={campaignInfosForEdit.bannerDesktop}
                    />
                  )}
                </FormGroupUploads>
              </ContainerUploadImage>
              <ContainerUploadImage>
                <FormGroupUploads>
                  <ButtonUploadImage
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick('bannerMobile');
                    }}
                  >
                    Banner Mobile
                  </ButtonUploadImage>
                  {!isEdit ? (
                    filesForUpload.bannerMobile !== '' ? (
                      <PreviewImageMobile src={filesForUpload.bannerMobile} />
                    ) : null
                  ) : filesForUpload.bannerMobile !== '' ? (
                    <PreviewImageMobile src={filesForUpload.bannerMobile} />
                  ) : (
                    <PreviewImageMobile
                      src={campaignInfosForEdit.bannerMobile}
                    />
                  )}
                </FormGroupUploads>
              </ContainerUploadImage>
              <ContainerUploadImage>
                <FormGroupUploads>
                  <ButtonUploadImage
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick('imagePromo');
                    }}
                  >
                    Imagem da promoção
                  </ButtonUploadImage>
                  {!isEdit ? (
                    filesForUpload.imagePromo !== '' ? (
                      <PreviewImageBack src={filesForUpload.imagePromo} />
                    ) : null
                  ) : filesForUpload.imagePromo !== '' ? (
                    <PreviewImageBack src={filesForUpload.imagePromo} />
                  ) : (
                    <PreviewImageBack src={campaignInfosForEdit.imagePromo} />
                  )}
                </FormGroupUploads>
              </ContainerUploadImage>
            </GeneralFormBanners>
            <ButtonSave onClick={generateCampaign}>
              {loading ? (
                <CircularProgress size="1.4rem" style={{ color: '#fff' }} />
              ) : (
                'Salvar'
              )}
            </ButtonSave>

            <input
              type="file"
              ref={handleFileInputBannerDesktop}
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => {
                if (e.target.files[0].size > 1048576 * 10) {
                  alert('Arquivo excedeu 10MB');
                  return;
                }
                handleChange(e, 'bannerDesktop');
              }}
              style={{ visibility: 'hidden' }}
            />
            <input
              type="file"
              ref={handleFileInputBannerMobile}
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => {
                if (e.target.files[0].size > 1048576 * 10) {
                  alert('Arquivo excedeu 10MB');
                  return;
                }
                handleChange(e, 'bannerMobile');
              }}
              style={{ visibility: 'hidden' }}
            />
            <input
              type="file"
              ref={handleFileInputImagePromo}
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => {
                if (e.target.files[0].size > 1048576 * 10) {
                  alert('Arquivo excedeu 10MB');
                  return;
                }
                handleChange(e, 'imagePromo');
              }}
              style={{ visibility: 'hidden' }}
            />
          </GeneralForm>
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
