// STYLES
import { ButtonDistribuitionTable } from '../Tables/style/EditButtomTableStyles';
import { FaRegCalendarTimes } from 'react-icons/fa';
import Tooltip from '@mui/material/Tooltip';

// OTHERS
import { convertVisualDateForTimestamp } from '../../services/helpers';

export const FinishSerieButton = ({
  openModal,
  serie,
  stopSellingDate,
  seriesFinished,
  status,
}) => {
  return status === 'processedSuccess' &&
    seriesFinished(convertVisualDateForTimestamp(stopSellingDate)) ? (
    <Tooltip title="Finalizar série" placement="top">
      <ButtonDistribuitionTable onClick={() => openModal('finishSerie', serie)}>
        <FaRegCalendarTimes size={20} style={{ color: '#E74C3C' }} />
      </ButtonDistribuitionTable>
    </Tooltip>
  ) : null;
};

