import axios from 'axios';
import { configJson } from '../../library/utils/firebaseUtils';
import { authToken } from './token';

export function distributionCards(uId, payload) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .post(
            configJson.API_HOST_V2 + `/sorteSaude/cards/distribution`,
            {
              ...payload,
            },
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
          .then((result) => {
            // console.log('RESULT  ==>', result.data.response);
            resolve(result.data);
          })
          .catch((error) => {
            // console.log('ERROR  ==>', error);
            reject(error.response.data.error); // Reject the promise with the error
          });
      })
      .catch((authError) => {
        reject(authError); // Reject the promise with the authentication error
      });
  });
}
