import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';

// STYLES
import {
  DivModal,
  ContainerModal,
  ContainerSelects,
  ContentSelectAccredit,
  Container,
  QuantityValue,
  SendCartelaButton,
  TableDataInfo,
  SearchInputContainer,
  SearchInput,
  SearchIcon,
  ContentNoHave,
} from './style/ModalStyle';
import { DividerTable } from '../../pages/DistribuitionCartelas/styles/DistribuitionCartelasStyle';
import { HiSearchCircle } from 'react-icons/hi';
import { CircularProgress } from '@mui/material';

// APIS
import { listAccrediteds } from '../../services/APIs/listAccrediteds';
import { distributionCards } from '../../services/APIs/distributionCards';

export const ModalConfirmShipping = ({
  openUpdate,
  handleModalUpdate,
  quantity,
}) => {
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);
  const { pathname } = document.location;
  const fractioned = pathname.split('/')[4] === 'cartelas' ? true : false;
  const uId = JSON.parse(localStorage.getItem('uid'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedOptionAccredited, setSelectedOptionAccredited] =
    useState(null);
  const [accredited, setAccredited] = useState([]);

  // BUSCAR OS CREDENCIADOSS
  useEffect(() => {
    setLoading(true);
    listAccrediteds(uId)
      .then((accredit) => {
        const data = accredit.response
          .map((info) => {
            const item = Object.values(info)[0];
            if (item.status) {
              return {
                name: item.name,
                address: item.address,
                establishments: item.establishments,
                id: Object.keys(info)[0],
              };
            }
            return null;
          })
          .filter((item) => item !== null);

        setAccredited(data);
      })
      .catch(() => {
        toast.error('As informações não poderam ser processadas.');
        console.log('ERROR API ==> listAccrediteds');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uId]);

  // FILTRA A BUSCA DO CREDENCIADO POR NOME
  useEffect(() => {
    if (search.length > 1) {
      const filteredResults = accredited.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  }, [search, accredited]);

  const handleAccreditedSelected = (e) => {
    setSelectedOptionAccredited(e.target.value);
  };

  const SendCartelas = () => {
    setLoadingSend(true);
    const infosAccredited = accredited.filter(
      (item) => item.name === selectedOptionAccredited
    )[0];

    const payload = {
      fractioned,
      pdvId: infosAccredited.id,
      quantity,
      serie: serie === '' ? serieUrl : serie,
    };

    distributionCards(uId, payload)
      .then((result) => {
        setLoadingSend(false);

        if (result.success) {
          localStorage.setItem('distributed', true);
          navigate(-2);
        }
      })
      .catch((err) => {
        setLoadingSend(false);
        toast.error(err);
      });
  };

  return (
    <div className={`modal ${openUpdate ? 'modal is-active' : 'modal'}`}>
      <div
        className="modal-background"
        onClick={() => handleModalUpdate()}
      ></div>
      <DivModal style={{ width: '50em' }} radius className="modal-card">
        <ContainerModal className="modal-card-body">
          <h4>Selecione o credenciado:</h4>
          <SearchInputContainer>
            <SearchInput
              autocomplete="off"
              name="randon_not_complete"
              type="text"
              placeholder="Pesquisar credenciado"
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon>
              <HiSearchCircle size="25px" />
            </SearchIcon>
          </SearchInputContainer>
          <Container>
            <ContainerSelects>
              <span>Selecione um credenciado:</span>
              <ContentSelectAccredit>
                {loading ? (
                  <ContentNoHave>
                    <CircularProgress size="35px" />
                  </ContentNoHave>
                ) : search.length > 1 ? (
                  searchResults.map((item) => (
                    <>
                      <table key={item.name}>
                        <tbody>
                          <tr>
                            <input
                              type="radio"
                              value={item.name}
                              checked={selectedOptionAccredited === item.name}
                              onChange={handleAccreditedSelected}
                              disabled={
                                item.establishments.length === 0 ||
                                item.establishments.every(
                                  (estab) =>
                                    Object.values(estab)[0].status === false
                                )
                              }
                            />
                            <span>
                              <TableDataInfo>{item.name}</TableDataInfo>
                              <TableDataInfo>{item.address}</TableDataInfo>
                            </span>
                          </tr>
                        </tbody>
                      </table>
                      <DividerTable />
                    </>
                  ))
                ) : (
                  accredited.map((item) => (
                    <>
                      <table key={item.name}>
                        <tbody>
                          <tr>
                            <input
                              type="radio"
                              value={item.name}
                              checked={selectedOptionAccredited === item.name}
                              onChange={handleAccreditedSelected}
                              disabled={
                                item.establishments.length === 0 ||
                                item.establishments.every(
                                  (estab) =>
                                    Object.values(estab)[0].status === false
                                )
                              }
                            />
                            <span>
                              <TableDataInfo>{item.name}</TableDataInfo>
                              <TableDataInfo>{item.address}</TableDataInfo>
                            </span>
                          </tr>
                        </tbody>
                      </table>
                      <DividerTable />
                    </>
                  ))
                )}
              </ContentSelectAccredit>
            </ContainerSelects>
          </Container>
          <p
            style={{
              fontSize: '0.7rem',
              color: 'red',
              textAlign: 'center',
            }}
          >
            *Credenciados não selecionáveis não possuem estabelecimentos
            vinculados ou ativos.
          </p>
          <QuantityValue>Total: {quantity} cartelas</QuantityValue>
          <SendCartelaButton
            disabled={selectedOptionAccredited === null || loadingSend}
            onClick={SendCartelas}
            variant="contained"
          >
            {loadingSend ? (
              <CircularProgress size="26px" style={{ color: '#fff' }} />
            ) : (
              'Reservar Cartelas'
            )}
          </SendCartelaButton>
        </ContainerModal>
      </DivModal>
    </div>
  );
};
