import { verifyUserPhone } from '../services/login';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authTokenWithSC } from '../services/APIs/token';
import { apiV2, authCode } from '../services/login';
import { findIndexInArray } from '../services/helpers';
import React, { useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [loggedUser, setLoggedUser] = useState();
  const [loading, setLoading] = useState(true);
  const sendType = { email: false, sms: true, whatsapp: false };

  useEffect(() => {
    const logged = localStorage.getItem('redirect');
    const recoveredUser = localStorage.getItem('user');
    if (recoveredUser && logged) {
      setUser(JSON.parse(recoveredUser));
      setRedirect(JSON.parse(logged));
    }
    setLoading(false);
  }, []);

  const resend = async (phone) => {
    const sendSms = await authCode(phone, sendType);
    localStorage.setItem(
      'transid',
      JSON.stringify(sendSms.data.success.transactionId)
    );
  };

  const login = async (phone) => {
    const token = await authTokenWithSC();
    const loginResponse = (await verifyUserPhone(phone, token)).data.response;
    if (loginResponse === undefined || !loginResponse.SupervisorBrasilCapInfo) {
      return false;
    } else {
      const name =
        loginResponse.partnerList[
          findIndexInArray(
            loginResponse.partnerList,
            'partnerName',
            'BrasilCap'
          )
        ].name;
      const role = loginResponse.SupervisorBrasilCapInfo.role;
      const id = loginResponse.id;
      const userObj = { name, role, id };
      localStorage.setItem('name', JSON.stringify(name));
      localStorage.setItem('role', JSON.stringify(role));
      localStorage.setItem('uid', JSON.stringify(id));
      localStorage.setItem('user', JSON.stringify(userObj));
      const sendSms = await authCode(phone, sendType);
      if (sendSms.success) {
        localStorage.setItem('transid', JSON.stringify(sendSms.transactionId));
      }
      const loggedUser = name;
      setUser({ loggedUser });
      if (!loginResponse) {
        return false;
      } else {
        // await resend(phone);
        setUser({ loggedUser });
        return true;
      }
    }
  };

  const logout = () => {
    apiV2.defaults.headers.Authorization = null;
    localStorage.clear();
    setUser(null);
    navigate('/');
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user && !!redirect,
        user,
        setUser,
        login,
        logout,
        transactionId,
        setTransactionId,
        loggedUser,
        setLoggedUser,
        resend,
        setRedirect,
        loading,
        redirect,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
