import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { convertDate } from '../../../services/helpers';

//STYLES
import { Content, InsideContent } from '../../../styled/generic';
import { Button, Input, Label, MessageAlert, WhiteBox } from '../styles/DistribuitionCartelasStyle';
import { Form } from '../../../styled/RegisterStyle';
import { Oval } from 'react-loading-icons';

//COMPONENTS
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import HeaderComp from '../../../components/Header/HeaderComp';

//APIS
import { createSeries } from '../../../services/APIs/createSeries';

export function RegisterSeries() {
  const uId = JSON.parse(localStorage.getItem('uid'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messageDate, setMessageDate] = useState('');
  const [nonCompliant, setNonCompliant] = useState(false);
  const [serieInfo, setSerieInfo] = useState({
    serie: '',
    totalCartela: '',
    totalDigital: '',
    initialDate: '',
    finalDate: '',
    initialDateDistribuition: '',
    finalDateDistribuition: '',
    stopSellingDate: '',
    cancelDateForLiquidation: '',
  });

  // VERIFICAÇÕES DE DATAS
  useEffect(() => {
    const convertInitialDate = convertDate(serieInfo.initialDate, 'start');
    const convertFinalDate = convertDate(serieInfo.finalDate, 'final');
    const convertInitialDateDistrib = convertDate(serieInfo.initialDateDistribuition, 'start');
    const convertFinalDateDistrib = convertDate(serieInfo.finalDateDistribuition, 'final');
    const convertStopSellingDate = convertDate(serieInfo.stopSellingDate, 'final');
    const convertCancelDateForLiquidation = convertDate(
      serieInfo.cancelDateForLiquidation,
      'start'
    );

    // VERIFICACAO DA ATIVACAO:
    if (convertInitialDate >= convertFinalDate) {
      setNonCompliant(true);
      setMessageDate(
        'Data de desativação da série não pode ser anterior ou igual a data de ativação.'
      );
    } else if (convertInitialDate >= convertStopSellingDate) {
      setNonCompliant(true);
      setMessageDate(
        'Data limite de vendas não pode ser anterior ou igual a data de ativação da série.'
      );
      // VERIFICACAO DA DESATIVACAO
    } else if (convertFinalDate <= convertInitialDateDistrib) {
      setNonCompliant(true);
      setMessageDate(
        'Data do inicio da distribuição não pode ser posterior ou igual a data de desativação da série.'
      );
    } else if (convertFinalDate < convertFinalDateDistrib) {
      setNonCompliant(true);
      setMessageDate(
        'Data do fim da distribuição não pode ser posterior a data desativação da série.'
      );
    } else if (convertFinalDate < convertStopSellingDate) {
      setNonCompliant(true);
      setMessageDate('Data de desativação da série não pode ser anterior a data limite de vendas.');
    } else if (convertInitialDateDistrib >= convertFinalDateDistrib) {
      setNonCompliant(true);
      setMessageDate(
        'Data do inicio da distribuição não pode ser posterior ou igual a data de fim da distribuição.'
      );
    } else if (convertCancelDateForLiquidation < convertFinalDate) {
      setNonCompliant(true);
      setMessageDate(
        'Data limite para não pagamento de títulos cancelados não pode ser anterior a data de desativação da série.'
      );
    } else {
      setNonCompliant(false);
      setMessageDate('');
    }

    if (+serieInfo.totalCartela + +serieInfo.totalDigital > 50000) {
      setNonCompliant(true);
      setMessageDate('Uma série não pode possuir mais de 50 mil cartelas');
    }
  }, [serieInfo]);

  // TRATA OS INPUTS CONFORME NECESSÁRIO
  const handleInputChange = (e, type) => {
    // Permite apenas números e campo vazio
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    // Permite apenas letras
    const sanitizeSerie = e.target.value.replace(/[^a-zA-Z]/g, '').toUpperCase();

    switch (type) {
      case 'serie':
        setSerieInfo({ ...serieInfo, serie: sanitizeSerie });
        break;
      case 'totalCartela':
        setSerieInfo({ ...serieInfo, totalCartela: inputValue });
        break;
      case 'totalDigital':
        setSerieInfo({ ...serieInfo, totalDigital: inputValue });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      if (nonCompliant) {
        return toast.warning(
          'Preencha os campos corretamente para prosseguir na criação da série.'
        );
      } else {
        const payload = {
          serie: serieInfo.serie,
          totalCardFisical: +serieInfo.totalCartela,
          totalCardDigital: +serieInfo.totalDigital,
          serieStartDate: convertDate(serieInfo.initialDate, 'start'),
          serieEndDate: convertDate(serieInfo.finalDate, 'final'),
          distrStartDate: convertDate(serieInfo.initialDateDistribuition, 'start'),
          distrEndDate: convertDate(serieInfo.finalDateDistribuition, 'final'),
          stopSellingDate: convertDate(serieInfo.stopSellingDate, 'final'),
          cancelDateForLiquidation: convertDate(serieInfo.cancelDateForLiquidation, 'start'),
        };
        await createSeries(uId, payload);
        toast.success('Série cadastrada com sucesso.');
        navigate('/operacao-cartela/gerenciar-series');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <Form onSubmit={handleSubmit} style={{ marginTop: 0 }}>
            <WhiteBox className="box" style={{ height: '42rem' }}>
              {messageDate !== '' && <MessageAlert>{messageDate}</MessageAlert>}
              <Label>Série*</Label>
              <Input
                maxLength={2}
                placeholder="AA"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) => handleInputChange(e, 'serie')}
                value={serieInfo.serie}
              />
              <Label>Data de ativação da série*:</Label>
              <Input
                type="date"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) => setSerieInfo({ ...serieInfo, initialDate: e.target.value })}
                value={serieInfo.initialDate}
              />
              <Label>Data de vencimento da série*:</Label>
              <Input
                type="date"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) => setSerieInfo({ ...serieInfo, finalDate: e.target.value })}
                value={serieInfo.finalDate}
              />
              <Label>Data do início da distribuição*:</Label>
              <Input
                type="date"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) =>
                  setSerieInfo({
                    ...serieInfo,
                    initialDateDistribuition: e.target.value,
                  })
                }
                value={serieInfo.initialDateDistribuition}
              />
              <Label>Data do fim da distribuição*:</Label>
              <Input
                type="date"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) =>
                  setSerieInfo({
                    ...serieInfo,
                    finalDateDistribuition: e.target.value,
                  })
                }
                value={serieInfo.finalDateDistribuition}
              />
              <Label>Data limite para vendas*:</Label>
              <Input
                type="date"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) =>
                  setSerieInfo({
                    ...serieInfo,
                    stopSellingDate: e.target.value,
                  })
                }
                value={serieInfo.stopSellingDate}
              />
              <Label>Data limite para não pagamento de títulos cancelados *:</Label>
              <Input
                type="date"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) =>
                  setSerieInfo({
                    ...serieInfo,
                    cancelDateForLiquidation: e.target.value,
                  })
                }
                value={serieInfo.cancelDateForLiquidation}
              />
              <Label>Número de títulos físicos*:</Label>
              <Input
                placeholder="35000"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) => handleInputChange(e, 'totalCartela')}
                value={serieInfo.totalCartela}
              />
              <Label>Número de títulos digitais*:</Label>
              <Input
                placeholder="5000"
                className={
                  window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
                }
                required
                onChange={(e) => handleInputChange(e, 'totalDigital')}
                value={serieInfo.totalDigital}
              />
            </WhiteBox>
            <div className={`modal ${loading ? 'modal is-active' : 'modal'}`}>
              <div
                className="modal-background"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Oval stroke="#EDB622" />
              </div>
            </div>
            <Button
              type="submit"
              className={window.screen.width > 450 ? 'button' : 'button is-small'}
              disabled={loading}
            >
              Salvar
            </Button>
          </Form>
        </InsideContent>
      </Content>
    </>
  );
}

