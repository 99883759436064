import { useState } from 'react';

// STYLES
import {
  BoxBalance,
  InputHolder,
  PdvBoxButton,
} from '../styles/DistribuitionCartelasStyle';
import {
  SmallPictureProfile,
  SmallPictureProfileBox,
} from '../../../styled/PictureProfiel';
import { Content, GenericText, InsideContent } from '../../../styled/generic';
import { FaStore } from 'react-icons/fa';

// LIBS
import { Link, useLocation } from 'react-router-dom';

// COMPONENTS
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import HeaderComp from '../../../components/Header/HeaderComp';
import AccreditedTable from '../../../components/Tables/AccreditedTable';

export function Accredited() {
  const location = useLocation();
  const [selected, setSelected] = useState(true);

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent theme={location.pathname} id="inside-content">
          <InputHolder id="inputHolder">
            <Link to={'/operacao-cartela/gerenciar-credenciados/cadastro'}>
              <PdvBoxButton>
                <SmallPictureProfileBox>
                  <SmallPictureProfile>
                    <FaStore />
                  </SmallPictureProfile>
                </SmallPictureProfileBox>
                <GenericText>Cadastrar credenciado</GenericText>
              </PdvBoxButton>
            </Link>
          </InputHolder>
          <BoxBalance>
            <label style={{ margin: '2rem 0 1rem 1rem' }}>
              Selecionar status:&nbsp;
              <select onChange={() => setSelected(!selected)}>
                <option value={true}>Credenciados ativos</option>
                <option value={false}>Credenciados inativos</option>
              </select>
            </label>
            <AccreditedTable selected={selected} />
          </BoxBalance>
        </InsideContent>
      </Content>
    </>
  );
}
