import { GenericText, Content, InsideContent } from "../styled/generic";
import { useLocation } from "react-router-dom";

import "../css/Input.css";
import HeaderComp from "../components/Header/HeaderComp";
import SideBarDesktop from "../components/Sidebar/SideBarComp";
import { ProfileBox, InputHolder } from "../components/Tables/style/SellerListStyle";
import { BsPersonFill } from "react-icons/bs";
import { SmallPictureProfile, SmallPictureProfileBox } from "../styled/PictureProfiel";
import SupervisorListTable from "../components/Tables/SupervisorListTable";
import Footer from "../components/Footer/Footer";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BoxBalance } from "../pages/SellerPage/style/SellerPageStyle";
export default function SupervisorList() {
  const location = useLocation();
  //const [searchName, setSearchName] = useState("");
  // const [active, setActive] = useState("Todos");
  const [selected, setSelected] = useState(true);

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent theme={location.pathname} id="inside-content">
          <InputHolder>
            <Link
              style={{ textDecoration: "none", marginBottom: "1em" }}
              to={"/registro-supervisor"}>
              <ProfileBox>
                <SmallPictureProfileBox>
                  <SmallPictureProfile>
                    <BsPersonFill />
                  </SmallPictureProfile>
                </SmallPictureProfileBox>
                <GenericText>Adicionar novo supervisor(a)</GenericText>
              </ProfileBox>
            </Link>
          </InputHolder>
          <BoxBalance>
            <label style={{ marginBottom: '1em'}}>
              Selecionar status:&nbsp;
              <select onChange={(e) => setSelected(!selected)}>
                <option value={true}>Supervisores ativos</option>
                <option value={false}>Supervisores inativos</option>
              </select>
            </label>
            <SupervisorListTable selected={selected} />
          </BoxBalance>
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
