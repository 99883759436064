import { useState } from 'react';
import { mask } from 'remask';
import ReactInputMask from 'react-input-mask';

// STYLE
import {
  DivModal,
  HeaderText,
  ModalBody,
  ModalHeader,
  SearchInput,
  SendCartelaButton,
} from './style/ModalStyle';
import { CircularProgress } from '@mui/material';
import { WhiteBoxOwner } from '../../pages/DistribuitionCartelas/styles/DistribuitionCartelasStyle';

// APIS
import { changeAccredited } from '../../services/APIs/changeAccredited';
import { getUserBrasilCap } from '../../services/getUsersBrasilCap';
import { toast } from 'react-toastify';

export const ModalToggleOwnerAccredited = ({
  open,
  close,
  accreditedId,
  setUpdatePage,
  oldAccreditedUid,
  updatePage,
}) => {
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [cellphone, setCellPhone] = useState('');
  const [userInfo, setUserInfo] = useState({
    cellphone: '',
    cpf: '',
    email: '',
    name: '',
    uId: '',
  });

  const handleInputCellphone = (e) => {
    const phone = mask(e.target.value, ['(99) 99999-9999']);

    setCellPhone(phone);
  };

  const handleCloseModal = () => {
    setMessage('');
    setCellPhone('');
    setUserInfo({ uId: '', cellphone: '', email: '', cpf: '' });
    close();
  };

  const findNewOwner = async () => {
    try {
      const sanitizeCellPhone = cellphone.replace(/[^a-zA-Z0-9]/g, '');
      setLoading(true);

      const user = await getUserBrasilCap(sanitizeCellPhone);
      const partner = user.partnerList.find((partner) => partner.partnerName === 'BrasilCap');

      const cpf = partner.documentList.find((doc) => doc.type === 'cpf').value;
      const phone = partner.contactList.find((contact) => contact.type === 'cellPhone').value;
      const email = partner.contactList.find((contact) => contact.type === 'email').value;

      setUserInfo({
        ...userInfo,
        uId: user.uId,
        name: partner.name,
        cpf: mask(cpf, ['999.999.999-99']),
        cellphone: phone,
        email: email,
      });
      setMessage('');
    } catch (err) {
      setMessage('Usuário não encontrado no banco de dados do sorte e saúde.');
    } finally {
      setLoading(false);
    }
  };

  const toggleOwners = async () => {
    setLoading(true);
    try {
      const payload = { accreditedId, newAccreditedUid: userInfo.uId };

      if (userInfo.uId === oldAccreditedUid) {
        return setMessage('Novo proprietário é igual ao antigo.');
      }

      await changeAccredited(uId, payload);
      toast.success('Proprietário do credenciamento alterado com sucesso.');
      setUpdatePage(!updatePage);
      setUserInfo({ cellphone: '', cpf: '', email: '', name: '', uId: '' });
      close();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setCellPhone('');
    }
  };

  return (
    <div className={`modal ${open ? 'modal is-active' : 'modal'}`}>
      <div onClick={handleCloseModal} className="modal-background"></div>
      <DivModal
        style={{ minHeight: `${userInfo.uId === '' ? '10rem' : '30rem'}` }}
        className="modal-card"
      >
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">
            Alterar proprietário do credenciamento
          </HeaderText>
        </ModalHeader>
        <ModalBody className="modal-card-body">
          {message !== '' && message !== undefined && message !== null && (
            <p style={{ color: 'red' }}>{message}</p>
          )}
          {userInfo.uId === '' ? (
            <>
              <h5>Digite o telefone do novo credenciado</h5>
              <SearchInput
                type="text"
                placeholder="(00) 00000-0000"
                style={{ width: '50%' }}
                onChange={handleInputCellphone}
                value={cellphone}
              />{' '}
            </>
          ) : (
            <WhiteBoxOwner className="box" style={{ width: '90%' }}>
              <p>Novo proprietário do credenciamento</p>
              <input
                className="input is-fullwidth"
                style={{
                  marginBottom: '1em',
                }}
                disabled
                placeholder="Nome não encontrado"
                value={userInfo.name}
              />
              <ReactInputMask
                className="input is-fullwidth"
                mask="(+55) 99 9 9999-9999"
                style={{
                  marginBottom: '1em',
                }}
                disabled
                placeholder="Telefone não encontrado"
                value={userInfo.cellphone}
              />
              <ReactInputMask
                className="input is-fullwidth"
                mask="999.999.999-99"
                style={{
                  marginBottom: '1em',
                }}
                placeholder="CPF não encontrado"
                disabled
                value={userInfo.cpf}
              />
              <input
                className="input is-fullwidth"
                style={{
                  marginBottom: '1em',
                }}
                placeholder="E-mail não encontrado"
                disabled
                value={userInfo.email}
              />
            </WhiteBoxOwner>
          )}
          {userInfo.uId === '' ? (
            <SendCartelaButton
              onClick={findNewOwner}
              className={window.screen.width > 450 ? 'button' : 'button is-small'}
              disabled={loading}
              variant="contained"
            >
              {loading ? (
                <CircularProgress style={{ color: '#fff' }} size={26} thickness={5} />
              ) : (
                'Buscar'
              )}
            </SendCartelaButton>
          ) : (
            <span style={{ display: 'flex', gap: '1rem' }}>
              <SendCartelaButton
                onClick={toggleOwners}
                className={window.screen.width > 450 ? 'button' : 'button is-small'}
                disabled={loading}
                variant="contained"
              >
                {loading ? (
                  <CircularProgress style={{ color: '#fff' }} size={26} thickness={5} />
                ) : (
                  'Confirmar Alteração'
                )}
              </SendCartelaButton>
              <SendCartelaButton
                onClick={handleCloseModal}
                variant="contained"
                style={{ background: '#E5AA1A' }}
              >
                Cancelar
              </SendCartelaButton>
            </span>
          )}
        </ModalBody>
      </DivModal>
    </div>
  );
};
