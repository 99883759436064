import React, { useContext } from 'react';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';

//STYLES
import { IoWarning } from 'react-icons/io5';
import {
  ModalHeader,
  ModalBody,
  DivModal,
  HeaderText,
  TableSeeStatus,
  Bold,
} from './style/ModalStyle';

export default function ModalSeeStatusSeries({ isOpen, close }) {
  const { resumeSerieStatus } = useContext(DistribuitionCartelaContext);

  // TRANSFORMA O OBJ EM UM ARRAY E TRATA OS STATUS
  const data =
    resumeSerieStatus !== undefined &&
    Object.entries(resumeSerieStatus).map(([status, quantity]) => {
      let newStatus;

      switch (status) {
        case 'notDisabled':
          newStatus = 'Não baixados ';
          break;
        case 'disabledError':
          newStatus = 'Baixados com erro';
          break;
        case 'disableConfirmed':
          newStatus = 'Baixados com sucesso';
          break;
        case 'disablePending':
          newStatus = 'Baixas pendentes';
          break;
        case 'lossError':
          newStatus = 'Extravios com erro';
          break;
        case 'lossPending':
          newStatus = 'Extravios pendentes';
          break;
        case 'lossFinished':
          newStatus = 'Extravios finalizados';
          break;
        case 'pending':
          newStatus = 'Pendentes';
          break;
        case 'active':
          newStatus = 'Ativados';
          break;
        case 'reservedOrDelivered':
          newStatus = 'Resevados ou enviados';
          break;
        default:
          newStatus = status;
      }

      return {
        status: newStatus,
        quantity,
      };
    });

  const handleCloseModal = () => {
    close('seeStatusSerie');
  };

  return (
    <div className={`modal ${isOpen ? 'modal is-active' : 'modal'}`}>
      <div onClick={handleCloseModal} className="modal-background"></div>
      <DivModal style={{ minHeight: '80%' }} className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">
            Ver status da série
          </HeaderText>
        </ModalHeader>
        <ModalBody className="modal-card-body">
          {resumeSerieStatus !== undefined ? (
            <TableSeeStatus>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {data.map((info) => (
                  <tr key={info.status}>
                    <td>
                      <strong>{info.status}</strong>
                    </td>
                    <td style={{ fontSize: '0.8rem' }}>
                      <Bold color={info.quantity}>{info.quantity}</Bold>
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableSeeStatus>
          ) : (
            <>
              <IoWarning size={100} style={{ color: '#EEBC33' }} />
              <h3 style={{ fontWeight: 'bold' }}>Relatório indisponível!</h3>
            </>
          )}
        </ModalBody>
      </DivModal>
    </div>
  );
}
