import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { StyledDiv } from '../Tables/style/MUIDatatable';
import { BottomMargin } from '../../pages/SellerPage/style/SellerPageStyle';
import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { LoadingTable } from '../../pages/ListOfRaffles/style/ListOfRaffles';
import { listAccrediteds } from '../../services/APIs/listAccrediteds';
import { useNavigate } from 'react-router-dom';
import { BsCircleFill } from 'react-icons/bs';
import { toast } from 'react-toastify';

export default function AccreditedTable({ selected }) {
  const uId = JSON.parse(localStorage.getItem('uid'));
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [accreditedsList, setAccreditedesList] = useState([]);

  useEffect(() => {
    listAccrediteds(uId)
      .then((pdv) => {
        const data = pdv.response.map((info) => {
          const item = Object.values(info)[0];

          return {
            uId: Object.keys(info)[0],
            name: item.name,
            address: item.address,
            status: item.status,
          };
        });
        setAccreditedesList(data);
      })
      .catch(() => {
        toast.error('As informações não poderam ser processadas.');
      });
  }, [uId]);

  useEffect(() => {
    navigate(`/operacao-cartela/gerenciar-credenciados/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, uId]);

  if (accreditedsList?.length === 0)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingTable>
          <CircularProgress size="100px" />
        </LoadingTable>
      </div>
    );

  const displayData = accreditedsList.filter((el) => el.status === selected);

  const columns = [
    {
      name: 'uId',
      label: 'Id do credenciado',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'name',
      label: 'Nome do credenciado',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'address',
      label: 'Endereço',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          if (!value) {
            return (
              <span>
                <BsCircleFill style={{ color: '#8A8F91' }} />
                &nbsp;Inativo
              </span>
            );
          } else {
            return (
              <span>
                <BsCircleFill style={{ color: '#2DB94D' }} />
                &nbsp;Ativo
              </span>
            );
          }
        },
      },
    },
  ];

  const tableOptions = {
    onRowClick: (rowData) => {
      setId(rowData[0]);
    },
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: 'simple',
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: '',
        toolTip: `${'table.toolTip'}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${'table.viewColumnsTitle'}`,
      },
    },
  };
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
            },
          },
        },
      },
    });

  return (
    <BottomMargin>
      <StyledDiv style={{ cursor: 'pointer' }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            className={'table is-mobile'}
            data={displayData}
            columns={columns}
            options={tableOptions}
          />
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}

