import axios from 'axios';
import { configJson } from '../../library/utils/firebaseUtils';
import { authToken } from '../APIs/token';

export function verifyBeforeLoss(uId, serie) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .get(
            configJson.API_HOST_V2 +
              `/sorteSaude/series/verifyBeforeLoss?serie=${serie}`,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
          .then((result) => {
            // console.log('RESULT  ==>', result.data.response);
            resolve(result.data.response);
          })
          .catch((error) => {
            // console.log('ERROR  ==>', error);
            reject(error.data?.error); // Reject the promise with the error
          });
      })
      .catch((authError) => {
        reject(authError); // Reject the promise with the authentication errorp
      });
  });
}
