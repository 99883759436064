import React, { useContext } from 'react';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';
import MUIDataTable from 'mui-datatables';

// STYLES
import { ContainerButtonTable } from './style/EditButtomTableStyles';
import { StyledDiv } from './style/MUIDatatable';
import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { BottomMargin } from '../../pages/SellerPage/style/SellerPageStyle';
import { BsCircleFill } from 'react-icons/bs';

//COMPONENTS
import { CardReservationbutton } from '../ActionButtons/CardResevationbutton';
import { ValidAndProccessButton } from '../ActionButtons/ValidAndProccessButton';
import { ViewAndEditSerieButton } from '../ActionButtons/ViewAndEditSerieButton';
import { DeactivatedCardButton } from '../ActionButtons/DeactivatedCardButton';
import { FinishSerieButton } from '../ActionButtons/FinishSerieButton';
import { DeactivatedCardListButton } from '../ActionButtons/DeactivatedCardListButton';
import { CardLosesButton } from '../ActionButtons/CardLosesButton';
import { SeeStatusSeriesButton } from '../ActionButtons/SeeStatusSeriesButton';
import { SaleOff } from '../ActionButtons/SaleOffButton';

// OTHERS
import { convertToFullDateNoHours, convertVisualDateForTimestamp } from '../../services/helpers';
import { CardsError } from '../ActionButtons/CardsError';

export default function SeriesTable({
  seriesInfo,
  loadingTable,
  openModalSerieEdit,
  openModalValidationAndProcess,
  openModalFinishSerie,
  isActiveSeries,
  selected,
}) {
  const { reservedInfos } = useContext(DistribuitionCartelaContext);

  const sanitizeData = seriesInfo.map((item) => {
    const data = {
      serieStartDate: convertToFullDateNoHours(item.serieStartDate),
      serieEndDate: convertToFullDateNoHours(item.serieEndDate),
      distrStartDate: convertToFullDateNoHours(item.distrStartDate),
      distrEndDate: convertToFullDateNoHours(item.distrEndDate),
      stopSellingDate: convertToFullDateNoHours(item.stopSellingDate),
      cancelDateForLiquidation: convertToFullDateNoHours(item.cancelDateForLiquidation),
      totalCardFisical: item.totalCardFisical,
      totalCardDigital: item.totalCardDigital,
      totalCards: item.totalCards,
      serie: item.serie,
      id: item.id,
      status: item.status,
      cardStatus: item.cardStatus,
    };

    return data;
  });

  const seriesFinished = (endDate) => {
    const date = new Date();
    const dateNow = date.getTime();
    if (endDate <= dateNow) {
      return true;
    } else {
      return false;
    }
  };

  const displayData =
    sanitizeData &&
    sanitizeData.filter(
      (el) => isActiveSeries(convertVisualDateForTimestamp(el.serieEndDate)) === selected
    );

  const columns = [
    {
      name: 'serie',
      label: 'Série',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'serieStartDate',
      label: 'Data do inicio da série',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'serieEndDate',
      label: 'Data do fim da série',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'distrStartDate',
      label: 'Data do início da distribuição',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'distrEndDate',
      label: 'Data do fim da distribuição',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'registered':
              return (
                <span>
                  <BsCircleFill style={{ color: '#8A8F91' }} />
                  &nbsp;Cadastrada
                </span>
              );
            case 'validation':
              return (
                <span>
                  <BsCircleFill style={{ color: '#2DB94D' }} />
                  &nbsp;Em validação
                </span>
              );
            case 'validationSuccess':
              return (
                <span>
                  <BsCircleFill style={{ color: '#54BBFE' }} />
                  &nbsp;Validado com sucesso
                </span>
              );
            case 'validationError':
              return (
                <span>
                  <BsCircleFill style={{ color: '#EB1E0A' }} />
                  &nbsp;Validado com erro
                </span>
              );
            case 'processing':
              return (
                <span>
                  <BsCircleFill style={{ color: '#E5AA1A' }} />
                  &nbsp;Em processamento
                </span>
              );
            case 'processedError':
              return (
                <span>
                  <BsCircleFill style={{ color: '#EB1E0A' }} />
                  &nbsp;Processado com erro
                </span>
              );
            case 'processedSuccess':
              return (
                <span>
                  <BsCircleFill style={{ color: '#2D70B9' }} />
                  &nbsp;Processado com sucesso
                </span>
              );
            case 'lossInitiated':
              return (
                <span>
                  <BsCircleFill style={{ color: '#E5AA1A' }} />
                  &nbsp;Extravio iniciado
                </span>
              );
            case 'lossCompleted':
              return (
                <span>
                  <BsCircleFill style={{ color: '#2DB94D' }} />
                  &nbsp;Extravio Completo
                </span>
              );
            default:
              break;
          }
        },
      },
    },
    {
      name: 'serie',
      label: 'Ação',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value, tableRow) => (
          <ContainerButtonTable>
            {/* VALIDAR E PROCESSAR ARQUIVO */}
            <ValidAndProccessButton
              openModal={openModalValidationAndProcess}
              serie={value}
              status={tableRow.rowData[5]}
            />
            {/* RESERVAR CARTELAS */}
            <CardReservationbutton
              date={tableRow.rowData[4]}
              reservedInfos={reservedInfos}
              serie={value}
              status={tableRow.rowData[5]}
            />
            {/* VISUALIZAR E EDITAR SÉRIE */}
            <ViewAndEditSerieButton
              infosSerie={tableRow.tableData[tableRow.rowIndex]}
              openModal={openModalSerieEdit}
              serie={value}
              serieEndDate={tableRow.tableData[tableRow.rowIndex].serieEndDate}
              seriesFinished={seriesFinished}
              status={tableRow.rowData[5]}
            />
            {/* DAR BAIXA */}
            <DeactivatedCardButton
              serie={value}
              serieStartDate={convertVisualDateForTimestamp(
                tableRow.tableData[tableRow.rowIndex].serieStartDate
              )}
              serieEndDate={convertVisualDateForTimestamp(
                tableRow.tableData[tableRow.rowIndex].serieEndDate
              )}
              status={tableRow.rowData[5]}
              stopSellingDate={convertVisualDateForTimestamp(
                tableRow.tableData[tableRow.rowIndex].stopSellingDate
              )}
            />
            {/* FINALIZAR SÉRIE */}
            <FinishSerieButton
              openModal={openModalFinishSerie}
              serie={value}
              stopSellingDate={tableRow.tableData[tableRow.rowIndex].stopSellingDate}
              seriesFinished={seriesFinished}
              status={tableRow.rowData[5]}
            />
            {/* CARTELAS BAIXADAS */}
            <DeactivatedCardListButton
              serie={value}
              stopSellingDate={tableRow.tableData[tableRow.rowIndex].stopSellingDate}
              seriesFinished={seriesFinished}
              status={tableRow.rowData[5]}
            />
            {/* CARTELAS EXTRAVIADAS */}
            <CardLosesButton
              serie={value}
              serieEndDate={tableRow.tableData[tableRow.rowIndex].serieEndDate}
              seriesFinished={seriesFinished}
              status={tableRow.rowData[5]}
            />
            {/* VER STATUS DA SERIE */}
            <SeeStatusSeriesButton
              openModal={openModalFinishSerie}
              serieInfoStatus={tableRow.tableData[tableRow.rowIndex].cardStatus}
              serieEndDate={tableRow.tableData[tableRow.rowIndex].serieEndDate}
              seriesFinished={seriesFinished}
              status={tableRow.rowData[5]}
            />

            {/* LIQUIDAÇÃO */}
            <SaleOff
              serie={value}
              serieEndDate={tableRow.tableData[tableRow.rowIndex].serieEndDate}
              seriesFinished={seriesFinished}
              status={tableRow.rowData[5]}
            />

            {/* CARTELAS COM ERRO */}
            <CardsError
              serie={value}
              serieEndDate={tableRow.tableData[tableRow.rowIndex].serieEndDate}
              seriesFinished={seriesFinished}
              status={tableRow.rowData[5]}
            />
          </ContainerButtonTable>
        ),
      },
    },
  ];

  const tableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: 'simple',
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: '',
        toolTip: `${'table.toolTip'}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${'table.viewColumnsTitle'}`,
      },
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
              textAlign: 'center',
              cursor: 'default',
            },
          },
        },
      },
    });

  return (
    <BottomMargin>
      <StyledDiv
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ThemeProvider theme={getMuiTheme()}>
          {loadingTable ? (
            <CircularProgress size={100} thickness={4} style={{ marginTop: '3rem' }} />
          ) : (
            <MUIDataTable
              className={'table is-mobile'}
              data={displayData}
              columns={columns}
              options={tableOptions}
            />
          )}
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}
