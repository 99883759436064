import axios, { AxiosError } from "axios";
import { configJson } from "../library/utils/firebaseUtils";

export const apiV2 = axios.create({
  baseURL: configJson.API_HOST_V2,
});

export const getAllSellers = async (uid, token, role) => {
  try {
    const response = await apiV2.get(
      role === "manager"
        ? `${configJson.API_HOST_V2}/brasilCap/getAllSellers`
        : `${configJson.API_HOST_V2}/brasilCap/getSellersList/?uidSupervisor=${uid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllTestimonials = async (token) => {
  try {
    const response = await apiV2.get(
      // `https://api-hml.triibo.com.br/brasilCap/testimonials?prizeType=sortesaude`,
      `${configJson.API_HOST_V2}/brasilCap/testimonials?prizeType=sortesaude`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllSupervisors = async (token) => {
  try {
    const response = await apiV2.get(`/brasilCap/getAllSupervisors`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAffiliation = async (token, uid) => {
  try {
    const response = await apiV2.get(
      `/affiliations?affiliationName=${configJson.partnerNameBBCap}&uid=${uid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  } catch (err) {
    return AxiosError;
  }
};

export const getUsersAffiliated = async (token) => {
  try {
    const response = await apiV2.get(`/affiliations/users/BrasilCap`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return AxiosError;
  }
};
