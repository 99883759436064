import {
  InsideContent,
  Content,
} from "../../styled/generic";
import {
  ProfileBox,
  ProfileData,
  UserData,
  UserDataText,
  PictureNData,
} from "./style/MyPofileStyle";
import { BsPersonFill } from "react-icons/bs";
import ReactInputMask from "react-input-mask";
import HeaderComp from "../../components/Header/HeaderComp";
import SideBarDesktop from "../../components/Sidebar/SideBarComp";
import {
  BigPictureProfile,
  BigPictureProfileBox,
} from "../../styled/PictureProfiel";
import Footer from "../../components/Footer/Footer";
import { verifyUserPhone } from "../../services/login";
import { authToken } from "../../services/APIs/token";
import React from "react";
import { findIndexInArray } from "../../services/helpers";

import { useQuery } from "@tanstack/react-query";

export default function MyProfile() {
  const phone = JSON.parse(localStorage.getItem("telNmbr"));
  const role = JSON.parse(localStorage.getItem("role"));

  const { data: user, isError } = useQuery({
    queryKey: ["userData"],
    queryFn: async () => {
      const token = await authToken();
      if (token) {
        const response = await verifyUserPhone(
          phone,
          token
        );

        return response.data.response.partnerList[
          findIndexInArray(
            response.partnerList,
            "partnerName",
            "BrasilCap"
          )
        ];
      }
    },
    keepPreviousData: true,
    retry: 1,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          {isError ? (
            "Este usuário não consta na lista de usuários ativos da empresa"
          ) : (
            <ProfileBox>
              <PictureNData>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}>
                  <BigPictureProfileBox>
                    <BigPictureProfile>
                      <BsPersonFill />
                    </BigPictureProfile>
                  </BigPictureProfileBox>
                </div>
                <ProfileData>
                  <p
                    style={{
                      fontSize: "60%",
                      fontWeight: 500,
                    }}>
                    {role === "manager"
                      ? "Gerente"
                      : "Supervisor"}
                  </p>
                  <p
                    style={{
                      fontSize: "60%",
                      fontWeight: 500,
                    }}>
                    {user && user.name}
                  </p>
                </ProfileData>
              </PictureNData>
              <UserData>
                <UserDataText>Dados Pessoais</UserDataText>
                <ReactInputMask
                  mask="+99 99 99999-9999"
                  className={
                    window.screen.width > 450
                      ? "input is-fullwidth"
                      : "input is-fullwidth is-small"
                  }
                  value={
                    user &&
                    user.contactList.find(
                      (el) => el.type === "cellPhone"
                    ).value
                  }
                  placeholder="(+55) 11 99999-9999"
                  disabled
                />
                <ReactInputMask
                  mask="999.999.999-99"
                  className={
                    window.screen.width > 450
                      ? "input is-fullwidth"
                      : "input is-fullwidth is-small"
                  }
                  value={
                    user &&
                    user.documentList.find(
                      (el) => el.type === "cpf"
                    ).value
                  }
                  placeholder="999.999.999-99"
                  disabled
                />
                <input
                  className={
                    window.screen.width > 450
                      ? "input is-fullwidth"
                      : "input is-fullwidth is-small"
                  }
                  l
                  value={
                    user &&
                    user.contactList.find(
                      (el) => el.type === "email"
                    ).value
                  }
                  placeholder="email@email.com"
                  disabled
                />
              </UserData>
            </ProfileBox>
          )}
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
