import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const EditButton = styled(Link)`
  max-width: 50px;
  width: 4rem;
  background: rgba(45, 112, 185, 0.9);
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-bottom: solid 1px #707070;
  display: table;
`;

export const TableTR = styled.tr`
  border-bottom: solid 1px #70707033;
  text-align: left;
  display: table-row;
`;

export const TableTH = styled.th`
  padding: 1rem 0.5rem;
  display: table-cell;
`;

export const TableTD = styled.td`
  padding: 1rem 0.5rem;
  display: table-cell;
`;

export const TableContainer = styled.div`
  width: 100%;

  @media (max-width: 700px) {
    width: 100%;
    overflow-x: auto;
  }
`;

export const ContainerButtonTable = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ButtonDistribuitionTable = styled.button`
  border: 0;
  background: transparent;
`;

export const ContainerNotHaveItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    font-size: 1.5rem;
    border: 0;
    background: transparent;
    font-weight: bold;
    color: rgba(45, 112, 185, 0.9);
    text-decoration: underline;
  }
`;
