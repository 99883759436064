import { TextField } from '@mui/material';
import styled from 'styled-components';
import { ProfileBox } from '../../SellerNSupervisor/style/SellerNSuperStyle';
import { transparentize } from 'polished';
import { BlueBox } from '../../../styled/RegisterStyle';

//DISTRIBUITIONCARTELAS
export const ContainerDistribuition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 4% auto;
  width: 80%;

  button {
    max-width: 159px;
  }
`;

// SendType

export const ContainerSendType = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;

  a {
    text-decoration: none;
    text-align: center;
    width: 40%;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const SendBox = styled(ProfileBox)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  color: #fff;
  height: 35rem;
  min-width: 45em;

  h4 {
    font-weight: 700;
    margin: 0;
  }

  p {
    font-size: 0.9rem;
    font-weight: 300;
    margin: 0;
  }

  input {
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    height: 2.5rem;
    padding: 0.5rem;
    width: 50%;
  }

  button {
    background-color: #fff;
    transition: 0.4s;

    &:hover {
      opacity: 0.7;
      background-color: #fff;
    }
  }

  @media screen and (max-width: 500px) {
    min-width: 23em;
    width: 20em !important;
    gap: 1rem;
    height: 30rem !important;
  }
`;

//MODALCONFIRMSHIPPING
export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  height: 520px;
  width: 500px;
`;

// Accredited
export const InputHolder = styled.div`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    margin-bottom: 1em;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin-bottom: 5%;
  }
`;

export const ContainerButtonsSeries = styled.div`
  display: flex;
`;

export const PdvBoxButton = styled.div`
  background-color: #2d70b9;
  padding: 1em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15em;
  margin-left: 2em;
  transition: 0.3s;

  @media screen and (max-width: 450px) {
    display: flex;
    margin-top: 5px;
    margin-left: 0;
    align-items: center;
    width: 75vw;
    height: 5vh;
    margin-bottom: 1.2em;
  }

  &:hover {
    filter: brightness(1.1);
  }
`;

export const BoxBalance = styled.div`
  width: 85vw;
  height: fit-content;
  border-radius: 10px;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 80vh;
    padding: 0;
    margin-left: 10%;
  }
`;

// RegisterAccredited

export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 4em;
  justify-content: space-between;
  width: 40%;
  margin-bottom: 1.5em;
  border-radius: 10px;
  height: 48em;

  textarea {
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 450px) {
    width: 90%;
    padding: 2%;
    margin-left: 8%;
  }
`;

export const Button = styled.button`
  color: white;
  background-color: #2d70b9;

  width: 10em;
  transition: 0.4s;

  &:hover {
    font-weight: 600;
    color: #fff;
  }

  @media screen and (max-width: 450px) {
    margin-left: 8%;
    margin-bottom: 5em;
  }
`;

export const Label = styled.label`
  color: ${(props) => (props.color ? '#fff' : '#000')};
  font-size: 0.8rem;
  font-weight: 300;
  margin: 0 0 0.2rem 0.2rem;
  width: 100%;
`;

export const Input = styled.input`
  margin-bottom: 0.5rem;
`;

export const TextArea = styled.textarea`
  margin-bottom: 0.5rem;
`;

export const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
`;

export const ContainerAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;

    span {
      width: 100%;
    }
  }
`;

// AccreditedIndividualPage

export const ContainerIndividualPage = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center;
  gap: 2rem;
`;

export const PdvBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  background-color: #2d70b9;
  border-left: 25px solid #ebb61f;
  border-radius: 10px;
  height: fit-content;
  max-width: 40%;
  padding: 1.5em;
  width: 45vw;

  @media screen and (max-width: 450px) {
    border-left: 12px solid #ebb61f;
    max-width: 100%;
    padding: 1em 0;
    width: 92%;
  }
`;

export const WhiteBoxOwner = styled.div`
  width: 32vw;
  height: fit-content;
  border-radius: 10px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.5em;
  flex-wrap: wrap;

  button {
    border: 0;
    background: transparent;
    padding: 0;
  }

  p {
    color: grey;
    font-size: 0.8em;
    margin: 0;
  }

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 450px) {
    width: 90vw;
    height: fit-content;
    margin-top: 1em;
    margin-left: 10%;
  }
`;

export const DataBox = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  @media screen and (max-width: 450px) {
    margin-left: 1em;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  @media screen and (max-width: 450px) {
    margin-bottom: -10px;
  }
`;

export const TextTitle = styled.p`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 0.9em;
  margin: 0;

  @media screen and (max-width: 450px) {
    font-size: 0.6em;
  }
`;

export const Text = styled.p`
  font-weight: 300;
  font-size: 0.9rem;
  @media screen and (max-width: 450px) {
    font-size: 0.8em;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @media screen and (max-width: 450px) {
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`;

export const ContainerButtonLinkEstablishment = styled.div`
  font-weight: 600;
  width: 75%;
  margin-top: 1rem;

  a {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    color: #000;
    text-decoration: none;
    width: 250px;

    transition: 0.4s;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const LinkEstablishmentButton = styled.button`
  background-color: #2e6fba;
  border: 1px solid #000;
  border-radius: 40px;
  height: 46px;
  width: 46px;
`;

export const ContainerTable = styled.div`
  width: 80%;
`;

//BindEstablishment

export const BindEstablishmentContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  width: 100%;
`;

export const ContentSearchOperator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 0.9rem;
    margin: 0.2rem;
  }
`;

export const InputSearchOperator = styled(TextField)`
  border-radius: 12px;
  width: 18rem;

  div {
    height: 3rem;
    border-radius: 8px;
  }
`;

export const TableEstablishmentUser = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  background: #2e6fba;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3) !important;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
  max-height: 14rem;
  padding: 0.5rem 1.6rem 0 1.6rem;
  overflow: auto;

  height: 14rem;
  width: 60%;

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    background: transparent;
    border: 0;
    font-size: 1.5rem;
    font-weight: 800;
    margin: 0;
    padding: 0;

    transition: transform 0.5s;

    &:hover {
      transform: scale(1.3);
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #f4f4f4; /* Cor do polegar */
    border-radius: 4px; /* Borda arredondada para o polegar */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Cor da faixa (trilha) */
    margin: 0.3rem;
  }
`;

export const DividerTable = styled.span`
  border: ${(props) => (props.color ? '1px solid #2e6fba' : '1px solid #fff')};
  width: 96%;
`;

export const DividerPage = styled.span`
  margin: 1.5rem 0 0 0;
  border: 1px solid #2e6fba;
  border-radius: 8px;
  width: 70%;
`;

export const ContentNoHaveEstablishment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
`;

export const ContentTextSelectAll = styled.span`
  display: flex;
  justify-content: ${(props) => (props.isButton ? 'space-between' : 'flex-start')};

  font-size: 1rem;
  font-weight: 700;
  transform: translateY(50%);
  width: 59%;

  button {
    background: transparent;
    border: 0;
    font-weight: 300;
  }
`;

export const VisibleScreenContent = styled.div`
  @keyframes aparecer {
    from {
      transform: translateX(-15%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  position: absolute;
  left: calc(2% + 10px);
  bottom: 2%;
  background: transparent;
  border: 3px solid #eebc33;
  border-left: 0;
  border-top: 0;
  border-right: 0;

  animation: aparecer 0.3s ease forwards;

  input {
    background: transparent;
    border: 0;
    min-width: 20rem;
  }
`;

// SENDCARTELA
export const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  padding: 0 1rem;
  width: 100%;

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    width: 23rem;
  }
`;

export const ContentSelectFractionedLote = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3) !important;
  padding: 0.6rem;
  width: 90%;

  @media screen and (max-width: 500px) {
    flex-direction: row;
  }
`;

export const ButtonFractionedLote = styled.button`
  background-color: ${(props) =>
    props.active ? transparentize(0.3, '#2e6fba') : '#a0a0a0'} !important;
  border-radius: 4px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.2) !important;
  color: ${(props) => props.active && '#000'} !important;
  font-size: 1.1rem !important;
  font-weight: 600;
  margin-right: 0.5rem;
  height: 2rem;
  padding: 0 !important;
  width: 2.5rem !important;

  transition: 0.3s;

  &:hover {
    color: ${(props) => (props.disabled ? null : '#fff')} !important;
    filter: brightness(0.8);
    pointer-events: auto;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')} !important;
  }
`;

export const ButtonSelectAccredited = styled.button`
  border: 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3) !important;

  width: 90% !important;

  svg {
    fill: #2e6fba;
    transition: 0.3s;
  }

  &:hover {
    svg {
      fill: #ebb61f;
      opacity: 1 !important;
    }
    opacity: 1 !important;
  }
`;

export const SelectMultipleAccredits = styled(TableEstablishmentUser)`
  background-color: #fff;
  border: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3) !important;
  color: #000;
  font-size: 0.6rem;
  max-height: 14rem;
  width: 35%;

  input {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
  }

  tr {
    justify-content: flex-start;
    margin-top: 0.4rem;
  }

  span {
    display: flex;
    flex-direction: column;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #a0a0a0; /* Cor do polegar */
    border-radius: 4px; /* Borda arredondada para o polegar */
  }

  @media screen and (max-width: 500px) {
    width: 100% !important;
    max-height: 8rem;
  }
`;

export const SelectedValuesAndAccredits = styled(SelectMultipleAccredits)`
  width: 50%;

  tr {
    justify-content: space-between;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const ContainerInstructions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3) !important;
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 1rem;
  padding: 1rem 0;
  min-width: 56em;

  div {
    font-weight: 600;
    margin: 0 0 0.2rem 1.2rem;
  }

  ul {
    display: flex;
    align-items: center;
    margin: 0;
    list-style: none;
    padding: 0 1rem;

    span {
      font-weight: 600;
      margin-right: 0.2rem;
    }

    b {
      margin: 0 0.2rem;
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 0.7rem;
    min-width: 23rem;
    gap: 0;

    span {
      width: 4rem;
    }
  }
`;

// SERIES

export const MessageAlert = styled.p`
  color: red;
  font-size: 0.7rem;
  margin-top: 0.5rem;
  text-align: center;
  strong {
    color: red;
    font-size: 0.9rem;
  }
`;

// LOWS

export const WhiteBoxLows = styled(WhiteBox)`
  align-items: center;
  justify-content: center;
  align-items: center;
  height: 20%;

  span {
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
`;

export const ContentRadios = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const BlueBoxLows = styled(BlueBox)`
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 40%;

  h5 {
    color: #fff;
    margin-bottom: 0;
  }

  input {
    border: 0;
    border-radius: 0.4rem;
    font-size: 1.8rem;
    height: 3.2rem;
    text-align: center;
    width: 13%;
  }
`;

export const Example = styled.p`
  color: #aaa;
  font-size: 0.7rem;
  letter-spacing: 1px;
  margin: 0;

  b {
    opacity: 0.7;
  }
`;

// CARTELASNOTACTIVY

export const ContainerRow = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;

  text-align: center;
`;

export const ContentBody = styled.span`
  display: flex;
  flex-direction: column;
  align-items: initial !important;

  h4,
  h5,
  h6 {
    letter-spacing: 1px;
    text-align: center;
  }
`;
