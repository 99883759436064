import styled from "styled-components";

export const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 10px;
  background-color: #2d70b9;
  width: 17em;
  height: 26em;
  @media screen and (max-width: 450px) {
    display: flex;
    margin-left: 5%;
    //margin: 2em;
    margin-bottom: 1em;
    flex-direction: row;
    padding: 20%;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    background-color: #2d70b9;
    height: 7em;
    width: 80vw;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 450px) {
    min-width: 100%;
  }
`;

export const GenericHeader = styled.div`
  color: white;
  min-width: 100%;
  font-size: 90%;
`;
export const GenericText = styled.div`
  color: white;
  font-size: 1.5em;
  font-weight: 300;
  width: 80%;
  @media screen and (max-width: 450px) {
    color: white;
    font-size: 50%;
    text-align: justify;
    font-weight: 300;
  }
`;
