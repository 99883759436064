import React, { useState } from 'react';
import { toast } from 'react-toastify';

//STYLES
import { ModalHeader, DivModal, HeaderText } from './style/ModalStyle';

// APIS
import { uploadFiles } from '../../services/APIs/uploadFiles';
import { SendImage } from '../SendImage/SendImage';

export function ModalUploadConfirmPaymentDistribuition({ close, open, action, fileName }) {
  const [image, setImage] = useState('');
  const [pathImage, setPathImage] = useState('');
  const [loading, setLoading] = useState(false);

  // SOBE A IMAGEM E ATUALIZA O STATUS
  const uploadImageAndConfirmPayment = async () => {
    setLoading(true);
    try {
      const infoImage = {
        mimeType: image.type,
        fileName: fileName || image.name,
        buffer: pathImage,
      };

      const updatePaymentVoucher = await uploadFiles('brasilcap-comprovante-liquidacao', infoImage);
      if (updatePaymentVoucher.data.success) {
        toast.success('Comprovante enviado com sucesso.');

        await action('liquidation');
        closeModal();
      } else {
        throw new Error('Algo ocorreu de errado ao enviar a imagem ao nosso banco de dados.');
      }
    } catch (err) {
      toast.error('Algo não funcionou corretamente, tente novamente mais tarde.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setImage('');
    setPathImage('');
    close();
  };

  return (
    <div className={`modal ${open ? 'modal is-active' : 'modal'}`}>
      <div onClick={closeModal} className="modal-background"></div>
      <DivModal style={{ minHeight: '60%', width: '35em' }} className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">Comprovante de pagamento</HeaderText>
        </ModalHeader>
        <div className="modal-card-body">
          <SendImage
            action={uploadImageAndConfirmPayment}
            labelButton="Enviar comprovante e liquidar"
            labelImage="Clique aqui e envie o comprovante de pagamento"
            labelTooltip="Remover comprovante"
            loading={loading}
            pathImage={pathImage}
            setImage={setImage}
            setPathImage={setPathImage}
          />
        </div>
      </DivModal>
    </div>
  );
}

