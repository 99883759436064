import React, { useState } from 'react';
import { RiPencilFill } from 'react-icons/ri';
import {
  OnSwitch,
  PencilButton,
  Select,
  TitleContigence,
} from './style/SelectOnSellerPage';
import { Stack, Switch } from '@mui/material';
import styled from '@emotion/styled';

import { authToken } from '../../services/APIs/token';
import { getAllSupervisors } from '../../services/getAll';
import { useQuery } from '@tanstack/react-query';

export const SwitchButton = styled(Switch)({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#EDB622',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    boxSizing: 'border-box',
  },
});

export default function UpdateSellerSupervisor({
  updateNameSupervisor,
  isDisabled,
  setIsDisabled,
  stateCodeSell,
  functionCodeSell,
}) {
  const [supervisorUid, setSupervisorUid] = useState();

  const { data, isError, isLoading } = useQuery({
    queryKey: ['supervisorsList'],
    queryFn: async () => {
      const token = await authToken();
      if (token) {
        const response = await getAllSupervisors(token);
        return response.data;
      }
    },
    keepPreviousData: true,
    retry: 1,
    refetchOnWindowFocus: false,
  });

  localStorage.setItem('selectedSupervisor', JSON.stringify(supervisorUid));

  if (isError)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Não existem vendedores cadastrados para este supervisor
      </div>
    );
  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        "Carregando..."
      </div>
    );

  const role = JSON.parse(localStorage.getItem('role'));
  const name = JSON.parse(localStorage.getItem('name'));

  return (
    <>
      <div
        style={{
          backgroundColor: 'inherit',
          color: 'white',
          border: 'none',
          display: 'flex',
        }}
      >
        {isDisabled ? (
          <select
            disabled
            style={{
              backgroundColor: 'inherit',
              color: 'white',
              border: 'none',
              display: 'flex',
            }}
          >
            <option>{localStorage.getItem('supervisorName')}</option>
          </select>
        ) : (
          <Select
            disabled={isDisabled}
            onChange={(e) => {
              localStorage.setItem(
                'supervisorName',
                data.response.find((elem) => elem.Data.uid === e.target.value)
                  .name
              );
              localStorage.setItem('super', e.target.value);
              setSupervisorUid(e.target.value);
              updateNameSupervisor(e.target.value);
            }}
            defaultValue={isDisabled ? localStorage.getItem('super') : ''}
          >
            {role !== 'manager' ? (
              <option style={{ color: 'black', border: 'none' }}>
                {name === 'undefined' ? 'Selecione um supervisor' : name}
              </option>
            ) : (
              <>
                <option
                  selected={true}
                  value=""
                  style={{
                    color: 'black',
                    border: 'none',
                  }}
                >
                  Selecione uma opção
                </option>
                {data.response
                  .filter((e) => e.Data.role !== 'manager')
                  .map((supervisor) => (
                    <option
                      style={{
                        color: 'black',
                        border: 'none',
                      }}
                      key={supervisor.Data.uid}
                      value={!supervisor.Data.uid ? '' : supervisor.Data.uid}
                    >
                      {supervisor.name}
                    </option>
                  ))}
              </>
            )}
          </Select>
        )}
      </div>
      <div>
        <PencilButton onClick={() => setIsDisabled(false)}>
          <RiPencilFill />
        </PencilButton>

        <TitleContigence>Modo de Contingência</TitleContigence>
        <Stack direction="row" spacing={1} alignItems="center">
          <OnSwitch>Não</OnSwitch>
          <SwitchButton
            checked={stateCodeSell}
            onChange={functionCodeSell}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <OnSwitch>Sim</OnSwitch>
        </Stack>
      </div>
    </>
  );
}
