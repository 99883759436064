import Footer from '../components/Footer/Footer';
import React, { useEffect, useState } from 'react';
import HeaderComp from '../components/Header/HeaderComp';
import SideBarDesktop from '../components/Sidebar/SideBarComp';
import { Content, InsideContent } from '../styled/generic';
import Logo from '../assets/images/LogoSquareV10.png';
import {
  BlueBox,
  SmallWhiteBox,
  LogoBlueBox,
  LabelBlueBox,
  WhiteBox,
  Input,
  SmallLabel,
  Button,
  Form,
  DivInputBlueBox,
} from '../styled/RegisterStyle';
import ReactInputMask from 'react-input-mask';
import ModalRegister from '../components/Modals/ModalRegister';
import { verifyUserPhone } from '../services/login';
import { authToken, authTokenWithSC } from '../services/APIs/token';
import { Oval } from 'react-loading-icons';
import { createSupervisor } from '../services/register';
import { toast } from 'react-toastify';
import { findIndexInArray } from '../services/helpers';
import { useNavigate } from 'react-router-dom';

export default function SupervisorRegister() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [setCanShow] = useState(false);
  const [user, setUser] = useState();
  const [id, setId] = useState();
  const [token, setToken] = useState('');

  const userLogged = JSON.parse(localStorage.getItem('name'));

  const navigate = useNavigate();

  useEffect(() => {
    setToken(authToken());
    if (phoneNumber.length === 11) {
      getSellerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  const getSellerData = async () => {
    const token = await authTokenWithSC();
    setIsLoading(true);
    const response = await verifyUserPhone(phoneNumber, token);
    setIsLoading(false);
    setId(response.data.response.id);
    setUser(
      response.data.response.partnerList[
        findIndexInArray(
          response.data.response.partnerList,
          'partnerName',
          'BrasilCap'
        )
      ]
    );
    setCanShow(true);
  };

  const superReg = async () => {
    const response = await createSupervisor(id, 'supervisor', token);
    if (response.data.error === 'Supervisor já existente.') {
      toast.error('Supervisor já cadastrado!');
    } else {
      toast.success('Supervisor cadastrado!');
      navigate('/lista-supervisor');
    }
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              superReg();
            }}
          >
            <BlueBox>
              <LogoBlueBox src={Logo} />
              <DivInputBlueBox>
                <LabelBlueBox className="label" htmlFor="tel">
                  Cadastro de novo vendedor(a)
                </LabelBlueBox>
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Oval stroke="white" />
                  </div>
                ) : (
                  <ReactInputMask
                    mask="99 99999-9999"
                    maskChar=""
                    alwaysShowMask={false}
                    id="tel"
                    name="tel"
                    type="tel"
                    placeholder="Telefone"
                    className="input is-fullwidth is-small"
                    value={phoneNumber}
                    onChange={(e) => {
                      const cleaned = e.target.value.replace(' ', '');
                      const removeSpace = cleaned.replace('-', '');
                      setPhoneNumber(removeSpace);
                    }}
                  />
                )}
              </DivInputBlueBox>
            </BlueBox>
            <WhiteBox className="box">
              <SmallLabel className="label">
                Dados Pessoais
                <Input
                  value={user && user.name}
                  type="text"
                  placeholder="Nome"
                  className={
                    window.screen.width > 450
                      ? 'input is-fullwidth'
                      : 'input is-fullwidth is-small'
                  }
                  disabled
                />
              </SmallLabel>
              <ReactInputMask
                mask="999.999.999-99"
                style={{
                  marginBottom: '1em',
                }}
                value={
                  user &&
                  user.documentList.find((el) => el.type === 'cpf').value
                }
                type="text"
                placeholder="CPF"
                className={
                  window.screen.width > 450
                    ? 'input is-fullwidth'
                    : 'input is-fullwidth is-small'
                }
                disabled
              />
              <Input
                defaultValue={
                  user &&
                  user.contactList.find((el) => el.type === 'email').value
                }
                type="email"
                placeholder="E-mail"
                className={
                  window.screen.width > 450
                    ? 'input is-fullwidth'
                    : 'input is-fullwidth is-small'
                }
                disabled
              />
            </WhiteBox>
            <SmallWhiteBox className="box">
              <input
                value={userLogged}
                placeholder="Gerente responsável"
                className={
                  window.screen.width > 450
                    ? 'input is-fullwidth'
                    : 'input is-fullwidth is-small'
                }
                disabled
              />
            </SmallWhiteBox>
            <Button
              type="submit"
              className={
                window.screen.width > 450 ? 'button' : 'button is-small'
              }
              disabled={!user}
            >
              Salvar
            </Button>
          </Form>
          <ModalRegister />
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
