import { Typography } from "@mui/material";
import styled from "styled-components";

export const Select = styled.select`
  background-color: inherit;
  display: flex;
  color: white;
  border: none;
  :disabled {
    background-color: #2d70b9;
  }
`;
export const PencilButton = styled.button`
  background-color: white;
  border: none;
  color: #2d70b9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 0;
`;

export const TitleContigence = styled(Typography)`
  font-size: 0.9rem !important;
`;

export const OnSwitch = styled(TitleContigence)`
  font-weight: bold !important;
`;
