import axios, { AxiosError } from "axios";
import { configJson } from "../library/utils/firebaseUtils";

export const apiV2 = axios.create({
  baseURL: configJson.API_HOST_V2,
});

export const updateAffiliation = async (token, uid, status) => {
  try {
    const response = await apiV2.patch(
      `/affiliations`,
      {
        uid,
        affiliation: {
          affiliationName: "BrasilCap",
          status,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    return AxiosError;
  }
};
