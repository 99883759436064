import { GenericText, Content, InsideContent } from "../../styled/generic";
import { useLocation } from "react-router-dom";
import "../../css/Input.css";
import HeaderComp from "../../components/Header/HeaderComp";
import SideBarDesktop from "../../components/Sidebar/SideBarComp";
import { ProfileBox, InputHolder } from "../../components/Tables/style/SellerListStyle";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { BoxBalance } from "../../pages/SellerPage/style/SellerPageStyle";
import React, { useState, useEffect } from "react";
import TestimonyTable from "../../components/Tables/testimonyTable";

export default function TestimonyList() {
  const location = useLocation();
  const [selected, setSelected] = useState(true);

  useEffect(() => {}, [selected]);
  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent theme={location.pathname} id="inside-content">
          <InputHolder id="inputHolder">
            <Link
              style={{
                textDecoration: "none",
                marginBottom: "1em",
              }}
              to={"/administracao/depoimento"}>
              <ProfileBox>
                <GenericText>Adicionar novo depoimento</GenericText>
              </ProfileBox>
            </Link>
          </InputHolder>
          <BoxBalance>
            <label  style={{ marginBottom: '1em'}}>
              Selecionar status:&nbsp;
              <select onChange={(e) => setSelected(!selected)}>
                <option value={true}>Depoimentos ativos</option>
                <option value={false}>Depoimentos inativos</option>
              </select>
            </label>
            <TestimonyTable selected={selected} setSelected={setSelected} />
          </BoxBalance>
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
