//STYLES
import { Content, InsideContent } from '../../styled/generic';

//COMPONENTS
import HeaderComp from '../../components/Header/HeaderComp';
import SideBarDesktop from '../../components/Sidebar/SideBarComp';
import {
  ButtonAdministration,
  ContainerAdministration,
} from '../Administration/style/Adminstration';
import { Link } from 'react-router-dom';

export function DistribuitionCartelasList() {
  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <ContainerAdministration>
            <Link to="/operacao-cartela/gerenciar-series">
              <ButtonAdministration>Gerenciar séries</ButtonAdministration>
            </Link>
            <Link to="/operacao-cartela/gerenciar-credenciados">
              <ButtonAdministration>Gerenciar credeciados</ButtonAdministration>
            </Link>
          </ContainerAdministration>
        </InsideContent>
      </Content>
    </>
  );
}
