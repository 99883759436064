import styled from "styled-components";

export const FooterStyle = styled.div`
  display: none;
  @media screen and (max-width: 450px) {
    background-color: #2d70b9;
    display: flex;
    height: 5em;
    width: 100vw;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;
    margin: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    position: fixed;
    z-index: 9999;
  }
`;
