// import React, { useEffect } from 'react';
// import { toast } from 'react-toastify';

//STYLES
import {
  ModalHeader,
  ModalBody,
  DivModal,
  HeaderText,
  SendCartelaButton,
} from './style/ModalStyle';
import { CircularProgress } from '@mui/material';

export default function ModalGenericConfirmProccess({
  action, // API OU FUNÇÃO DO BOTÃO DE CONFIRMAR
  actionCancelation, // DEFINE O QUE FAZER QUANDO CANCELAR
  body, // MENSAGEM DO CORPO DO MODAL
  canceled, // TRUE OU FALSE SE DESEJAR QUE EXIBA UM BOTÃO DE CANCELAR
  close, // FECHA MODAL
  labelButton, // LABEL DO BOTÃO DE CONFIRMAR
  labelCancelation, // LABEL DO BOTÃO DE CANCELAR
  open, // ESTADO DO MODA
  title, // TITULO DO MODAL
  message, // MESSAGEM DE ERRO.
  setMessage, // ALTERA MESSAGEM DE ERRO
  loading, // LOADING
}) {
  const handleCloseModal = () => {
    close();
    message !== null && message !== undefined && setMessage('');
  };

  return (
    <div className={`modal ${open ? 'modal is-active' : 'modal'}`}>
      <div onClick={handleCloseModal} className="modal-background"></div>
      <DivModal style={{ minHeight: '12rem' }} className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">{title}</HeaderText>
        </ModalHeader>
        <ModalBody className="modal-card-body">
          {message !== '' && message !== undefined && message !== null && (
            <p style={{ color: 'red', fontSize: '0.9rem', textAlign: 'center' }}>{message}</p>
          )}
          <h4 style={{ textAlign: 'center' }}>{body}</h4>
          <span style={{ display: 'flex', gap: '1rem' }}>
            <SendCartelaButton
              onClick={action}
              className={window.screen.width > 450 ? 'button' : 'button is-small'}
              disabled={loading}
              variant="contained"
            >
              {loading ? (
                <CircularProgress style={{ color: '#fff' }} size={26} thickness={5} />
              ) : (
                labelButton
              )}
            </SendCartelaButton>
            {canceled && (
              <SendCartelaButton
                disabled={loading}
                onClick={actionCancelation ? actionCancelation : close}
                variant="contained"
                style={{ background: '#E5AA1A' }}
              >
                {labelCancelation}
              </SendCartelaButton>
            )}
          </span>
        </ModalBody>
      </DivModal>
    </div>
  );
}
