import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { authToken } from '../../services/APIs/token';
import { getOpenLeads, getValidCode } from '../../services/salesData';
import { Oval } from 'react-loading-icons';
import { useMutation, useQuery } from '@tanstack/react-query';

export default function SingleSell() {
  const { id } = useParams();
  const [timer, setTimer] = useState(0);
  const [showButton, setShowButton] = useState(false);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const {
    data: activeSell,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['singleSell'],
    queryFn: async () => {
      const superUId = JSON.parse(localStorage.getItem('uid'));
      const token = await authToken(superUId);
      if (token) {
        const response = await getOpenLeads(id, token);
        if (response) {
          return response.data.result;
        }
      }
    },
    keepPreviousData: true,
    retry: 1,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      localStorage.setItem('LeadIdCode', JSON.stringify(activeSell?.leadId));
    },
  });

  const {
    mutate: getCode,
    isLoading: isLoadingCode,
    data: code,
  } = useMutation({
    mutationKey: ['smsCode'],
    mutationFn: async () => {
      const superUId = JSON.parse(localStorage.getItem('uid'));
      const token = await authToken(superUId);
      if (token) {
        const response = await getValidCode(activeSell.leadId, token);
        return response.data.result.code;
      }
    },
    onSuccess: () => {
      setTimer(5 * 60);
      setShowButton(true);
    },
  });

  useEffect(() => {
    if (timer === 0) {
      setTimer(0);
    } else {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  return (
    <>
      <div
        className="box"
        style={{
          flexWrap: 'wrap',
          display: 'flex',
          margin: '1.5em',
          alignSelf: 'center',
          justifySelf: 'center !important',
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Oval stroke="#EDB622" />
          </div>
        ) : activeSell === null || isError ? (
          <div
            style={{ width: '80vw', display: 'flex', justifyContent: 'center' }}
          >
            Não existem vendas registradas nas últimas 3 horas
          </div>
        ) : (
          <table className="table is-fullwidth">
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Email</th>
              <th>Valor</th>
              <th>Código</th>
            </tr>
            <tr>
              <th>
                {activeSell && activeSell.name
                  ? activeSell.name
                  : 'Ainda não consta o nome'}
              </th>
              <th>
                {activeSell &&
                  activeSell.cpf.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                    '$1.$2.$3-$4'
                  )}
              </th>
              <th>
                {activeSell && activeSell.email
                  ? activeSell.email
                  : 'Ainda não consta o email'}
              </th>
              <th>
                {activeSell &&
                  activeSell.orderPrice.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
              </th>
              <th>
                {!showButton ? (
                  !isLoadingCode ? (
                    <button onClick={getCode} className="button is-small">
                      Exibir código
                    </button>
                  ) : (
                    <Oval stroke="#EDB622" />
                  )
                ) : (
                  <>
                    <span>{code}</span>
                    {timer !== 0 ? (
                      <span
                        style={{
                          fontSize: '0.8em',
                          fontWeight: '400',
                          color: 'blue',
                        }}
                      >
                        Código expira em:&nbsp;
                        {minutes.toString().padStart(2, '0')}:
                        {seconds.toString().padStart(2, '0')}
                      </span>
                    ) : (
                      <button
                        className="button is-small is-ghost"
                        onClick={getCode}
                      >
                        Renovar código
                      </button>
                    )}
                  </>
                )}
              </th>
            </tr>
          </table>
        )}
      </div>
    </>
  );
}
