import { SendCartelaButton } from '../../../../components/Modals/style/ModalStyle';
import { ContentBody } from '../../styles/DistribuitionCartelasStyle';

export const StepAlertStopSellingDate = ({ navigate, setFinishedSerieIsEnable }) => {
  return (
    <ContentBody>
      <h4>Atenção:</h4>
      <h5>
        Você está prestes a baixar cartelas antes da{' '}
        <strong style={{ color: '#2D70B9' }}>Data limite para vendas</strong>.
      </h5>
      <h5>Após a baixa, as cartelas não poderão mais ser vendidas.</h5>
      <>
        <SendCartelaButton
          onClick={() => setFinishedSerieIsEnable(true)}
          variant="contained"
          $spacingTop="1rem"
        >
          Prosseguir
        </SendCartelaButton>
        <SendCartelaButton
          onClick={() => navigate(-1)}
          variant="contained"
          $background="#E5AA1A"
          $spacingTop="0.5rem"
        >
          Cancelar
        </SendCartelaButton>
      </>
    </ContentBody>
  );
};
