import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//Styles
import { ContainerDistribuition } from '../styles/DistribuitionCartelasStyle';
import { Content } from '../../../styled/generic';
import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//Components
import DistribuitionCartelaTable from '../../../components/Tables/DistribuitionCartelaTable';
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import ModalReceivedCartelas from '../../../components/Modals/ModalReceivedCartelas';
import ModalCancelationCartela from '../../../components/Modals/ModalCancelationCartela';
import { ModalTransferCartela } from '../../../components/Modals/ModalTransferCartela';
import { DistribuitionCartelaContext } from '../../../context/distribuitionCartela';

export function DistribuitionCartelas() {
  const { serie, distrEndDate, serieUrl } = useContext(DistribuitionCartelaContext);
  const distrEndDateLocal = JSON.parse(localStorage.getItem('distrEndDate'));
  const navigate = useNavigate();
  const distributed = JSON.parse(localStorage.getItem('distributed'));
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCancelationIsOpen, setModalCancelationIsOpen] = useState(false);
  const [modalTransferIsOpen, setModalTransferIsOpen] = useState(false);
  const [idRemessa, setIdRemessa] = useState('');
  const [deliveryId, setDeliveryId] = useState('');
  const [accreditedId, setAccreditedId] = useState('');
  const [cnpj, setCnpj] = useState('');

  //CHAMAR API DE EXIBIR PONTO DE VENDAS, QUANTIDADE E DATA DE ENVIOS
  useEffect(() => {
    if (distributed) {
      toast.success('Solicitação efetuada com sucesso.');
      localStorage.removeItem('distributed');
    }
  }, [distributed]);

  const hanleClick = () => {
    navigate(
      `/operacao-cartela/gerenciar-series/reservar-cartelas/tipo-reserva/${
        serie === '' ? serieUrl : serie
      }`
    );
  };

  const handleOpenModal = (type, value, value2) => {
    switch (type) {
      case 'received':
        setModalIsOpen(true);
        setIdRemessa(value);
        setAccreditedId(value2);
        break;
      case 'cancelation':
        setModalCancelationIsOpen(true);
        setDeliveryId(value);
        break;
      case 'transfer':
        setModalTransferIsOpen(true);
        setDeliveryId(value);
        setCnpj(value2);
        break;
      default:
        break;
    }
  };

  const handleCloseModal = (type) => {
    switch (type) {
      case 'received':
        setModalIsOpen(false);
        break;
      case 'cancelation':
        setModalCancelationIsOpen(false);
        break;
      case 'transfer':
        setModalTransferIsOpen(false);
        break;
      default:
        break;
    }
  };
  const verifyDistributionIsActive = () => {
    const dateIsNow = new Date();
    const timeStampNow = dateIsNow.getTime();
    if (timeStampNow < (distrEndDate === 0 ? distrEndDateLocal : distrEndDate)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <ModalReceivedCartelas
        isOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        remessa={idRemessa}
        accreditedId={accreditedId}
      />
      <ModalCancelationCartela
        isOpen={modalCancelationIsOpen}
        handleCloseModal={handleCloseModal}
        deliveryId={deliveryId}
      />
      <ModalTransferCartela
        isOpen={modalTransferIsOpen}
        handleCloseModal={handleCloseModal}
        deliveryId={deliveryId}
        oldPdvId={cnpj}
      />
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <ContainerDistribuition>
          {verifyDistributionIsActive() && (
            <Button
              color="primary"
              variant="contained"
              endIcon={<ChevronRightIcon />}
              onClick={hanleClick}
            >
              RESERVAR
            </Button>
          )}
          <DistribuitionCartelaTable
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            isOpen={modalIsOpen}
            verifyDistributionIsActive={verifyDistributionIsActive}
          />
        </ContainerDistribuition>
      </Content>
    </>
  );
}
