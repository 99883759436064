import CryptoJS from "crypto-js";

export function encrypt(msg, key) {
    if (!msg) return null;
  
    const myEncrypt = {
      pass: CryptoJS.enc.Utf8.parse(key),
      iv: CryptoJS.enc.Hex.parse("0000000000000000"),
    };
  
    let options = {
      mode: CryptoJS.mode.CBC,
      iv: myEncrypt.iv,
    };
  
    let json = CryptoJS.AES.encrypt(msg, myEncrypt.pass, options);
  
    return json.ciphertext.toString(CryptoJS.enc.Base64);
  }
  
  export function decrypt(msg, key) {
    if (!msg) return null;
  
    msg = decodeURIComponent(msg);
  
    const myEncrypt = {
      pass: CryptoJS.enc.Utf8.parse(key),
      iv: CryptoJS.enc.Hex.parse("0000000000000000"),
    };
  
    let options = {
      mode: CryptoJS.mode.CBC,
      iv: myEncrypt.iv,
    };
  
    let json = CryptoJS.AES.decrypt(
      {
        ciphertext: CryptoJS.enc.Base64.parse(msg),
      },
      myEncrypt.pass,
      options
    );
    return json.toString(CryptoJS.enc.Utf8);
  }