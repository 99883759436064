import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/auth';
import Logo from '../../assets/images/LogoSquareV10@2x.png';
import LogoMobile from '../../assets/images/LogoBlueBG@2x.png';
import {
  BoxLogin,
  GenericText,
  ImageLogo,
  InputHeaderText,
  AddressTextMobile,
  ImageLogoMobile,
  FormLoginStyle,
} from '../../pages/Login/style/Login';
import { validateSMS } from '../../services/login';
import { Oval } from 'react-loading-icons';
import { useNavigate } from 'react-router-dom';
import InputPhone from './InputPhone';
import InputCode from './InputCode';

export default function FormLogin() {
  const navigate = useNavigate();
  const uId = JSON.parse(localStorage.getItem('uid'));

  const { login, setRedirect } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [onValidate, setOnValidate] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);

  const transactionId = JSON.parse(localStorage.getItem('transid'));

  const handleSubmit = async (e, isForm) => {
    if (isForm) {
      e.preventDefault();
      localStorage.setItem('telNmbr', phoneNumber);
    }
    const phoneNumberLocal = localStorage.getItem('telNmbr');
    setIsLoading(true);
    const result = await login(phoneNumberLocal);
    setPhoneNumber(phoneNumberLocal);

    if (!result) {
      setInvalidLogin(true);
      if (localStorage.getItem('errorSMS')) {
        toast.error(
          'Número de tentativas excedidas. Tente novamente em 15 minutos.'
        );
        localStorage.removeItem('errorSMS');
      } else {
        toast.error('Telefone não encontrado');
      }
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setOnValidate(true);
      setIsLoading(false);
    }
  };

  const handleRedirect = async (e) => {
    e.preventDefault();

    if (code === '') {
      setInvalidLogin(true);
      toast.error('Digite o código');
      return;
    }

    setIsLoading(true);

    try {
      const result = await validateSMS(uId, code, phoneNumber, transactionId);

      if (result.success) {
        setIsLoading(false);
        setInvalidLogin(false);
        setRedirect(true);
        localStorage.setItem('redirect', JSON.stringify(true));
        navigate('/dashboard');
      } else {
        setInvalidLogin(true);
        toast.error('Algo ocorreu de errado, tente novamente mais tarde!');
      }
    } catch (error) {
      if (error.includes('Invalid code!')) {
        setInvalidLogin(true);
        toast.error('Código inválido!');
      } else {
        setInvalidLogin(true);
        toast.error('Algo ocorreu de errado, tente novamente mais tarde!');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {invalidLogin && <ToastContainer />}
      <BoxLogin className={window.screen.width > 450 ? 'box' : ''}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ImageLogo src={Logo} />
          <ImageLogoMobile src={LogoMobile} />
        </div>
        {isLoading ? (
          <Oval stroke="#EDB622" />
        ) : (
          <FormLoginStyle>
            <InputHeaderText>Fazer Login</InputHeaderText>
            {onValidate ? (
              <GenericText>
                Dentro de alguns segundos você receberá
                <br />
                um código de autenticação por SMS
              </GenericText>
            ) : (
              <GenericText>
                Informe seu telefone para a
                <br />
                gente te identificar
              </GenericText>
            )}
            {onValidate && (
              <InputCode
                phoneNumber={phoneNumber}
                setCode={setCode}
                code={code}
                handleRedirect={handleRedirect}
                handleSubmit={handleSubmit}
              />
            )}
            {!onValidate && (
              <>
                <InputPhone
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  handleSubmit={handleSubmit}
                />
              </>
            )}
          </FormLoginStyle>
        )}
      </BoxLogin>
      <AddressTextMobile>
        Av. Brigadeiro Faria Lima, 5544, 15090-000 - São José do Rio Preto
      </AddressTextMobile>
    </>
  );
}
