import { PowerBIEmbed } from 'powerbi-client-react';
import { Oval } from 'react-loading-icons';
import { models } from 'powerbi-client';
import { getPowerBi } from '../services/powerBi';
import React, { useEffect, useState } from 'react';
import { authToken } from '../services/APIs/token';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function PowerBI() {
  const [data, setData] = useState(null);
  const [relatorySelected, setRelatorySelected] = useState('');
  const [embedSelected, setEmbedSelected] = useState(null);

  const getToken = async () => {
    const uid = JSON.parse(localStorage.getItem('uid'));
    const token = await authToken(uid);
    const response = await getPowerBi(token);
    setData(response?.data?.embedInfo);
  };

  const handleChangeSelectRelatory = (e) => {
    const selectedReportName = e.target.value;
    const selectedReport = data.find(
      (embed) => embed.embedUrl[0].reportName === selectedReportName
    );
    setRelatorySelected(selectedReportName);
    setEmbedSelected(selectedReport);
  };
  useEffect(() => {
    // Define o valor inicial como o primeiro relatório, se houver dados disponíveis
    if (data && data.length > 0) {
      setRelatorySelected(data[0]?.embedUrl[0].reportName);
      setEmbedSelected(data[0]);
    }
  }, [data]);

  useEffect(() => {
    getToken();
  }, []);

  if (!data)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
          width: '100%',
        }}
      >
        <Oval stroke="#EDB621" />
      </div>
    );
  else
    return (
      <>
        <div style={{ height: '100%', width: '100%' }}>
          <FormControl
            sx={{ m: 1, minWidth: 120 }}
            size="small"
            style={{ margin: '0 3rem', width: '15%' }}
          >
            <p style={{ fontWeight: 600, margin: '2px' }}>Selecionar Relatório:</p>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={relatorySelected}
              onChange={handleChangeSelectRelatory}
            >
              {data.map((embed) => (
                <MenuItem key={embed?.embedUrl[0].reportId} value={embed?.embedUrl[0].reportName}>
                  {embed?.embedUrl[0].reportName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {
            <PowerBIEmbed
              style={{ height: '80vh' }}
              cssClassName="iframe-powerBI"
              key={embedSelected && embedSelected.embedUrl[0].reportId}
              embedConfig={{
                type: 'report', // Supported types: report, dashboard, tile, visual and qna
                id: embedSelected && embedSelected.embedUrl[0].reportId,
                embedUrl: embedSelected && embedSelected.embedUrl[0].embedUrl,
                accessToken: embedSelected && embedSelected.accessToken,
                tokenType: models.TokenType.Embed,
              }}
            />
          }
        </div>
      </>
    );
}

