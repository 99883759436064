import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { DistribuitionCartelaContext } from '../../../context/distribuitionCartela';

// STYLES
import {
  ContentNoHave,
  SearchIcon,
  SearchInput,
  SearchInputContainer,
  TableDataInfo,
} from '../../../components/Modals/style/ModalStyle';
import {
  ButtonFractionedLote,
  ButtonSelectAccredited,
  ContainerInstructions,
  ContentSelectFractionedLote,
  DividerTable,
  Main,
  SelectMultipleAccredits,
  SelectedValuesAndAccredits,
  SendBox,
} from '../styles/DistribuitionCartelasStyle';
import { Content, InsideContent } from '../../../styled/generic';
import { HiSearchCircle } from 'react-icons/hi';
import { Button, CircularProgress } from '@mui/material';
import { MdDoubleArrow } from 'react-icons/md';

// COMPONENTS
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import HeaderComp from '../../../components/Header/HeaderComp';

// APIS
import { listAccrediteds } from '../../../services/APIs/listAccrediteds';
import { distributionCards } from '../../../services/APIs/distributionCards';

export const SendCartelas = () => {
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);
  const navigate = useNavigate();
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [accredited, setAccredited] = useState([]);
  const [cartelasValue, setCartelasValue] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [received, setReceived] = useState([]);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedOptionAccredited, setSelectedOptionAccredited] =
    useState(null);

  //PEGA OS CREDENCIADOS
  useEffect(() => {
    setLoading(true);
    listAccrediteds(uId)
      .then((accredit) => {
        const data = accredit.response
          .map((info) => {
            const item = Object.values(info)[0];
            if (item.status) {
              return {
                name: item.name,
                address: item.address,
                establishments: item.establishments,
                id: Object.keys(info)[0],
              };
            }
            return null;
          })
          .filter((item) => item !== null);
        setAccredited(data);
      })
      .catch(() => {
        toast.error('Algo de errado ocorreu ao buscar os credenciados.');
        console.log('ERROR API ==> listAccrediteds');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uId]);

  // FILTRA OS CREDENCIADOS
  useEffect(() => {
    if (search.length > 1) {
      const filteredResults = accredited.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  }, [search, accredited]);

  const handleAccreditedSelected = (e) => {
    setSelectedOptionAccredited(e.target.value);
  };

  const sumOfCards = (value) => {
    setCurrentValue(value);
  };

  // ENVIA O VALOR E O CREDENCIADO PRO ARRAY DE SELEÇÃO
  const addAccreditedAndCartelas = () => {
    const accreditedInfo = accredited.filter(
      (item) => item.name === selectedOptionAccredited
    )[0];

    const index = accredited.findIndex(
      (element) => element.name === selectedOptionAccredited
    );

    const updatedAccredited = [...accredited];
    updatedAccredited.splice(index, 1);

    setCartelasValue(cartelasValue + currentValue);
    setReceived([
      ...received,
      { cartelaValue: currentValue, accredited: accreditedInfo },
    ]);
    setAccredited(updatedAccredited);
    setSelectedOptionAccredited(null);
    setCurrentValue(0);
  };

  const removeSelectedAccredited = (index) => {
    const removedAccredited = received[index].accredited;
    const returnValue = received[index].cartelaValue;

    const updatedAccredited = [...received];
    updatedAccredited.splice(index, 1);

    setReceived(updatedAccredited);
    setAccredited([removedAccredited, ...accredited]);
    setCartelasValue(cartelasValue - returnValue);
  };

  const sendFractionedCartelas = async () => {
    setLoadingSend(true);
    const payload = {
      fractioned: true,
      distributionInfo: [],
      serie: serie === '' ? serieUrl : serie,
    };

    received.map((item) => {
      const { cartelaValue } = item;
      const pdvId = item.accredited.id;

      return payload.distributionInfo.push({ quantity: cartelaValue, pdvId });
    });

    const result = await distributionCards(uId, payload);

    if (result.success) {
      setLoadingSend(false);

      localStorage.setItem('distributed', true);
      navigate(-2);
    } else {
      setLoadingSend(false);

      toast.error('Algo ocorreu de errado');
    }
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          {/* Escolhe o tipo de reserva */}
          <SendBox>
            <spam>
              <h5>Reserva fracionada</h5>
            </spam>
            <SearchInputContainer>
              <SearchInput
                autocomplete="off"
                name="randon_not_complete"
                type="text"
                placeholder="Pesquisar credenciado"
                onChange={(e) => setSearch(e.target.value)}
              />
              <SearchIcon>
                <HiSearchCircle size="25px" />
              </SearchIcon>
            </SearchInputContainer>
            <Main>
              <SelectMultipleAccredits style={{ width: '35%' }}>
                {!loading ? (
                  search.length > 1 ? (
                    searchResults.map((item) => (
                      <>
                        <table key={item.name}>
                          <tbody>
                            <tr>
                              <input
                                type="radio"
                                value={item.name}
                                checked={selectedOptionAccredited === item.name}
                                onChange={handleAccreditedSelected}
                                disabled={
                                  item.establishments.length === 0 ||
                                  item.establishments.every(
                                    (estab) =>
                                      Object.values(estab)[0].status === false
                                  )
                                }
                              />
                              <span>
                                <TableDataInfo>{item.name}</TableDataInfo>
                                <TableDataInfo>{item.address}</TableDataInfo>
                              </span>
                            </tr>
                          </tbody>
                        </table>
                        <DividerTable color style={{ width: '100%' }} />
                      </>
                    ))
                  ) : (
                    accredited.map((item) => (
                      <>
                        <table key={item.name}>
                          <tbody>
                            <tr>
                              <input
                                type="radio"
                                value={item.name}
                                checked={selectedOptionAccredited === item.name}
                                onChange={handleAccreditedSelected}
                                disabled={
                                  item.establishments.length === 0 ||
                                  item.establishments.every(
                                    (estab) =>
                                      Object.values(estab)[0].status === false
                                  )
                                }
                              />
                              <span>
                                <TableDataInfo>{item.name}</TableDataInfo>
                                <TableDataInfo>{item.address}</TableDataInfo>
                              </span>
                            </tr>
                          </tbody>
                        </table>
                        <DividerTable color style={{ width: '100%' }} />
                      </>
                    ))
                  )
                ) : (
                  <ContentNoHave>
                    <CircularProgress
                      size="35px"
                      style={{ color: '#1565b4' }}
                    />
                  </ContentNoHave>
                )}
              </SelectMultipleAccredits>
              <span>
                {/* <p>Selecione a quantidade de cartelas</p> */}
                <ContentSelectFractionedLote>
                  <ButtonFractionedLote
                    value={20}
                    active={currentValue === 20}
                    onClick={() => sumOfCards(20)}
                    disabled={
                      cartelasValue > 80 || selectedOptionAccredited === null
                    }
                  >
                    20
                  </ButtonFractionedLote>
                  <ButtonFractionedLote
                    value={40}
                    active={currentValue === 40}
                    onClick={() => sumOfCards(40)}
                    disabled={
                      cartelasValue > 60 || selectedOptionAccredited === null
                    }
                  >
                    40
                  </ButtonFractionedLote>
                  <ButtonFractionedLote
                    value={60}
                    active={currentValue === 60}
                    disabled={
                      cartelasValue > 40 || selectedOptionAccredited === null
                    }
                    onClick={() => sumOfCards(60)}
                  >
                    60
                  </ButtonFractionedLote>
                  <ButtonFractionedLote
                    value={80}
                    active={currentValue === 80}
                    onClick={() => sumOfCards(80)}
                    disabled={
                      cartelasValue > 20 || selectedOptionAccredited === null
                    }
                  >
                    80
                  </ButtonFractionedLote>
                </ContentSelectFractionedLote>
                <ButtonSelectAccredited
                  disabled={
                    selectedOptionAccredited === null || currentValue === 0
                  }
                  onClick={addAccreditedAndCartelas}
                >
                  <MdDoubleArrow size="35px" />
                </ButtonSelectAccredited>
              </span>

              <SelectedValuesAndAccredits>
                {received.map((item, index) => (
                  <>
                    <table key={item.accredited.id}>
                      <tbody>
                        <tr>
                          <span>
                            <TableDataInfo>
                              {item.cartelaValue} cart.
                            </TableDataInfo>
                          </span>
                          <span>
                            <TableDataInfo>
                              {item.accredited.name}
                            </TableDataInfo>
                            <TableDataInfo>
                              {item.accredited.address}
                            </TableDataInfo>
                          </span>
                          <button
                            style={{ color: 'red' }}
                            onClick={() => removeSelectedAccredited(index)}
                          >
                            -
                          </button>
                        </tr>
                      </tbody>
                    </table>
                    <DividerTable color style={{ width: '100%' }} />
                  </>
                ))}
              </SelectedValuesAndAccredits>
            </Main>
            <p
              style={{
                fontSize: '0.8rem',
              }}
            >
              *Credenciados não selecionáveis não possuem establecimentos
              vinculados ou ativos.
            </p>
            <Button
              disabled={cartelasValue < 100 || loadingSend}
              style={{ marginBottom: '1rem', minWidth: '9rem' }}
              onClick={sendFractionedCartelas}
            >
              {loadingSend ? (
                <CircularProgress size="26px" />
              ) : (
                'Reservar cartelas'
              )}
            </Button>
          </SendBox>
          <ContainerInstructions>
            <div> INSTRUÇÕES: </div>
            <ul>
              <span>1º passo:</span> Selecione um credenciado;
            </ul>
            <ul>
              <span>2º passo: </span> Selecione a quantidade de cartelas que
              deseja reservar para esse credenciado;
            </ul>
            <ul>
              <span>3º passo:</span> Clique no botão <MdDoubleArrow /> para
              separar as cartelas;
            </ul>
            <ul>
              <span>4º passo:</span> Repita o processo até que a soma das
              cartelas enviadas chegue a 100 unidades;
            </ul>
            <ul>
              <span>5º passo:</span> Clique no botão <b> RESERVAR CARTELAS </b>
              para confirmar a solicitação;
            </ul>
            <ul>
              <span>Obs:</span> Caso o credenciado ou o valor sejam selecionados
              erroneamente, você pode removê-los da caixa de separação clicando
              no
              <b style={{ fontSize: '1.2rem', color: 'red' }}>-</b>.
            </ul>
          </ContainerInstructions>
        </InsideContent>
      </Content>
    </>
  );
};
