import { InputLabel } from "@mui/material";
import styled from "styled-components";
import { DebounceInput } from "react-debounce-input";

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  margin: 2rem;

  .searchIcon {
    position: absolute;
    bottom: 14px;
    left: -1px;
  }
`;

export const InputSearch = styled(DebounceInput)`
  border-bottom: 1px solid #6b6b6b;
  border-left: none;
  border-right: none;
  border-top: none;

  box-shadow: 0 0 0 0;
  font-size: 16px;
  margin-bottom: 10px;
  outline: 0;
  padding-bottom: 5px;
  padding-left: 26px;
  width: 100%;
`;

export const LabelSearch = styled(InputLabel)`
  font-size: 13px;
  margin-bottom: 1rem;
`;

export const LoadingTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50vh;
  width: 100%;
`;

export const BoxBalance = styled.div`
  width: 85vw;
  height: fit-content;
  border-radius: 10px;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 80vh;
    padding: 0;
    margin-left: 10%;
  }
`;
