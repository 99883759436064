import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/auth";
import { Oval } from "react-loading-icons";

const Private = ({ children }) => {
  const { authenticated, loading } = useContext(AuthContext);
  if (loading) {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><Oval /></div>
    );
  }
  if (!authenticated) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default Private;
