import React, { useContext, useEffect, useState } from 'react';
import { configJson } from '../../library/utils/firebaseUtils';
import { toast } from 'react-toastify';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';
// STYLES
import MUIDataTable from 'mui-datatables';
import { StyledDiv } from '../Tables/style/MUIDatatable';
import { BottomMargin } from '../../pages/SellerPage/style/SellerPageStyle';
import { CircularProgress, createTheme, ThemeProvider, Tooltip } from '@mui/material';
import { LoadingTable } from '../../pages/ListOfRaffles/style/ListOfRaffles';
import { MdCancelScheduleSend } from 'react-icons/md';
import { RiFolderReceivedLine } from 'react-icons/ri';
import { GiBoxUnpacking } from 'react-icons/gi';
import { BsCircleFill, BsFiletypeDoc } from 'react-icons/bs';
import { ButtonDistribuitionTable, ContainerButtonTable } from './style/EditButtomTableStyles';

//APIS AND HELPERS
import { getAllDelivered } from '../../services/APIs/getAllDelivered';
import {
  convertMillisecondsToDateTime,
  convertToFullDate,
  convertToMillis,
} from '../../services/helpers';

export default function DistribuitionCartelaTable({ handleOpenModal, verifyDistributionIsActive }) {
  const isUpdateTable = JSON.parse(localStorage.getItem('updateList'));
  const uId = JSON.parse(localStorage.getItem('uid'));
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);

  const [deliveredInfos, setDeliveredInfos] = useState([]);
  const [respApi, setRespApi] = useState(false);

  useEffect(() => {
    localStorage.removeItem('updateList');
    if (serie !== '' || serieUrl !== '') {
      setRespApi(true);
      getAllDelivered(uId, serie === '' ? serieUrl : serie)
        .then((result) => {
          const mappedItems = result.map((x) => {
            const sanitizeLote = x?.batches && x.batches.map((lote) => lote.split('_')[2]);
            const sanitizeCards = x?.cards && x.cards[0].split('_')[2];
            const data = {
              accreditedName: x.accreditedName,
              date: convertMillisecondsToDateTime(x.reservationDate),
              fractioned: x.fractioned === true ? 'Fracionado' : 'Lote',
              id: x.id,
              quantity: x.requestedQuantity,
              lote: x.fractioned ? sanitizeCards : sanitizeLote,
              action: {
                cnpj: x.cnpj,
                accreditedId: x.accreditedId,
                deliveryId: x.deliveryId,
                romaneio: configJson.STORAGE_URL + x.filePath,
              },
              status: x.status,
            };

            return data;
          });
          setDeliveredInfos(mappedItems.flat());
        })
        .catch((error) => {
          if (error === 'Não há itens para exibir!') {
            toast.warning(error);
          } else {
            toast.error(error);
          }
        })
        .finally(() => {
          setRespApi(false);
        });
    }
  }, [uId, isUpdateTable, serie, serieUrl]);

  if (respApi)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingTable>
          <CircularProgress size="100px" />
        </LoadingTable>
      </div>
    );

  const displayData = deliveredInfos;

  const columns = [
    {
      name: 'accreditedName',
      label: 'Credenciado',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'fractioned',
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'quantity',
      label: 'Quantidade',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'lote',
      label: 'Lote de origem',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          return (
            <Tooltip title={typeof value === 'object' && value.join(', ')} placement="top">
              {typeof value === 'object' ? value.slice(0, 4).join(', ') : value}
              {typeof value === 'object' ? value.length > 4 && '...' : ''}
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'date',
      label: 'Data da reserva',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value, rowData) => {
          // AJUSTES PARA EXIBIR A DATA EM ORDEM CORRETA
          return convertToFullDate(convertToMillis(value));
        },
      },
    },
    {
      name: 'id',
      label: 'CÓDIGO DO ENVIO',
      options: {
        filter: true,
        sort: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'status',
      label: 'Situação',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'cancelled':
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <BsCircleFill style={{ color: '#EB1E0A' }} size="14px" />
                  &nbsp;Cancelado
                </span>
              );
            case 'expired':
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <BsCircleFill style={{ color: '#a43238' }} size="14px" />
                  &nbsp;Expirado
                </span>
              );
            case 'reserved':
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <BsCircleFill style={{ color: '#54BBFE' }} size="14px" />
                  &nbsp;Reservado
                </span>
              );
            case 'pending':
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <BsCircleFill style={{ color: '#E6CE51' }} size="14px" />
                  &nbsp;Pendente
                </span>
              );
            case 'parcially reserved':
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <BsCircleFill style={{ color: '#E5AA1A' }} size="14px" />
                  &nbsp;Reservado parcial
                </span>
              );
            case 'delivered':
              return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <BsCircleFill style={{ color: '#2DB94D' }} size="14px" />
                  &nbsp;Entregue
                </span>
              );
            default:
              // console.log('Status não existente');
              break;
          }
        },
      },
    },
    {
      name: 'action',
      label: 'AÇÃO',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value, tableRow) => (
          <ContainerButtonTable>
            {tableRow.rowData[6] !== 'pending' && tableRow.rowData[6] !== 'cancelled' ? (
              <Tooltip title="Romaneio" placement="top">
                <ButtonDistribuitionTable>
                  <a href={value.romaneio} target="_blank" rel="noreferrer">
                    <BsFiletypeDoc size="20px" style={{ color: '#1565B4' }} />
                  </a>
                </ButtonDistribuitionTable>
              </Tooltip>
            ) : (
              <ButtonDistribuitionTable>
                <BsFiletypeDoc
                  size="20px"
                  style={{
                    color: '#1565B4',
                    opacity: '0.3',
                    cursor: 'not-allowed',
                  }}
                />
              </ButtonDistribuitionTable>
            )}
            {verifyDistributionIsActive() && tableRow.rowData[6] === 'reserved' ? (
              tableRow.rowData[1] === 'Lote' ? (
                <Tooltip title="Cancelar" placement="top">
                  <ButtonDistribuitionTable
                    onClick={() => handleOpenModal('cancelation', value.deliveryId)}
                  >
                    <MdCancelScheduleSend size="20px" style={{ color: '#EB1E0A' }} />
                  </ButtonDistribuitionTable>
                </Tooltip>
              ) : (
                <Tooltip title="Transferir" placement="top">
                  <ButtonDistribuitionTable
                    onClick={() => handleOpenModal('transfer', value.deliveryId, value.cnpj)}
                  >
                    <GiBoxUnpacking size="20px" style={{ color: '#EDB622' }} />
                  </ButtonDistribuitionTable>
                </Tooltip>
              )
            ) : tableRow.rowData[1] === 'Lote' ? (
              <ButtonDistribuitionTable>
                <MdCancelScheduleSend
                  size="20px"
                  style={{
                    color: '#EB1E0A',
                    opacity: '0.3',
                    cursor: 'not-allowed',
                  }}
                />
              </ButtonDistribuitionTable>
            ) : (
              <ButtonDistribuitionTable>
                <GiBoxUnpacking
                  size="20px"
                  style={{
                    color: '#EDB622',
                    opacity: '0.3',
                    cursor: 'not-allowed',
                  }}
                />
              </ButtonDistribuitionTable>
            )}
            {verifyDistributionIsActive() &&
            tableRow.rowData[6] !== 'delivered' &&
            tableRow.rowData[6] !== 'cancelled' &&
            tableRow.rowData[6] !== 'pending' ? (
              <Tooltip title="Confirmar recebimento" placement="top">
                <ButtonDistribuitionTable
                  onClick={() =>
                    handleOpenModal('received', tableRow.rowData[5], value.accreditedId)
                  }
                >
                  <RiFolderReceivedLine size="20px" style={{ color: '#2DB94D' }} />
                </ButtonDistribuitionTable>
              </Tooltip>
            ) : (
              <ButtonDistribuitionTable>
                <RiFolderReceivedLine
                  size="20px"
                  style={{
                    color: '#2DB94D',
                    opacity: '0.3',
                    cursor: 'not-allowed',
                  }}
                />
              </ButtonDistribuitionTable>
            )}
          </ContainerButtonTable>
        ),
      },
    },
  ];
  const tableOptions = {
    onRowClick: (rowData) => {},
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: 'simple',
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: '',
        toolTip: `${'table.toolTip'}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${'table.viewColumnsTitle'}`,
      },
    },
    defaultSortField: 'date',
    sortOrder: {
      name: 'date',
      direction: 'desc',
    },
  };
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
              cursor: 'default',
              textAlign: 'center',
            },
          },
        },
      },
    });

  return (
    <BottomMargin>
      <StyledDiv style={{ cursor: 'pointer' }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            className={'table is-mobile'}
            data={displayData}
            columns={columns}
            options={tableOptions}
          />
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}
