import { Content, InsideContent } from '../styled/generic';
import Footer from '../components/Footer/Footer';
import HeaderComp from '../components/Header/HeaderComp';
import SideBarDesktop from '../components/Sidebar/SideBarComp';
import {
  MediumPictureProfile,
  MediumPictureProfileBox,
} from '../styled/PictureProfiel';
import ReactInputMask from 'react-input-mask';
import { BsPersonFill } from 'react-icons/bs';
import {
  LeftDiv,
  ProfileBoxDesktop,
  ProfileBoxMobile,
  PictureBox,
  DataBox,
  ButtonBox,
  Role,
  NameNRole,
  Name,
  WhiteBoxSeller,
  ContentBox,
} from '../pages/SellerPage/style/SellerPageStyle';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  enableSupervisor,
  disableSupervisor,
  getSupervisorData,
} from '../services/updateStatus';
import { verifyUserPhone } from '../services/login';
import { authToken, authTokenWithSC } from '../services/APIs/token';
import ModalGenericConfirmProccess from '../components/Modals/ModalGenericConfirmProccess';

export default function SellerPage() {
  const [inactive, setInactive] = useState(false);
  const { id } = useParams();
  const [seller, setSeller] = useState();
  const phone = localStorage.getItem('sellerPhone').replace('+55', '');
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const getSeller = async () => {
      const token = await authTokenWithSC();
      const response = (
        await verifyUserPhone(phone, token)
      ).data.response.partnerList.find((e) => e.partnerName === 'BrasilCap');
      setSeller(response);
    };
    getSeller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    authToken().then((token) => {
      getSupervisorData(id, token).then((response) => {
        setInactive(response.data.response.active === false);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSeller = async () => {
    authToken().then((token) => {
      if (inactive) {
        enableSupervisor(id, token).then((e) => {
          setInactive(!inactive);
        });
      } else {
        disableSupervisor(id, token).then((e) => {
          setInactive(!inactive);
          handleModal();
        });
      }
    });
  };

  const handleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <ModalGenericConfirmProccess
            action={toggleSeller} // API OU FUNÇÃO DO BOTÃO DE CONFIRMAR
            body={'Tem certeza que deseja desativar este usuário?'} // MENSAGEM DO CORPO DO MODAL
            canceled={'Cancelar'} // TRUE OU FALSE SE DESEJAR QUE EXIBA UM BOTÃO DE CANCELAR
            close={handleModal} // FECHA MODAL
            labelButton={'Desativar'} // LABEL DO BOTÃO DE CONFIRMAR
            labelCancelation={'Voltar'} // LABEL DO BOTÃO DE CANCELAR
            open={isOpenModal} // ESTADO DO MODA
            title={`Desativação de ${
              window.location.pathname === `/vendedor/${id}`
                ? 'vendedor'
                : window.location.pathname === `/afiliado/${id}`
                ? 'afiliado'
                : 'supervisor'
            }
            (a)`}
          />
          <ContentBox>
            <LeftDiv>
              <ProfileBoxDesktop id={!inactive ? '' : 'inactive'}>
                <div className="column">
                  <PictureBox>
                    <MediumPictureProfileBox>
                      <MediumPictureProfile>
                        <BsPersonFill id={!inactive ? '' : 'inactivePic'} />
                      </MediumPictureProfile>
                    </MediumPictureProfileBox>
                  </PictureBox>
                </div>
                <DataBox className="column">
                  <NameNRole>
                    <Role>Supervisor</Role>
                    <Name>{seller && seller.name}</Name>
                  </NameNRole>
                </DataBox>
                <ButtonBox className="column">
                  <button
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      border: 'none',
                      backgroundColor: 'inherit',
                      color: 'white',
                    }}
                    className="button is-small"
                  ></button>
                  <button
                    style={{
                      fontSize: '0.8em',
                      color: '#2d70b9',
                      width: 'fit-content',
                    }}
                    className="button"
                    onClick={inactive ? () => toggleSeller() : handleModal}
                    id={!inactive ? '' : 'inactiveButton'}
                  >
                    {!inactive ? 'Desativar supervisor' : 'Ativar supervisor'}
                  </button>
                </ButtonBox>
              </ProfileBoxDesktop>
              {window.screen.width <= 450 && (
                <ProfileBoxMobile
                  className="columns"
                  id={!inactive ? '' : 'inactive'}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                    className="column"
                  >
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <PictureBox>
                        <MediumPictureProfileBox>
                          <MediumPictureProfile>
                            <BsPersonFill id={!inactive ? '' : 'inactivePic'} />
                          </MediumPictureProfile>
                        </MediumPictureProfileBox>
                      </PictureBox>
                      <DataBox className="column">
                        <NameNRole>
                          <Role>supervisor</Role>
                          <Name>{seller && seller.name}</Name>
                        </NameNRole>
                      </DataBox>
                    </div>
                    <button
                      style={{
                        fontSize: '0.8em',
                        color: '#2d70b9',
                        width: 'fit-content',
                      }}
                      className="button"
                      onClick={inactive ? () => toggleSeller() : handleModal}
                      id={!inactive ? '' : 'inactiveButton'}
                    >
                      {!inactive ? 'Desativar supervisor' : 'Ativar supervisor'}
                    </button>
                  </div>
                </ProfileBoxMobile>
              )}
              <WhiteBoxSeller className="box">
                <p
                  style={{
                    color: 'grey',
                    fontSize: '0.8em',
                  }}
                >
                  Dados pessoais
                </p>
                <ReactInputMask
                  className="input is-fullwidth"
                  mask="(+55) 99 9 9999-9999"
                  style={{
                    marginBottom: '1em',
                  }}
                  disabled
                  value={
                    seller &&
                    seller.contactList.find((e) => e.type === 'cellPhone').value
                  }
                />
                <ReactInputMask
                  className="input is-fullwidth"
                  mask="999.999.999-99"
                  style={{
                    marginBottom: '1em',
                  }}
                  disabled
                  value={
                    seller &&
                    seller.documentList.find((e) => e.type === 'cpf').value
                  }
                />
                <input
                  className="input is-fullwidth"
                  style={{
                    marginBottom: '1em',
                  }}
                  disabled
                  value={
                    seller &&
                    seller.contactList.find((e) => e.type === 'email').value
                  }
                />
              </WhiteBoxSeller>
            </LeftDiv>
          </ContentBox>
        </InsideContent>
      </Content>
      <Footer />
    </>
  );
}
