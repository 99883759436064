import MUIDataTable from 'mui-datatables';
import { StyledDiv } from './style/MUIDatatable';
import React, { useState } from 'react';
import { BottomMargin } from '../../pages/SellerPage/style/SellerPageStyle';
import { createTheme, ThemeProvider } from '@mui/material';
import { mask } from 'remask';

export default function SellerleadsTable({ sellerList, initial, final }) {
  const [open, setOpen] = useState({});

  const handleTooltipClose = (i) => {
    setOpen({ ...open, [i]: false });
  };

  const handleTooltipOpen = (i) => {
    setOpen({ ...open, [i]: true });
  };
  const columns = [
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'date',
      label: 'Data',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          const date = new Date(value);
          const data =
            date.toLocaleDateString('pt-br') +
            ' - ' +
            date.toLocaleTimeString('pt-br');
          return data;
        },
      },
    },
    {
      name: 'cpf',
      label: 'CPF',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          const cpfWithMask = mask(value, ['999.999.999-99']);

          return cpfWithMask;
        },
      },
    },
    {
      name: 'cellphone',
      label: 'Telefone',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          const cleaned = ('' + value).replace('+55', '');
          let match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
          if (match) {
            return `${match[1]} ${match[2]} ${match[3]}-${match[4]}`;
          }
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
      },
    },
    {
      name: 'quantity',
      label: 'Qtd',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
      },
    },
    {
      name: 'valuePaid',
      label: 'Valor',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          let data = value.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          });
          return data;
        },
      },
    },
    {
      name: 'status',
      label: 'Status da venda',
      options: {
        filter: true,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value) => {
          if (value === 'unpaid') {
            value = 'venda frustrada';
          } else if (value === 'paid') {
            value = 'Pago';
          } else {
            value = 'venda concluída';
          }
          return value;
        },
      },
    },
    {
      name: 'luckyNumbers',
      label: 'Numeros da sorte',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value, tableData) => {
          let luckyNmbrs = [];
          if (Array.isArray(value)) {
            value.forEach(
              (item, i) => (luckyNmbrs = [...luckyNmbrs, ...item.split(',')])
            );

            return open[tableData.rowIndex] ? (
              <>
                {luckyNmbrs.map((e) => (
                  <p>{e}</p>
                ))}
                <button
                  style={{ padding: 0 }}
                  className="button is-ghost is-small"
                  onClick={() => handleTooltipClose(tableData.rowIndex)}
                >
                  ocultar
                </button>
              </>
            ) : (
              <button
                className="button is-small"
                onClick={() => handleTooltipOpen(tableData.rowIndex)}
              >
                Clique para mostrar
              </button>
            );
          }
        },
      },
    },
  ];
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
            },
          },
        },
      },
    });

  const tableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    responsive: 'simple',
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: '',
        toolTip: `${'table.toolTip'}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${'table.viewColumnsTitle'}`,
      },
    },
  };

  return (
    <BottomMargin>
      <StyledDiv>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            style={{ zIndex: 0 }}
            data={
              sellerList &&
              sellerList.filter((el) => el.date >= initial && el.date <= final)
            }
            columns={columns}
            options={tableOptions}
          />
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}
