import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React from 'react';
import DashBoard from '../pages/Dashboard/DashBoard';
import Login from '../pages/Login/Login';
import MyProfile from '../pages/MyProfile/MyProfile';
import SellerPage from '../pages/SellerPage/SellerPage';
import SellerRegister from '../pages/SellerRegister';
import SellersList from '../pages/SellersList';
import SupervisorList from '../pages/SupervisorList';
import SupervisorPage from '../pages/SupervisorPage';
import SupervisorRegister from '../pages/SupervisorRegister';
import { AuthProvider } from '../context/auth';
import { RegisterProvider } from '../context/register';
import SellerNSupervisor from '../pages/SellerNSupervisor/SellerNSupervisor';
import Private from './Private';
import AfiliatedList from '../pages/AffiliatedList';
import AffiliatedRegister from '../pages/AffiliatedRegister';
import AffiliatedPage from '../pages/AffiliatedPage';
import { Administration } from '../pages/Administration/Administration';
import { CreateRaffle } from '../pages/CreateRaffle/CreateRaffle';
import { ListOfRaffles } from '../pages/ListOfRaffles/ListOfRaffles';
import { CampaignProvider } from '../context/campaigns';
import TestimonyList from '../pages/Testimony/TestimonyList';
import TestimonyPage from '../pages/Testimony/TestimonyPage';
import { DistribuitionCartelas } from '../pages/DistribuitionCartelas/Distribuition/DistribuitionCartelas';
import { DistribuitionCartelaProvider } from '../context/distribuitionCartela';
import { DistribuitionCartelasList } from '../pages/DistribuitionCartelas/DistribuitionCartelasList';
import { Accredited } from '../pages/DistribuitionCartelas/Accredited/Accredited';
import { RegisterAccredited } from '../pages/DistribuitionCartelas/Accredited/RegisterAccredited';
import { AccreditedIndividualPage } from '../pages/DistribuitionCartelas/Accredited/AccreditedIndividualPage';
import { BindEstablishment } from '../pages/DistribuitionCartelas/Accredited/BindEstablishment';
import { SendType } from '../pages/DistribuitionCartelas/Distribuition/SendType';
import { SendCartelas } from '../pages/DistribuitionCartelas/Distribuition/SendCartelas';
import { SendInLote } from '../pages/DistribuitionCartelas/Distribuition/SendInLote';
import { Series } from '../pages/DistribuitionCartelas/Series/Series';
import { RegisterSeries } from '../pages/DistribuitionCartelas/Series/RegisterSeries';
import { Lows } from '../pages/DistribuitionCartelas/LowsAndLosses/Lows';
import { LossList } from '../pages/DistribuitionCartelas/LowsAndLosses/LossList';
import { LowsList } from '../pages/DistribuitionCartelas/LowsAndLosses/LowsList';
import { SaleOff } from '../pages/DistribuitionCartelas/SaleOff/SaleOff';
import { CardsWithErros } from '../pages/DistribuitionCartelas/CardsWithErros/CardsWithErros';

export default function Rotas() {
  // const isModule = JSON.parse(localStorage.getItem('isModule'));

  return (
    <Router>
      <AuthProvider>
        <RegisterProvider>
          <CampaignProvider>
            <DistribuitionCartelaProvider>
              <Routes>
                <Route exact path="/" element={<Navigate to="/login" />} />
                <Route exact path="/login" element={<Login />} />
                <Route
                  exact
                  path="/dashboard"
                  element={
                    <Private>
                      <DashBoard />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/perfil"
                  element={
                    <Private>
                      <MyProfile />
                    </Private>
                  }
                />
                <Route
                  exact
                  path={`/vendedor/:id`}
                  element={
                    <Private>
                      <SellerPage />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/registro-vendedor"
                  element={
                    <Private>
                      <SellerRegister />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/lista-vendedor"
                  element={
                    <Private>
                      <SellersList />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/supervisor/:id"
                  element={
                    <Private>
                      <SupervisorPage />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/registro-supervisor"
                  element={
                    <Private>
                      <SupervisorRegister />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/lista-supervisor"
                  element={
                    <Private>
                      <SupervisorList />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/lista-supervisor-vendedor"
                  element={
                    <Private>
                      <SellerNSupervisor />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/lista-afiliados"
                  element={
                    <Private>
                      <AfiliatedList />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/registro-afiliado"
                  element={
                    <Private>
                      <AffiliatedRegister />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/afiliado/:id"
                  element={
                    <Private>
                      <AffiliatedPage />
                    </Private>
                  }
                />
                {/* {isModule && ( */}
                <>
                  <Route
                    exact
                    path="/operacao-cartela"
                    element={
                      <Private>
                        <DistribuitionCartelasList />
                      </Private>
                    }
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-series"
                    element={
                      <Private>
                        <Series />
                      </Private>
                    }
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-series/cadastro"
                    element={
                      <Private>
                        <RegisterSeries />
                      </Private>
                    }
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-series/baixa/:id"
                    element={<Private>{<Lows />}</Private>}
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-series/reservar-cartelas/:id"
                    element={
                      <Private>
                        <DistribuitionCartelas />
                      </Private>
                    }
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-series/reservar-cartelas/tipo-reserva/:id"
                    element={<Private>{<SendType />}</Private>}
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-series/reservar-cartelas/tipo-reserva/lote/:id"
                    element={<Private>{<SendInLote />}</Private>}
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-series/reservar-cartelas/tipo-reserva/fracionada/:id"
                    element={<Private>{<SendCartelas />}</Private>}
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-credenciados"
                    element={
                      <Private>
                        <Accredited />
                      </Private>
                    }
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-credenciados/cadastro"
                    element={
                      <Private>
                        <RegisterAccredited />
                      </Private>
                    }
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-credenciados/:id"
                    element={
                      <Private>
                        <AccreditedIndividualPage />
                      </Private>
                    }
                  />
                  <Route
                    exact
                    path="/operacao-cartela/gerenciar-credenciados/:id/vincular"
                    element={
                      <Private>
                        <BindEstablishment />
                      </Private>
                    }
                  />
                </>
                <Route
                  exact
                  path="/operacao-cartela/gerenciar-series/extravios/:id"
                  element={
                    <Private>
                      <LossList />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/operacao-cartela/gerenciar-series/lista-de-baixas/:id"
                  element={
                    <Private>
                      <LowsList />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/operacao-cartela/gerenciar-series/liquidacao/:id"
                  element={<Private>{<SaleOff />}</Private>}
                />
                <Route
                  exact
                  path="/operacao-cartela/gerenciar-series/erros/:id"
                  element={<Private>{<CardsWithErros />}</Private>}
                />
                <Route
                  exact
                  path="/administracao"
                  element={
                    <Private>
                      <Administration />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/administracao/criar-sorteios"
                  element={
                    <Private>
                      <CreateRaffle />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/administracao/lista-de-sorteios"
                  element={
                    <Private>
                      <ListOfRaffles />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/administracao/lista-de-sorteios/:id"
                  element={
                    <Private>
                      <CreateRaffle />
                    </Private>
                  }
                />
                <Route
                  exact
                  path="/administracao/depoimentos"
                  element={
                    <Private>
                      <TestimonyList />
                    </Private>
                  }
                />
                <Route
                  path="/administracao/depoimento"
                  element={
                    <Private>
                      <TestimonyPage />
                    </Private>
                  }
                />
              </Routes>
            </DistribuitionCartelaProvider>
          </CampaignProvider>
        </RegisterProvider>
      </AuthProvider>
    </Router>
  );
}
