import axios from 'axios';
import { configJson } from '../library/utils/firebaseUtils';
import { getChannelTokenUidNull } from './APIs/token';

export function getUserInfo_v1(queryPartnerAPI, userInfo) {
  return new Promise((resolve, reject) => {
    getChannelTokenUidNull()
      .then((token) => {
        // console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', token);

        //TODO capturar sessionId e transactionId
        axios
          .post(configJson.API_HOST_V1 + 'getUserInfo_v1', {
            triiboHeader: {
              apiToken: configJson.apiToken,
              channelGroup: configJson.channelGroup,
              channelName: configJson.channelName,
              uId: null,
              channelTokenId: token,
              channelId: configJson.channelId,
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then((result) => {
            resolve(result.data.success.userInfo);
          })
          .catch((error) => {
            // console.log('ERROR GET USER INFO ===>', error);

            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
      });
  });
}
