import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';

// STYLES
import {
  DivModal,
  ContainerModal,
  ContainerSelects,
  ContentSelectAccredit,
  Container,
  SendCartelaButton,
  TableDataInfo,
  SearchInputContainer,
  SearchInput,
  SearchIcon,
  ContentNoHave,
  ModalHeader,
  HeaderText,
} from './style/ModalStyle';
import { DividerTable } from '../../pages/DistribuitionCartelas/styles/DistribuitionCartelasStyle';
import { HiSearchCircle } from 'react-icons/hi';
import { CircularProgress } from '@mui/material';

// APIS
import { listAccrediteds } from '../../services/APIs/listAccrediteds';
import { transferCartela } from '../../services/APIs/transferCartela';

export const ModalTransferCartela = ({
  isOpen,
  handleCloseModal,
  oldPdvId,
  deliveryId,
}) => {
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [accredited, setAccredited] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [message, setMessage] = useState('');
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedOptionAccredited, setSelectedOptionAccredited] =
    useState(null);

  // BUSCAR OS CREDENCIADOSS
  useEffect(() => {
    setLoading(true);
    listAccrediteds(uId)
      .then((accredit) => {
        if (accredit.success) {
          const data = accredit.response
            .map((info) => {
              const item = Object.values(info)[0];
              const newPdvCNPJ = Object.keys(info)[0];
              if (item.status) {
                return {
                  newPdvCNPJ,
                  address: item.address,
                  establishments: item.establishments,
                  name: item.name,
                };
              }
              return null;
            })
            .filter((item) => item !== null);
          setAccredited(data);
          setLoading(false);
        } else {
          console.log('ERROR API ==> listAccrediteds');
          setLoading(false);
        }
      })
      .catch(() => {
        toast.error('As informações não poderam ser processadas.');
      });
  }, [uId]);

  // FILTRA A BUSCA DO CREDENCIADO POR NOME
  useEffect(() => {
    if (search.length > 1) {
      const filteredResults = accredited.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  }, [search, accredited]);

  const handleAccreditedSelected = (e) => {
    setSelectedOptionAccredited(e.target.value);
  };
  const resendCartela = () => {
    setLoadingSend(true);
    const infosAccredited = accredited.filter(
      (item) => item.name === selectedOptionAccredited
    )[0];

    const payload = {
      serie: serie === '' ? serieUrl : serie,
      oldPdvId,
      newPdvId: infosAccredited.newPdvCNPJ,
      deliveryId,
    };

    if (oldPdvId === infosAccredited.newPdvCNPJ) {
      setLoadingSend(false);

      setMessage(
        'Você está tentando transferir as cartelas para o mesmo credenciado.'
      );

      setTimeout(() => {
        setMessage('');
      }, 2000);
    } else {
      transferCartela(uId, payload)
        .then((result) => {
          setLoadingSend(false);

          if (result.success) {
            toast.success('Transferência realizada com sucesso.');
            localStorage.setItem('updateList', true);

            close();
          }
        })
        .catch((err) => {
          setLoadingSend(false);

          console.log('Algo ocorreu de errado.', err);
          toast.error('Algo ocorreu de errado. Tente novamente mais tarde.');
        });
    }
  };

  const close = () => {
    handleCloseModal('transfer');
  };

  return (
    <div className={`modal ${isOpen ? 'modal is-active' : 'modal'}`}>
      <div className="modal-background" onClick={() => close()}></div>
      <DivModal style={{ width: '50em' }} radius className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">
            Transferir cartela
          </HeaderText>
        </ModalHeader>
        <ContainerModal className="modal-card-body">
          <h4>Selecione o credenciado:</h4>
          <SearchInputContainer>
            <SearchInput
              autocomplete="off"
              name="randon_not_complete"
              type="text"
              placeholder="Pesquisar credenciado"
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon>
              <HiSearchCircle size="25px" />
            </SearchIcon>
          </SearchInputContainer>
          <Container>
            <ContainerSelects>
              <span>Selecione um credenciado:</span>
              <ContentSelectAccredit>
                {loading ? (
                  <ContentNoHave>
                    <CircularProgress size="35px" />
                  </ContentNoHave>
                ) : search.length > 1 ? (
                  searchResults.map((item) => (
                    <>
                      <table key={item.name}>
                        <tbody>
                          <tr>
                            <input
                              type="radio"
                              value={item.name}
                              checked={selectedOptionAccredited === item.name}
                              onChange={handleAccreditedSelected}
                            />
                            <span>
                              <TableDataInfo>{item.name}</TableDataInfo>
                              <TableDataInfo>{item.address}</TableDataInfo>
                            </span>
                          </tr>
                        </tbody>
                      </table>
                      <DividerTable />
                    </>
                  ))
                ) : (
                  accredited.map((item) => (
                    <>
                      <table key={item.name}>
                        <tbody>
                          <tr>
                            <input
                              type="radio"
                              value={item.name}
                              checked={selectedOptionAccredited === item.name}
                              onChange={handleAccreditedSelected}
                            />
                            <span>
                              <TableDataInfo>{item.name}</TableDataInfo>
                              <TableDataInfo>{item.address}</TableDataInfo>
                            </span>
                          </tr>
                        </tbody>
                      </table>
                      <DividerTable />
                    </>
                  ))
                )}
              </ContentSelectAccredit>
            </ContainerSelects>
          </Container>
          <span style={{ color: 'red' }}>{message}</span>
          <SendCartelaButton
            disabled={selectedOptionAccredited === null || loadingSend}
            onClick={resendCartela}
            variant="contained"
          >
            {loadingSend ? (
              <CircularProgress size="26px" style={{ color: '#fff' }} />
            ) : (
              'Transferir Cartelas'
            )}
          </SendCartelaButton>
        </ContainerModal>
      </DivModal>
    </div>
  );
};
