import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 450px) {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2d70b9;
    overflow: hidden;
    position: relative;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const LeftDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 450px) {
    padding: 30%;
    display: flex;
    margin: none;
    align-items: center;
    justify-content: center;
    //background-color: #2d70b9;
    width: 100vw;
    height: 100vh;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
`;
export const FormLoginStyle = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 450px) {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 5%;
    border: none;
  }
`;

export const BoxLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  width: 33em;
  height: 40em;
  padding: 5%;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  @media screen and (max-width: 450px) {
    padding: 10%;
    display: flex;
    margin: none;
    align-items: center;
    justify-content: center;
    background-color: #2d70b9;
    width: 90vw;
    height: 80vh;
    border: none;
    border-radius: 0;
  }
`;

export const ImageLogo = styled.img`
  max-width: 50%;
  background-color: none;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;
export const ImageLogoMobile = styled.img`
  max-width: 50%;
  background-color: none;
  padding-bottom: 10%;
  @media screen and (min-width: 450px) {
    display: none;
  }
`;

export const Button = styled.button`
  color: white;
  background-color: #2d70b9;
  height: 40%;
  margin-top: 5%;
  border-radius: 8px;
`;
export const InputHeaderText = styled.h1`
  font-size: 1.8em;
  font-weight: 500;
  text-align: center;
`;

export const GenericText = styled.span`
  font-size: 0.8em;
  font-weight: 500;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
`;

export const HeaderText = styled.h1`
  font-size: 3em;
  font-weight: 600;
  padding-top: 8%;
  padding-left: 5%;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

export const RightDiv = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  //mix-blend-mode: multiply;
  background: #2d70b9 0% 0% no-repeat padding-box;
  //opacity: 1;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;
export const NoteBookImg = styled.img`
  width: 34em;
`;

export const NoteBookBoxImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  //padding-top: 10%;
`;

export const AddressText = styled.span`
  color: white;
  font-size: 1em;
  text-align: center;
  margin-top: 3.7em;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;
export const AddressTextMobile = styled.span`
  font-size: 0.8em;
  color: white;
  width: 60vw;
  //font-size: 0.8em;
  text-align: center;
  //margin-top: 6em;
  @media screen and (min-width: 450px) {
    display: none;
  }
`;

export const LinkCode = styled.button`
  font-size: 10px;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  margin-top: 5px;
  border: none;
  color: blue;
  background-color: none;
  text-decoration: underline;
`;
